import { useMutation } from 'urql';
import { graphql } from '../../gql';

export const CreateWorkbenchElementPlaceholderMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementPlaceholder(
    $input: CreateWorkbenchElementPlaceholderInput!
  ) {
    createWorkbenchElementPlaceholder(input: $input) {
      workbenchElementPlaceholder {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);

export const useCreateWorkbenchElementPlaceholder = () =>
  useMutation(CreateWorkbenchElementPlaceholderMutation);

export const UpdateWorkbenchElementPlaceholderMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementPlaceholder(
    $input: UpdateWorkbenchElementPlaceholderInput!
  ) {
    updateWorkbenchElementPlaceholder(input: $input) {
      workbenchElementPlaceholder {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);

export const DeleteWorkbenchElementPlaceholderMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementPlaceholder(
    $input: DeleteWorkbenchElementPlaceholderInput!
  ) {
    deleteWorkbenchElementPlaceholder(input: $input) {
      deletedWorkbenchElementPlaceholderNodeId
    }
  }
`);
