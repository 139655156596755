import { graphql } from '../gql';

export const CreateLayer3dFromDrawingMutation = graphql(/* GraphQL */ `
  mutation CreateLayer3dFromDrawing($input: CreateLayer3dFromDrawingInput!) {
    createLayer3dFromDrawing(input: $input) {
      drawing {
        ...DrawingData
      }
    }
  }
`);
