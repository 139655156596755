import { useMutation } from 'urql';
import { graphql } from '../gql';

const DeleteFolder = graphql(/* GraphQL */ `
  mutation DeleteFolder($input: DeleteFolderInput!) {
    deleteFolder(input: $input) {
      parent {
        ...FolderData
      }
    }
  }
`);

export const useDeleteFolder = () => useMutation(DeleteFolder);
