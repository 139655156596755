import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import styled, { useTheme } from 'styled-components';
import { CarretDownIcon } from './icons';
import { Text } from './Text/Text';
import { boundNumber } from '@vizcom/shared/js-utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CarretsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CarretDown = styled(CarretDownIcon)`
  cursor: ns-resize;
`;
const CarretUp = styled(CarretDown)`
  transform: rotate(180deg);
`;

const Value = styled(Text)`
  position: relative;
  min-width: 30px;
  text-align: right;
  cursor: text;
  user-select: none;
  touch-action: none;
  border: 1px solid transparent;
  line-height: normal;
  padding: 6px 10px;

  :hover {
    border: 1px solid ${({ theme }) => theme.text.disabled};
    border-radius: 4px;
  }
`;

const ValueInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: right;
  background: none;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text.default};
  z-index: 2;

  border: 1px solid ${({ theme }) => theme.text.primary};
  border-radius: 4px;
  padding: 6px 10px;

  appearance: none;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  cursor: ew-resize;
  touch-action: none;
`;

const DRAG_RANGE_PX = 200; // Dragging 200px up or down covers the whole NumberInput range

export const NumberInput = ({
  value,
  setValue,
  min,
  max,
  unit = '%',
  dragArrows = true,
  disabled = false,
  enableGestureSlider = false,
}: {
  value: number;
  setValue: (v: number) => void;
  min?: number;
  max?: number;
  unit?: string;
  dragArrows?: boolean;
  disabled?: boolean;
  enableGestureSlider?: boolean;
}) => {
  const [dragOffset, setDragOffset] = useState(0); // Offset while dragging arrows
  const [isEditing, setIsEditing] = useState(false); // Whether the input is being edited
  const [tempInputValue, setTempInputValue] = useState<number | null>(null); // Temporary value while editing
  const [sliderStartValue, setSliderStartValue] = useState(value);
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  // Handler for dragging arrows
  const arrowDragBind = useDrag(
    ({ event, last, delta }) => {
      event.preventDefault();
      event.stopPropagation();

      const valueDiff =
        -1 * (delta[1] / DRAG_RANGE_PX) * ((max ?? 100) - (min ?? 0));
      let offset = dragOffset + valueDiff;
      if (min !== undefined && value + offset < min) {
        offset = min - value;
      }
      if (max !== undefined && value + offset > max) {
        offset = max - value;
      }
      if (last) {
        setValue(Math.round(value + offset));
      } else {
        setDragOffset(offset);
      }
    },
    {
      threshold: 3,
      filterTaps: true,
    }
  );

  // Handler for dragging slider
  const sliderDragBind = useDrag(
    ({ event, movement: [mx], first, last }) => {
      event.preventDefault();
      event.stopPropagation();

      if (first) {
        setSliderStartValue(value);
      }

      const range = (max ?? 100) - (min ?? 0);
      const valueDiff = (mx / DRAG_RANGE_PX) * range;
      const newValue = boundNumber(
        min ?? -Infinity,
        sliderStartValue + valueDiff,
        max ?? Infinity
      );

      if (last) {
        setValue(Math.round(newValue));
      } else {
        setValue(newValue);
      }
    },
    {
      axis: 'x',
      filterTaps: true,
    }
  );

  useEffect(() => {
    setDragOffset(0);
  }, [value]);

  useEffect(() => {
    if (isEditing && inputRef.current) inputRef.current.select();
  }, [isEditing]);

  // Handler for increasing value
  const handleIncrease: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(Math.min(value + 10, max ?? Infinity));
  };

  // Handler for decreasing value
  const handleDecrease: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(Math.max(value - 10, min ?? -Infinity));
  };

  if (disabled) {
    return (
      <Container>
        <Value
          type="b2"
          color="disabled"
          style={{ cursor: 'not-allowed', color: theme.text.disabled }}
        >
          {value.toFixed(0)}
          {unit}
        </Value>
      </Container>
    );
  }

  return (
    <Container>
      <Value type="b2" color="default" onClick={() => setIsEditing(true)}>
        {enableGestureSlider && <SliderContainer {...sliderDragBind()} />}
        {isEditing && (
          <ValueInput
            ref={inputRef}
            value={tempInputValue !== null ? tempInputValue : value}
            onKeyDown={(e) => {
              e.stopPropagation();

              if (e.key === 'Enter') {
                setIsEditing(false);

                const boundValue = boundNumber(
                  min ?? -Infinity,
                  tempInputValue || 0,
                  max ?? Infinity
                );
                setValue(Math.round(boundValue));
                setTempInputValue(null);
              }
            }}
            onChange={(e) => setTempInputValue(+e.target.value)}
            onBlur={() => {
              setIsEditing(false);

              const boundValue = boundNumber(
                min ?? -Infinity,
                tempInputValue || 0,
                max ?? Infinity
              );
              setValue(Math.round(boundValue));
              setTempInputValue(null);
            }}
            onFocus={() => setTempInputValue(value)}
            type="number"
            min={min}
            max={max}
            autoFocus
          />
        )}
        <span style={{ opacity: isEditing ? 0 : 1 }}>
          {(value + dragOffset).toFixed(0)}
          {unit}
        </span>
      </Value>
      {dragArrows && (
        <CarretsContainer {...arrowDragBind()}>
          <CarretUp onClick={handleIncrease} />
          <CarretDown onClick={handleDecrease} />
        </CarretsContainer>
      )}
    </Container>
  );
};
