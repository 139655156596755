export type PayloadMultiView = {
  type: 'multi_view';
  inputImageUrl: string;
  prompt: string;
  novelViewIndex: number;
  timeout: number;
};

export type PayloadMix = {
  type: 'mix';
  images: { url: string; weight: number }[];
  height: number;
  width: number;
  sizeRatio: number;
  timeout: number;
};

export type Payload3DStudio = {
  type: '3d_studio';
  resultRoomName: string;
  inputImage: string;
  prompt: string;
  influence: number;
  materialImages: string[];
  materialMasks: string[];
  timeout: number;
};

export type UpscalePayload = {
  type: 'upscale';
  inputImageUrl: string;
  timeout: number;
};

export enum PublicPalette {
  general = 'general',
  generalV2 = 'generalV2',
  technicolor = 'technicolor',
  technicolor_v2 = 'technicolor_v2',
  cybercel = 'cybercel',
  volume = 'volume',
  pastel = 'pastel',
  carInterior = 'carInterior',
  carExterior = 'carExterior',
  carExterior_v2 = 'carExterior_v2',
  surfaceSculpt = 'surfaceSculpt',
}

export type LoraPayload = {
  name: string;
  base_model: string;
  clip_skip: number;
  strength: number;
  prompt_template: string;
  negative_prompt_template: string;

  first_pass_cfg: number;
  first_pass_steps: number;
  first_pass_sampler: string;
  first_pass_scheduler: string;

  second_pass_cfg: number;
  second_pass_steps: number;
  second_pass_sampler: string;
  second_pass_scheduler: string;
  second_pass_denoise: number;

  url: string;
};

export type InferencePayload = {
  type: 'inference';
  resultTarget: 'workbenchPlaceholder' | 'promptOutput' | 'paletteThumbnail';
  targetWidth: number;
  targetHeight: number;

  mode: 'render' | 'refine';

  inputImageUrl: string;
  maskImageUrl?: string;
  prompt: string;
  negativePrompt?: string;
  influence: number;
  paletteInfluence: number;
  fidelity: number;

  palette: PublicPalette | LoraPayload;
  // this is only used when receiving the job output to populate the Prompt table
  publicPaletteId?: string;
  workbenchPaletteId?: string;
  customModelId?: string;

  styleReferenceUrl?: string;
  styleReferenceStrength?: number;

  workflow?: Record<string, any>;
  magicPrompt?: boolean;

  timeout: number;
  seed?: number;
};

export type StableDiffusionJobPayload =
  | InferencePayload
  | UpscalePayload
  | Payload3DStudio
  | PayloadMultiView
  | PayloadMix;

export interface StableDiffusionJobOutputBase {
  workerHostname: string;
  submittedAt: number; // timestamp at which the initial job was added to the queue
  processingDuration: number;
  postProcessingDuration?: number; // for stable-diffusion queues, this is the time it took to upload the results
}

export type JobOutputMultiView = StableDiffusionJobOutputBase & {
  type: PayloadMultiView['type'];
  inferredImagesPath: string[];
};

export type JobOutputMix = StableDiffusionJobOutputBase & {
  type: PayloadMix['type'];
  height: number;
  width: number;
  sizeRatio: number;
  inferredImagesPath: string[];
};

export type UpscaleJobOutput = StableDiffusionJobOutputBase & {
  type: UpscalePayload['type'];
  inferredImagesPath: string[];
};

export type InferenceJobOutput = StableDiffusionJobOutputBase & {
  type: InferencePayload['type'];
  inferredImagesPath: string[];
  resultTarget: InferencePayload['resultTarget'];
  targetWidth: number;
  targetHeight: number;
  input: InferencePayload;
  timings?: {
    workflowDuration: number;
    loraWeightDuration: number;
    sourceImagesDownloadDuration: number;
  };
};

export type StableDiffusionJobOutput =
  | UpscaleJobOutput
  | InferenceJobOutput
  | JobOutputMultiView
  | JobOutputMix;

export const STABLE_DIFFUSION_QUEUE_NAME = 'vizcom-inference';
export const STABLE_DIFFUSION_RESULT_QUEUE_NAME = 'vizcom-inference:result';

// before june 2024, we used a legacy type for prompt which could be one of:
// - stableDiffusion
// - controlNet_default
// - controlNet_technicolor
// - controlNet_cybercel
// - controlNet_volume
// - controlNet_pastel
// - controlNet_carInterior
// - controlNet_carExterior
// this function allows us to convert these legacy types to the new mode and palette info
export const promptLegacyTypeToModeAndPalette = (
  legacyType: string
): { mode: 'render' | 'refine'; publicPaletteId: PublicPalette } => {
  if (legacyType.startsWith('controlNet_')) {
    const publicPaletteId =
      legacyType.split('_')[1] === 'default'
        ? PublicPalette.general
        : legacyType.split('_')[1];
    return {
      mode: 'render',
      publicPaletteId: Object.values(PublicPalette).includes(
        publicPaletteId as any
      )
        ? (publicPaletteId as PublicPalette)
        : PublicPalette.general,
    };
  } else {
    return {
      mode: 'refine',
      publicPaletteId: PublicPalette.general,
    };
  }
};

export const WHITE_MASK_PLACEHOLDER = 'WHITE_MASK_PLACEHOLDER';
