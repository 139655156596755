const files = {
  dashboard: () => `/files`,
  organizationRecent: (organizationId: string) =>
    `/files/${organizationId}/recent`,
  folder: (folderId: string) => `/files/folder/${folderId}`,
  organizationDraftFolder: (organizationId: string) =>
    `/files/organization/${organizationId}/drafts`,
  team: (id: string) => `/files/team/${id}`,
};

const auth = {
  home: () => `/auth`,
  signIn: () => `/auth?step=signIn`,
  signUp: () => `/auth?step=signUp`,
  onBoarding: () => `/auth?step=onBoarding`,
  registerWithToken: (token: string) => `/auth/register/token#token=${token}`,
};

const admin = {
  dashboard: () => `/admin`,
  featureFlags: () => `/admin/flags`,
  organizations: () => `/admin/orgs`,
  customModels: () => `/admin/custommodels`,
  users: () => `/admin/users`,
  changelog: () => `/admin/changelog`,
};

const workbench = {
  file: (id: string) => `/workbench/${id}`,
  new: (folderId: string) => `/workbench/folder/${folderId}/new`,
  compositeSceneEditor: (workbenchId: string, compositeSceneId: string) =>
    `/workbench/${workbenchId}/3d/${compositeSceneId}`,
  drawing: (workbenchId: string, drawingId: string) =>
    `/workbench/${workbenchId}/drawing/${drawingId}`,
};

const settings = {
  account: {
    profile: () => `/settings/account/profile`,
  },
  organization: {
    general: (id: string) => `/settings/organization/${id}/general`,
    members: (id: string) => `/settings/organization/${id}/members`,
    membersInvite: (id: string) =>
      `/settings/organization/${id}/members/invite`,
    teams: (id: string) => `/settings/organization/${id}/teams`,
    createTeam: (id: string) => `/settings/organization/${id}/teams/create`,
    teamSettings: (orgId: string, teamId: string) =>
      `/settings/organization/${orgId}/teams/${teamId}/settings`,
    teamInvite: (orgId: string, teamId: string) =>
      `/settings/organization/${orgId}/teams/${teamId}/invite`,
    subscription: (id: string) => `/settings/organization/${id}/subscription`,
    security: (id: string) => `/settings/organization/${id}/security`,
    accessLog: (id: string) => `/settings/organization/${id}/access`,
    activityLog: (id: string) => `/settings/organization/${id}/activity`,
  },
  app: {
    changelog: () => `/settings/app/changelog`,
  },
};

const organization = {
  acceptInvite: (organizationId: string) =>
    `/organization/${organizationId}/acceptinvite`,
  create: () => `/organization/create`,
  create_name: () => `/organization/create/name`,
};

const onboarding = {
  profile: () => `/onboarding/profile`,
};

const upgradeSubscription = {
  root: (plan: string) => `/upgrade/select-organization?plan=${plan}`,
  organizationSelectEditor: (organizationId: string, plan: string) =>
    `/upgrade/${organizationId}/review-editors?plan=${plan}`,
};

const mobileWorkbench = {
  upload: (workbenchId: string) => `/mobile-workbench/upload/${workbenchId}`,
};

export const paths = {
  files,
  auth,
  admin,
  settings,
  workbench,
  mobileWorkbench,
  organization,
  onboarding,
  upgradeSubscription,
};
