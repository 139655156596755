import { useMutation } from 'urql';
import { graphql } from '../gql';

const CreateChangelogMutation = graphql(/* GraphQL */ `
  mutation CreateChangelog($input: CreateChangelogInput!) {
    createChangelog(input: $input) {
      changelog {
        ...ChangelogData
      }
    }
  }
`);

export const useCreateChangelog = () => useMutation(CreateChangelogMutation);

export const CreateChangelogResourceMutation = graphql(/* GraphQL */ `
  mutation CreateChangelogResource($input: CreateChangelogResourceInput!) {
    createChangelogResource(input: $input) {
      changelogResource {
        id
        path
      }
    }
  }
`);
