import { useMutation } from 'urql';
import { paths } from '@vizcom/shared-utils-paths';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../gql';
import { CreateUserInput } from '../gql/graphql';

const GenerateRegisterToken = graphql(/* GraphQL */ `
  mutation generateRegisterToken($input: GenerateRegisterTokenInput!) {
    generateRegisterToken(input: $input) {
      sentByEmail
    }
  }
`);

export const useGenerateRegisterToken = () => {
  return useMutation(GenerateRegisterToken);
};

const CreateUser = graphql(/* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      authToken
    }
  }
`);

export const useCreateUser = (opts = { noRedirect: false }) => {
  const navigate = useNavigate();
  const [r, createUser] = useMutation(CreateUser);

  const createUserWrapper = async (input: CreateUserInput) => {
    const res = await createUser({ input });
    if (!res.error && res.data?.createUser) {
      if (!opts.noRedirect) {
        navigate(paths.files.dashboard(), { replace: true });
      }
    }
    return res;
  };

  return [r, createUserWrapper] as const;
};
