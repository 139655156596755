import * as z from 'zod';

import { WorkbenchSharingEventSchema } from './WorkbenchSharingEventSchema';
import { WorkbenchTrackingEventSchema } from './WorkbenchTrackingEventSchema';
import { DrawingTrackingEventSchema } from './DrawingTrackingEventSchema';
import { UserTrackingEventSchema } from './UserTrackingEventSchema';
import { TeamTrackingEventSchema } from './TeamTrackingEventSchema';
import { FolderTrackingEventSchema } from './FolderTrackingEventSchema';
import { StudioTrackingEventSchema } from './StudioTrackingEventSchema';
import { PaywallTrackingEventSchema } from './PaywallTrackingEventSchema';
import { InferenceTrackingEventSchema } from './InferenceTrackingEventSchema';
import { PaletteTrackingEventSchema } from './PaletteTrackingEventSchema';
import { Inference3dTrackingEventSchema } from './Inference3dTrackingEventSchema';

export const TrackingEventSchema = z.discriminatedUnion('type', [
  DrawingTrackingEventSchema,
  WorkbenchTrackingEventSchema,
  WorkbenchSharingEventSchema,
  UserTrackingEventSchema,
  TeamTrackingEventSchema,
  FolderTrackingEventSchema,
  StudioTrackingEventSchema,
  PaywallTrackingEventSchema,
  InferenceTrackingEventSchema,
  PaletteTrackingEventSchema,
  Inference3dTrackingEventSchema,
]);

export type TrackingEvent = z.infer<typeof TrackingEventSchema>;
