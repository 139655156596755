import styled from 'styled-components';
import { Text } from '../Text/Text';
import { Button } from '../Button';
import { Link } from 'react-router-dom';
import { useCrisp } from '../HelpCenter/crisp';

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  flex-direction: column;
  padding: 4em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface LoadingErrorPageProps {
  targetType: string;
  errorMessage?: string;
}

export const LoadingErrorPage = (props: LoadingErrorPageProps) => {
  const crisp = useCrisp();

  return (
    <FullPageContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          margin: '24px 0',
        }}
      >
        <Text block type="h1">
          There was an error while loading the {props.targetType} you were
          looking for
        </Text>
        <Text block type="sh2">
          This can happen if:
        </Text>
        <Text block>
          - You copy-pasted a broken link, make sure you copied the whole link
        </Text>
        <Text block>- You're not connected to the internet</Text>
        <Text block>- Our servers are currently in maintainance</Text>
      </div>
      {props.errorMessage && (
        <Text block color="danger" style={{ margin: '24px 0', maxWidth: 450 }}>
          {props.errorMessage}
        </Text>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <Button as={Link} to={'/'}>
          Return to dashboard
        </Button>
        <Text block>- Or -</Text>
        <Button variant="secondary" onClick={() => crisp.openChat()}>
          Contact support
        </Button>
      </div>
    </FullPageContainer>
  );
};
