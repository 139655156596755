import { graphql } from '../gql';

export const AllUserClientStatesFragment = graphql(/* GraphQL */ `
  fragment AllUserClientState on User {
    clientStates {
      nodes {
        userId
        value
        key
      }
    }
  }
`);
