import { graphql } from '../../gql';

export const DeleteWorkbenchElementsMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElements(
    $deleteWorkbenchElementsInput: DeleteWorkbenchElementsInput!
  ) {
    deleteWorkbenchElements(input: $deleteWorkbenchElementsInput) {
      status
    }
  }
`);
