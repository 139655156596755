import styled from 'styled-components';
import {
  Button,
  CheckIcon,
  MembersOutlineIcon,
  PencilOutlineIcon,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  OrganizationSubscriptionPlan,
  useSubscribeToPlan,
} from '@vizcom/shared/data-access/graphql';
import { Link } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';

export const OrganizationPlansTable = (props: {
  currentPlan: OrganizationSubscriptionPlan;
  organizationId: string;
  organizationPoc: boolean;
  onClose?: () => void;
}) => {
  const { currentPlan, organizationId, organizationPoc, onClose } = props;

  const [res, subscribeToPlan] = useSubscribeToPlan();

  const handleDowngrade = async (plan: OrganizationSubscriptionPlan) => {
    // for downgrading, directly redirects to Stripe. If upgrading redirect to the upgrade page instead
    const res = await subscribeToPlan({
      input: {
        organizationId,
        plan,
      },
    });
    if (res.error || !res.data) {
      addToast('Error while downgrading the plan', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
      return;
    }
    window.location.assign(res.data.subscribeToPlan.url);
    onClose?.();
  };

  return (
    <PlansContainer>
      <PlanContainer
        $active={currentPlan === OrganizationSubscriptionPlan.Free}
      >
        <div style={{ marginBottom: '24px' }}>
          <PlanTitle
            $poc={
              organizationPoc &&
              currentPlan === OrganizationSubscriptionPlan.Free
            }
          >
            Starter
          </PlanTitle>
          <PlanPrice>
            $0
            <PlanPriceDetail>per editor/month</PlanPriceDetail>
          </PlanPrice>
          <PlanSubtitle>
            For individuals looking to visualize today, at no cost. Perfect for
            getting started with Vizcom.
          </PlanSubtitle>
        </div>
        <PlanCta>
          {currentPlan === OrganizationSubscriptionPlan.Free ? (
            <Button variant="bordered" disabled size="S">
              Current plan
            </Button>
          ) : currentPlan === OrganizationSubscriptionPlan.Enterprise ? (
            <Button
              variant="bordered"
              as="a"
              href="https://www.vizcom.ai/contact"
              target="_blank"
              size="S"
            >
              Contact sales
            </Button>
          ) : (
            <Button
              onClick={() => handleDowngrade(OrganizationSubscriptionPlan.Free)}
              variant="bordered"
              size="S"
            >
              Downgrade
            </Button>
          )}
        </PlanCta>
        <div style={{ marginTop: '24px' }}>
          <PlanFeature>
            <MembersOutlineIcon /> 1 team
          </PlanFeature>
          <PlanFeature>
            <PencilOutlineIcon /> 2 editors
          </PlanFeature>
          <PlanFeatureTitle>Starter includes:</PlanFeatureTitle>
          <PlanFeature>
            <CheckIcon /> Unlimited files
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Unlimited exports
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Unlimited file viewers
          </PlanFeature>
        </div>
      </PlanContainer>
      <PlanContainer $active={currentPlan === OrganizationSubscriptionPlan.Pro}>
        <div style={{ marginBottom: '24px' }}>
          <PlanTitle
            $poc={
              organizationPoc &&
              currentPlan === OrganizationSubscriptionPlan.Pro
            }
          >
            Professional
          </PlanTitle>
          <PlanPrice>
            $49 <PlanPriceDetail>per editor/month</PlanPriceDetail>
          </PlanPrice>
          <PlanSubtitle>
            For working professionals and small teams that want to get started
            with product visualization.
          </PlanSubtitle>
        </div>
        <PlanCta>
          {currentPlan === OrganizationSubscriptionPlan.Pro ? (
            <Button variant="bordered" disabled size="S">
              Current plan
            </Button>
          ) : currentPlan === OrganizationSubscriptionPlan.Enterprise ? (
            <Button
              variant="bordered"
              as="a"
              href="https://www.vizcom.ai/contact"
              target="_blank"
              size="S"
            >
              Contact sales
            </Button>
          ) : (
            <Button
              variant="bordered"
              as={Link}
              to={paths.upgradeSubscription.root(
                OrganizationSubscriptionPlan.Pro
              )}
              size="S"
              onClick={onClose}
            >
              Upgrade
            </Button>
          )}
        </PlanCta>
        <div style={{ marginTop: '24px' }}>
          <PlanFeature>
            <MembersOutlineIcon /> 1 team
          </PlanFeature>
          <PlanFeature>
            <PencilOutlineIcon /> Unlimited editors
          </PlanFeature>
          <PlanFeatureTitle>Everything in Starter, plus:</PlanFeatureTitle>
          <PlanFeature>
            <CheckIcon /> Priority render queue
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> 4 image generation
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Advanced 3D model generation
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> 4K export
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Content privacy and ownership
          </PlanFeature>
        </div>
      </PlanContainer>
      <PlanContainer
        $active={currentPlan === OrganizationSubscriptionPlan.Enterprise}
      >
        <div style={{ marginBottom: '24px' }}>
          <PlanTitle
            $poc={
              organizationPoc &&
              currentPlan === OrganizationSubscriptionPlan.Enterprise
            }
          >
            Enterprise
          </PlanTitle>
          <PlanPrice>
            Let's chat <PlanPriceDetail>custom pricing</PlanPriceDetail>
          </PlanPrice>
          <PlanSubtitle>
            For organizations that want to scale their design process, with full
            support and advanced security.
          </PlanSubtitle>
        </div>
        <PlanCta>
          {currentPlan === OrganizationSubscriptionPlan.Enterprise ? (
            <Button disabled size="S" variant="bordered">
              Current plan
            </Button>
          ) : (
            <Button
              as="a"
              href="https://www.vizcom.ai/contact"
              target="_blank"
              size="S"
              variant="bordered"
            >
              Contact sales
            </Button>
          )}
        </PlanCta>
        <div style={{ marginTop: '24px' }}>
          <PlanFeature>
            <MembersOutlineIcon /> Unlimited teams
          </PlanFeature>
          <PlanFeature>
            <PencilOutlineIcon /> Custom seats
          </PlanFeature>
          <PlanFeatureTitle>Everything in Professional, plus:</PlanFeatureTitle>
          <PlanFeature>
            <CheckIcon /> Custom AI models
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Shared render styles library
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Dedicated onboarding
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Custom billing
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Custom security & privacy
          </PlanFeature>
          <PlanFeature>
            <CheckIcon /> Priority support
          </PlanFeature>
        </div>
      </PlanContainer>
    </PlansContainer>
  );
};

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

const PlanContainer = styled.div<{ $active: boolean }>`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  background-color: ${(p) => (p.$active ? p.theme.surface.e1 : 'transparent')};
  border: 1px solid ${(p) => p.theme.surface.e2};
  border-radius: 12px;
  padding: 24px;
`;

const PlanTitle = styled.p<{ $poc: boolean }>`
  font-size: 20px;
  font-weight: 700;
  color: ${(p) => p.theme.text.default};
  position: relative;
  display: inline;
  margin-bottom: 16px;
  ${(props) =>
    props.$poc &&
    `&:after {
    content: "POC";
    color: #af792e;
    border: 1px solid #af792e;
    background-color: #af792e25;
    font-size: 10px;
    font-weight: 900;
    border-radius: 16px;
    padding: 4px 8px;
    position: absolute;
    top: 50%;
    right: calc(0% - 10px);
    transform: translate(100%, -50%);
  }`}
`;

const PlanPrice = styled.div`
  font-size: 32px;
  color: ${(p) => p.theme.text.default};
  margin: 16px 0;
`;

const PlanPriceDetail = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
`;

const PlanSubtitle = styled.p`
  font-size: 10px;
  color: ${(p) => p.theme.text.default};
  margin-top: 16px;
`;

const PlanCta = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  & > * {
    width: 100%;
  }
`;

const PlanFeatureTitle = styled.p`
  font-weight: 700;
  margin: 24px 0 16px;
  color: ${(p) => p.theme.text.default};
`;

const PlanFeature = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p.theme.text.default};
  margin-top: 12px;
  gap: 12px;
`;
