import styled from 'styled-components';
import { useStringHashNumber } from './hooks/useStringHashNumber';
import { TeamAvatar, teamAvatarColors } from './TeamAvatar';
import { CloseIcon } from './icons';

interface BadgeProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  id: string;
  title: string;
  size: 'small' | 'medium' | 'large';
  compact?: boolean;
  avatar?: boolean;
  lettersToDisplay?: number;
  onClose?: () => void;
}

export const Badge = (props: BadgeProps) => {
  const { id, size, title, onClose, compact, avatar = false } = props;
  const hash = useStringHashNumber(id);

  const opacity = avatar ? '20' : '00';
  const color = hash
    ? teamAvatarColors[hash % teamAvatarColors.length] + opacity
    : '#FFFFFF';

  if (compact) {
    return <TeamAvatar teamId={id} name={title} size={size} />;
  }

  return (
    <BadgeContainer $color={color} $size={size}>
      {avatar && <TeamAvatar teamId={id} name={title} size={size} />}
      {!compact && <TextClamped title={title}>{title}</TextClamped>}
      {onClose && <CloseIcon height={15} onClick={onClose} />}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div<{
  $color: string;
  $size: 'small' | 'medium' | 'large';
}>`
  background-color: ${(p) => p.$color};
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  font-size: 10px;
  max-width: min(100%, 120px);
  font-size: ${(p) =>
    p.$size === 'large' ? 20 : p.$size === 'medium' ? 15 : 9}px;
`;

const TextClamped = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
