import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  height: 0px;
  width: 0px;
`;

export const TooltipMenuContainer = styled.div<{
  $left: number;
  $top: number;
  $height: number;
  $width: number;
  $direction: 'up' | 'down' | 'left' | 'right';
  $showArrow: boolean;
}>`
  position: absolute;
  background: ${({ theme }) => theme.surface.e0};
  left: ${({ $left }) => $left}px;
  top: ${({ $top }) => $top}px;
  width: fit-content;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 100;

  :after,
  :before {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    display: ${({ $showArrow }) => ($showArrow ? 'block' : 'none')};
    ${({ $direction, $height, $width }) => {
      switch ($direction) {
        case 'up':
          return `
            bottom: 100%;
            left: ${$width}px;
          `;
        case 'down':
          return `
            top: 100%;
            left: 50%;
          `;
        case 'left':
          return `
            top: ${$height}px;
            right: 100%;
          `;
        case 'right':
          return `
            top: ${$height}px;
            left: 100%;
          `;
      }
    }}
  }

  :before {
    ${({ $direction, theme }) => {
      switch ($direction) {
        case 'up':
          return `
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid ${theme.surface.e0};
          `;
        case 'down':
          return `
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-top: 10px solid ${theme.surface.e0};
          `;
        case 'left':
          return `
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid ${theme.surface.e0};
          `;
        case 'right':
          return `
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid ${theme.surface.e0};
          `;
      }
    }}
  }
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.text.default};
  padding: 10px 16px;
  align-items: center;
  gap: 10px;

  :hover {
    background: ${({ theme }) => theme.surface.e1};
  }
`;
