import styled, { css } from 'styled-components';

const buttonTypes = {
  primary: css`
    color: ${(p) => p.theme.text.default};
    background-color: ${(p) => p.theme.primary.default};
    & > svg {
      color: ${(p) => p.theme.text.default};
    }
    :hover {
      background-color: ${(p) => p.theme.primary.hover};
    }
    :focus {
      background-color: ${(p) => p.theme.primary.default};
    }
    :disabled {
      color: ${(p) => p.theme.text.primary};
      & > svg {
        color: ${(p) => p.theme.text.primary};
      }
      background-color: ${(p) => p.theme.primary.disabled};
    }
  `,
  secondary: css`
    color: ${(p) => p.theme.text.default};
    background-color: ${(p) => p.theme.surface.e2};
    :hover {
      background-color: ${(p) => p.theme.surface.e1};
    }
    :focus {
      background-color: ${(p) => p.theme.surface.e1};
    }
    :disabled {
      color: ${(p) => p.theme.text.secondary};
      background-color: ${(p) => p.theme.surface.e2};
    }
  `,
  tertiary: css`
    color: ${(p) => p.theme.text.info};
    background-color: transparent;
    :focus {
      background-color: rgba(57, 57, 57, 0.1);
      color: ${(p) => p.theme.text.default};
      box-shadow: 0 0 0 1px ${(p) => p.theme.tertiary.hover};
    }
  `,
  transparent: css`
    background-color: transparent;
    color: ${(p) => p.theme.text.default};
    :disabled {
      color: ${(p) => p.theme.text.secondary};
    }
    :not(:disabled) {
      :hover {
        background-color: rgba(57, 57, 57, 0.5);
      }
      :focus {
        background-color: rgba(57, 57, 57, 0.5);
      }
    }
  `,
  outline: css`
    background-color: transparent;
    color: ${(p) => p.theme.text.info};
    :hover {
      color: ${(p) => p.theme.text.default};
    }
    :focus {
      color: ${(p) => p.theme.text.default};
    }
  `,
  bordered: css`
    background-color: transparent;
    border: 1px solid ${(p) => p.theme.white};
    color: ${(p) => p.theme.white};
    :disabled {
      border: 1px solid ${(p) => p.theme.text.secondary};
      color: ${(p) => p.theme.text.secondary};
    }
  `,
  fullWhite: css`
    background-color: white;
    color: ${(p) => p.theme.surface.e0};
  `,
  fullBlack: css`
    background-color: ${(p) => p.theme.surface.e0};
    color: #f9f9fa;
  `,
  transparentNoHover: css`
    background-color: transparent;
    color: ${(p) => p.theme.text.default};
  `,
  danger: css`
    background-color: ${(p) => p.theme.functional.danger.default};
    color: ${(p) => p.theme.text.default};
    :disabled {
      color: ${(p) => p.theme.text.secondary};
      background-color: ${(p) => p.theme.surface.e2};
    }
    :not(:disabled) {
      :hover,
      :active,
      :focus {
        background-color: ${(p) => p.theme.functional.danger.hover};
      }
    }
  `,
  warning: css`
    background-color: ${(p) => p.theme.functional.warning.default};
    color: ${(p) => p.theme.text.default};
    :disabled {
      color: ${(p) => p.theme.text.secondary};
      background-color: ${(p) => p.theme.surface.e2};
    }
    :not(:disabled) {
      :hover,
      :active,
      :focus {
        background-color: ${(p) => p.theme.functional.warning.hover};
      }
    }
  `,
  action2: css`
    background-color: #2f2f31;
    color: ${(p) => p.theme.text.default};

    :not(:disabled) {
      :hover,
      :active,
      :focus {
        background-color: #3c3c3e;
      }
    }
  `,
} as const;

const buttonSizes = {
  L: css`
    padding: 10px 18px;
    font-size: 14px;
  `,
  M: css`
    padding: 8px 18px;
    font-size: 12px;
  `,
  S: css`
    padding: 8px 8px;
    font-size: 10px;
  `,
  icon: css`
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  iconSquared: css`
    height: 32px;
    width: 32px;
    padding: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  `,
} as const;

interface ButtonElProps {
  variant?: keyof typeof buttonTypes;
  size?: keyof typeof buttonSizes;
}

const StyledButton = styled.button<ButtonElProps>`
  display: block;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.1s ease, color 0.1s ease, box-shadow 0.05s ease;
  border: none;
  box-shadow: 0 0 0 0px transparent;
  text-align: center;

  ${({ variant }) => buttonTypes[variant || 'primary']}
  ${({ size }) => buttonSizes[size || 'L']}

  :disabled {
    cursor: not-allowed;
  }
`;

export const Button = StyledButton;
