import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const PaletteEventTypeZodEnum = z.enum([
  'ADD_IMAGES',
  'REMOVE_IMAGES',
  'TRAIN',
  'CANCEL_TRAINING',
]);

export const PaletteEventName = PaletteEventTypeZodEnum.Enum;
export const PaletteTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: PaletteEventTypeZodEnum,
  data: z.object({
    paletteId: z.string(),
    imagesCount: z.number().optional(),
  }),
});
