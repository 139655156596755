import { graphql } from '../gql';
import { useQuery } from 'urql';

const customModelsByOrganizationId = graphql(/* GraphQL */ `
  query customModels($organizationId: UUID!) {
    customModels(condition: { organizationId: $organizationId }) {
      nodes {
        id
        name
        thumbnailPath
      }
    }
  }
`);

export const useCustomModels = (organizationId: string | undefined | null) => {
  const [res] = useQuery({
    query: customModelsByOrganizationId,
    variables: { organizationId: organizationId! },
    pause: !organizationId,
  });

  return { ...res, data: res.data?.customModels };
};

const allCustomModels = graphql(/* GraphQL */ `
  query allCustomModels {
    customModels {
      nodes {
        ...CustomModelFullData
      }
    }
  }
`);

export const useAllCustomModels = () => {
  const [res] = useQuery({
    query: allCustomModels,
  });

  return { ...res, data: res.data?.customModels };
};
