import { useEffect } from 'react';
import { atom, selector, useRecoilState } from 'recoil';

export const isMobileAtom = atom<boolean>({
  key: 'isMobile',
  default: false,
});

export const isSidebarOpenAtom = atom<boolean>({
  key: 'isSidebarOpen',
  default: false,
});

export const shouldDisplaySidebarAtom = selector<boolean>({
  key: 'shouldDisplaySidebar',
  get: ({ get }) => {
    const isMobile = get(isMobileAtom);
    const isSidebarOpen = get(isSidebarOpenAtom);

    return !isMobile || isSidebarOpen;
  },
});

export const useSidebarOpenStateSync = () => {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenAtom);

  const handler = () => {
    const value = window.matchMedia('(max-width: 645px)').matches;

    setIsMobile(value);
  };

  useEffect(() => {
    const observer = new ResizeObserver(handler);
    observer.observe(document.body);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [isMobile, setIsSidebarOpen]);

  return [!isMobile || isSidebarOpen, setIsSidebarOpen] as const;
};
