import {
  atom,
  CallbackInterface,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
const stored = localStorage.getItem('selectedTheme');

type Theme = 'dark' | 'light';
type SelectedTheme = Theme | 'device';

export const deviceThemeState = atom<Theme>({
  key: 'deviceTheme',
  default: prefersDark.matches ? 'dark' : 'light',
});

export const selectedThemeState = atom<SelectedTheme>({
  key: 'selectedTheme',
  default: ['device', 'dark', 'light'].includes(stored!)
    ? (stored! as SelectedTheme)
    : 'device',
});

export const setTheme =
  ({ set }: CallbackInterface) =>
  (theme: Theme) => {
    localStorage.setItem('selectedTheme', theme);
    set(selectedThemeState, theme);
  };

export const useSelectedTheme = () =>
  [useRecoilValue(selectedThemeState), useRecoilCallback(setTheme, [])] as [
    SelectedTheme,
    (theme: SelectedTheme) => void
  ];
