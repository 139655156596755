import React from 'react';
import * as Sentry from '@sentry/react';

const SentryErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
