import { create } from 'zustand';
import { useState, ReactNode, useEffect, memo } from 'react';
import { Delay } from './OnboardingTooltip';
import {
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
} from './RichTooltip/RichTooltip';
import { Placement } from '@floating-ui/react';
import { omit } from 'lodash';

export enum TooltipNotificationName {
  ExitInferenceTray = 'exit-inference-tray',
}

interface TooltipNotificationState {
  tooltipsDisplayState: { [key in TooltipNotificationName]?: boolean };
  setDisplay: (name: TooltipNotificationName, show: boolean) => void;
  unregisterTooltip: (name: TooltipNotificationName) => void;
}

export const useTooltipNotificationState = create<TooltipNotificationState>(
  (set) => ({
    tooltipsDisplayState: {},
    setDisplay: (name, show) => {
      set((state) => ({
        tooltipsDisplayState: {
          ...state.tooltipsDisplayState,
          [name]: show,
        },
      }));
    },
    unregisterTooltip: (name) => {
      set((state) => ({
        tooltipsDisplayState: omit(state.tooltipsDisplayState, name),
      }));
    },
  })
);

interface TooltipNotificationProps {
  children: ReactNode;
  message: string;
  placement?: Placement;
  name: TooltipNotificationName;
  durationVisible?: number;
  autoTriggerAfter?: number;
}

const TooltipNotificationComponent = ({
  children,
  message,
  name,
  autoTriggerAfter,
  placement = 'top',
  durationVisible = 10_000,
}: TooltipNotificationProps) => {
  const setDisplay = useTooltipNotificationState((s) => s.setDisplay);
  const unregisterTooltip = useTooltipNotificationState(
    (s) => s.unregisterTooltip
  );
  const stateIsOpen = useTooltipNotificationState((s) =>
    Boolean(s.tooltipsDisplayState[name])
  );

  const [autoTriggerDisabled, setAutoTriggerDisabled] =
    useState<boolean>(false);

  const shouldAutoTrigger =
    !autoTriggerDisabled && autoTriggerAfter && !stateIsOpen;
  const openTooltip = Boolean(stateIsOpen || shouldAutoTrigger);
  const delay = shouldAutoTrigger ? autoTriggerAfter : 0;

  useEffect(() => () => unregisterTooltip(name), []);

  return (
    <RichTooltip placement={placement} manualOpen={openTooltip}>
      <RichTooltipTrigger>{children}</RichTooltipTrigger>
      <Delay
        delay={delay}
        durationVisible={durationVisible}
        startTimer={openTooltip}
        onDurationVisibleEnds={() => {
          setDisplay(name, false);
          setAutoTriggerDisabled(true);
        }}
      >
        <RichTooltipContent
          style={{ color: 'white' }}
          $backgroundSurface="primary"
        >
          {message}
        </RichTooltipContent>
      </Delay>
    </RichTooltip>
  );
};

export const TooltipNotification = memo(TooltipNotificationComponent);
