import {
  WorkbenchElementMixData,
  WorkbenchElementSectionData,
  WorkbenchElementTextData,
} from '@vizcom/shared/data-access/graphql';
import {
  ClientSideWorkbenchElementData,
  ClientSideWorkbenchElementDrawing,
  ClientSideWorkbenchElementPalette,
} from './clientState';
import { createContext, useContext } from 'react';
import * as THREE from 'three';

export const GENERATED_IMAGES_MARGIN = 30;

export const elementById = (
  elements: ClientSideWorkbenchElementData[],
  id: string | null | undefined
) => {
  if (!id) {
    return null;
  }

  return elements.find((element) => element.id === id);
};

export const generateVersionNonce = () => {
  return Math.floor(Math.random() * 1_000_000_000);
};

export const generateId = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const elementIsDrawing = (
  element: ClientSideWorkbenchElementData
): element is ClientSideWorkbenchElementDrawing => {
  return element.__typename === 'Drawing';
};

export const elementIsText = (
  element: ClientSideWorkbenchElementData
): element is WorkbenchElementTextData => {
  return element.__typename === 'WorkbenchElementText';
};

export const elementIsMix = (
  element: ClientSideWorkbenchElementData
): element is WorkbenchElementMixData => {
  return element.__typename === 'WorkbenchElementMix';
};

export const elementIsSection = (
  element: ClientSideWorkbenchElementData
): element is WorkbenchElementSectionData => {
  return element.__typename === 'WorkbenchElementSection';
};

export const elementIsPalette = (
  element: ClientSideWorkbenchElementData
): element is ClientSideWorkbenchElementPalette => {
  return element.__typename === 'WorkbenchElementPalette';
};

export const isDraggingContext = createContext({
  current: false,
});

export const isViewerContext = createContext<boolean | null>(null);

export const useIsWorkbenchViewer = () => {
  const isViewer = useContext(isViewerContext);

  if (isViewer === null) {
    throw new Error('useIsWorkbenchViewer must be used within a Workbench');
  }
  return isViewer;
};

export const floorPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

export const isOrthographicCamera = (
  def: THREE.Camera
): def is THREE.OrthographicCamera =>
  def && (def as THREE.OrthographicCamera).isOrthographicCamera;
