import { atom, useSetRecoilState } from 'recoil';

export const paywallModalOpen = atom<boolean>({
  key: 'paywallModalOpen',
  default: false,
});

export const useTriggerPaywallModalOpen = () => {
  const setState = useSetRecoilState(paywallModalOpen);

  return () => {
    setState(true);
  };
};
