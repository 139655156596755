import { useMutation } from 'urql';
import { graphql } from '../../gql';

const CreateOrganizationSubscriptionManagementPortal = graphql(/* GraphQL */ `
  mutation CreateOrganizationSubscriptionManagementPortal(
    $input: CreateOrganizationSubscriptionManagementPortalInput!
  ) {
    createOrganizationSubscriptionManagementPortal(input: $input) {
      url
    }
  }
`);

export const useCreateOrganizationSubscriptionManagementPortal = () =>
  useMutation(CreateOrganizationSubscriptionManagementPortal);

const SubscribeToPlan = graphql(/* GraphQL */ `
  mutation SubscribeToPlan($input: SubscribeToPlanInput!) {
    subscribeToPlan(input: $input) {
      url
    }
  }
`);

export const useSubscribeToPlan = () => useMutation(SubscribeToPlan);
