import {
  autoUpdate,
  flip,
  useFloating,
  useInteractions,
  useListNavigation,
  useTypeahead,
  useClick,
  useDismiss,
  useRole,
  FloatingFocusManager,
  FloatingList,
  Placement,
  offset,
} from '@floating-ui/react';
import React, { useContext } from 'react';

import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number) => void;
}

const SelectContext = createContext<SelectContextValue | null>(null);

export const useSelectContext = () => {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error('Select must be used within SelectContext provider');
  }

  return context;
};

export type CustomSelectTriggerContentProps = {
  selectedIndex: number | null;
};

type SelectProps = {
  children: ReactNode;
  onSelectElement: (value: string | null, index: number) => void;
  placement?: Placement;
  selectedOptionIndex?: number | null;
  customSelectedTriggerContent?: React.FC<CustomSelectTriggerContentProps>;
};

export function Select({
  children,
  selectedOptionIndex = null,
  placement = 'bottom-end',
  onSelectElement,
  customSelectedTriggerContent,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    selectedOptionIndex
  );
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), offset(10)],
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string>>([]);

  const handleSelect = useCallback(
    (index: number) => {
      const selectedOptionValue =
        elementsRef.current[index]?.getAttribute('value') || null;

      setSelectedIndex(index);
      setIsOpen(false);
      setSelectedLabel(labelsRef.current[index]);
      onSelectElement(selectedOptionValue, index);
    },
    [onSelectElement]
  );

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });

  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: (index) => {
      if (isOpen) setActiveIndex(index);
      else handleSelect(index);
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNav, typeahead, click, dismiss, role]
  );

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect]
  );

  return (
    <SelectContainer>
      <SelectTriggerContainer
        $isOpen={isOpen}
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
      >
        {customSelectedTriggerContent ? (
          customSelectedTriggerContent({ selectedIndex })
        ) : (
          <div
            style={{
              padding: '10px 16px 10px 16px',
            }}
          >
            {selectedLabel ?? 'Select an option...'}
          </div>
        )}
      </SelectTriggerContainer>

      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                <OptionsContainer>{children}</OptionsContainer>
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  flex: 1;
`;

const SelectTriggerContainer = styled.div<{
  $isOpen: boolean;
}>`
  background-color: ${(p) => p.theme.surface.e2};
  color: ${(p) => p.theme.text.default};
  width: 100%;

  border-radius: 6px;
  cursor: pointer;

  border: ${(p) =>
    p.$isOpen
      ? `2px solid ${p.theme.primary.default}`
      : '2px solid transparent'};

  :hover {
    opacity: 0.8;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 8px;
  background-color: ${(p) => p.theme.surface.e1};
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
`;
