import { useMutation } from 'urql';
import { graphql } from '../../gql';

export const SetCurrentUserClientStateMutation = graphql(/* GraphQL */ `
  mutation SetCurrentUserClientState($input: SetCurrentUserClientStateInput!) {
    setCurrentUserClientState(input: $input) {
      query {
        currentUser {
          id
          ...AllUserClientState
        }
      }
    }
  }
`);

export const useSetCurrentUserClientState = () => {
  return useMutation(SetCurrentUserClientStateMutation);
};
