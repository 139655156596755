import styled from 'styled-components';
import DropdownIconUrl from './dropdown.svg?url';
import Model3dIconUrl from './model_3d.svg?url';

import ArrangeIcon from './arrange.svg?react';
import MenuHorizontalIconNoStyled from './menu-horizontal.svg?react';
import DownloadIconNotStyled from './download.svg?react';
import UploadIconNotStyled from './upload.svg?react';
import EditIconNotStyled from './edit.svg?react';
import AiSparklesIconNotStyled from './ai_sparkles.svg?react';
import ImageIconNotStyled from './image.svg?react';
import PromptToolbarIcon from './prompt.svg?react';
import SketchToolbarIcon from './sketch.svg?react';
import CubeToolbarIcon from './3d_cube.svg?react';
import MixToolbarIcon from './mix.svg?react';
import PaletteToolbarIcon from './palette.svg?react';
import ImageBlockToolbarIcon from './image_block.svg?react';
import AddIconNotStyled from './add.svg?react';
import SearchIconNotStyled from './search.svg?react';
import CarretDownIconNotStyled from './carret_down.svg?react';
import CheckIconNotStyled from './check.svg?react';
import EmailIconNotStyled from './email.svg?react';
import QuestionMarkCircleIconNotStyled from './questionMarkCircle.svg?react';
import InfoIconNotStyled from './info.svg?react';
import MembersOutlineIconNotStyled from './members_outline.svg?react';
import PencilOutlineIconNotStyled from './pencil_outline.svg?react';
import BlankAvatarIconNotStyled from './blank_avatar.svg?react';
import KeyboardShortcutsIconNotStyled from './keyboard-shortcuts.svg?react';
import MagnifyingGlassIconNotStyled from './magnifying-glass.svg?react';
import FileOutlineIconNotStyled from './file_outline.svg?react';
import FileIcon from './file.svg?react';
import SettingsOutlineIconNotStyled from './settings_outline.svg?react';
import CloseIconNotStyled from './close.svg?react';
import HomeIconNotStyled from './home_icon.svg?react';
import Model3dIconNotStyled from './model_3d.svg?react';
import AddToWorkbenchIconNotStyled from './add_to_workbench.svg?react';
import DuplicateToWorkbenchIconNotStyled from './duplicate_to_workbench.svg?react';
import SparklesIconNotStyled from './sparkles.svg?react';
import SymmetryIconNotStyled from './symmetry.svg?react';
import DropdownIcon from './dropdown.svg?react';
import FolderIcon from './folder.svg?react';
import NewFolderIcon from './NewFolder.svg?react';
import CheckmarkIcon from './checkmark.svg?react';
import ColorPickerIcon from './color_picker.svg?react';
import ArrowIcon from './arrow.svg?react';
import BrushIcon from './brush.svg?react';
import BrushHardIcon from './brush_hard.svg?react';
import BrushSoftIcon from './brush_soft.svg?react';
import PaintBucketIcon from './paint_bucket.svg?react';
import DuplicateIcon from './duplicate.svg?react';
import EraserIcon from './eraser.svg?react';
import HistoryIcon from './history.svg?react';
import HelpIcon from './help.svg?react';
import LassoIcon from './lasso.svg?react';
import BezierIcon from './bezier.svg?react';
import BrushSelectionIcon from './brush_selection.svg?react';
import AutoSelectionIcon from './auto_selection.svg?react';
import LoaderIcon from './loader.svg?react';
import LockIcon from './lock.svg?react';
import PlusIcon from './plus.svg?react';
import MinusIcon from './minus.svg?react';
import RedoIcon from './redo.svg?react';
import RegenerateIcon from './regenerate.svg?react';
import RectangleIcon from './rectangle.svg?react';
import EllipseIcon from './ellipse.svg?react';
import LineIcon from './line.svg?react';
import TrashIcon from './trash.svg?react';
import TickIcon from './tick.svg?react';
import UndoIcon from './undo.svg?react';
import VisibleIcon from './visible.svg?react';
import InvisibleIcon from './invisible.svg?react';
import OpacityIcon from './opacity.svg?react';
import BlendIcon from './blend.svg?react';
import MoveToTopIcon from './move_to_top.svg?react';
import MoveToBottomIcon from './move_to_bottom.svg?react';
import RenameIcon from './rename.svg?react';
import ExportIcon from './export.svg?react';
import MergeIcon from './merge.svg?react';
import WorkbenchIcon from './workbench.svg?react';
import MenuEllipsesIcon from './menu_ellipses.svg?react';
import InvertIcon from './invert.svg?react';
import CheckLine from './check-line.svg?react';
import FullscreenIcon from './fullscreen.svg?react';
import FullscreenExitIcon from './fullscreen_exit.svg?react';
import MaterialTextureIconNotStyled from './material_texture.svg?react';
import MaterialMeshIconNotStyled from './material_mesh.svg?react';
import Layer3dLightIconNotStyled from './layer3d_light.svg?react'; // HERE
import Layer3dDropShadowIconNotStyled from './layer3d_dropshadow.svg?react';
import CopyContentIconNotStyled from './copy_content.svg?react';
import ArchiveIconNotStyled from './archive.svg?react';
import SectionIcon from './section.svg?react';
import ImportImageIcon from './import_image.svg?react';
import MatchCaseIcon from './match_case.svg?react';

import AmexIcon from './credit-cards/amex.svg?react';
import DinersIcon from './credit-cards/diners.svg?react';
import DiscoverIcon from './credit-cards/discover.svg?react';
import GenericIcon from './credit-cards/generic.svg?react';
import JCBIcon from './credit-cards/jcb.svg?react';
import MaestroIcon from './credit-cards/maestro.svg?react';
import MasterCardIcon from './credit-cards/mastercard.svg?react';
import PaypalIcon from './credit-cards/paypal.svg?react';
import UnionpayIcon from './credit-cards/unionpay.svg?react';
import VisaIcon from './credit-cards/visa.svg?react';

import ThumbnailIconRaw from './thumbnail.svg?raw';
import PlayCircleIconRaw from './play_circle.svg?raw';
import CheckCircleIconRaw from './check-circle.svg?raw';
import CloseIconOrangeRaw from './close-orange.svg?raw';
import RetryIconRaw from './retry.svg?raw';
import ErrorIconRaw from './error.svg?raw';

import LinkIcon from './link.svg?react';
import CorporateIcon from './corporate.svg?react';
import NoEntryIcon from './no_entry.svg?react';
import WorldOutlineIcon from './world_outline.svg?react';
import WarningIcon from './warning.svg?react';

import Import3DModelIcon from './import_3d_model.svg?react';
import Convert2dTo3dIcon from './convert_2d_to_3d.svg?react';
import SelectIcon from './select.svg?react';
import FitToScreenIcon from './fit_to_screen.svg?react';
import TextIcon from './text.svg?react';
import ZoomOutIcon from './zoom_out.svg?react';
import ZoomInIcon from './zoom_in.svg?react';
import SkewIcon from './skew.svg?react';
import EyeDropperIcon from './eye_dropper.svg?react';
import RemoveIcon from './remove.svg?react';
import BoldIcon from './bold.svg?react';
import UnderlineIcon from './underline.svg?react';
import StrikethroughIcon from './strikethrough.svg?react';
import LeftAlign from './left_align.svg?react';
import CenterAlign from './center_align.svg?react';
import RightAlign from './right_align.svg?react';
import MoreTools from './more_tools.svg?react';

export { Model3dIconUrl };

export const MenuHorizontalIcon = styled(MenuHorizontalIconNoStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export {
  ArrangeIcon,
  ImportImageIcon,
  MatchCaseIcon,
  SectionIcon,
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  GenericIcon,
  JCBIcon,
  MaestroIcon,
  MasterCardIcon,
  PaypalIcon,
  UnionpayIcon,
  VisaIcon,
  DropdownIcon,
  FolderIcon,
  NewFolderIcon,
  CheckmarkIcon,
  ColorPickerIcon,
  ArrowIcon,
  BrushIcon,
  BrushHardIcon,
  BrushSoftIcon,
  PaintBucketIcon,
  DuplicateIcon,
  EraserIcon,
  HistoryIcon,
  HelpIcon,
  LassoIcon,
  BezierIcon,
  BrushSelectionIcon,
  AutoSelectionIcon,
  LoaderIcon,
  LockIcon,
  PlusIcon,
  MinusIcon,
  RedoIcon,
  RegenerateIcon,
  RectangleIcon,
  EllipseIcon,
  LineIcon,
  TrashIcon,
  TickIcon,
  UndoIcon,
  VisibleIcon,
  InvisibleIcon,
  OpacityIcon,
  BlendIcon,
  MoveToTopIcon,
  MoveToBottomIcon,
  RenameIcon,
  ExportIcon,
  MergeIcon,
  WorkbenchIcon,
  MenuEllipsesIcon,
  InvertIcon,
  FullscreenIcon,
  FullscreenExitIcon,
  PlayCircleIconRaw,
  CheckCircleIconRaw,
  LinkIcon,
  CorporateIcon,
  NoEntryIcon,
  WorldOutlineIcon,
  CloseIconOrangeRaw,
  RetryIconRaw,
  ErrorIconRaw,
  WarningIcon,
  FileIcon,
  Import3DModelIcon,
  Convert2dTo3dIcon,
  SelectIcon,
  FitToScreenIcon,
  TextIcon,
  ZoomOutIcon,
  ZoomInIcon,
  SkewIcon,
  EyeDropperIcon,
  RemoveIcon,
  BoldIcon,
  UnderlineIcon,
  StrikethroughIcon,
  LeftAlign,
  CenterAlign,
  RightAlign,
  PromptToolbarIcon,
  SketchToolbarIcon,
  CubeToolbarIcon,
  MixToolbarIcon,
  PaletteToolbarIcon,
  ImageBlockToolbarIcon,
};

export const ArrowLeftIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
`;

export const ArrowRightIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

export const CloseIcon = styled(CloseIconNotStyled)`
  color: ${(p) => p.color || p.theme.icon.default};
`;

export const HomeIcon = styled(HomeIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export const DownloadIcon = styled(DownloadIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const UploadIcon = styled(UploadIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const EditIcon = styled(EditIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const AiSparklesIcon = styled(AiSparklesIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const SymmetryIcon = styled(SymmetryIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const ImageIcon = styled(ImageIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const AddIcon = styled(AddIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const SearchIcon = styled(SearchIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const CarretDownIcon = styled(CarretDownIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const CarretLeftIcon = styled(CarretDownIcon)`
  transform: rotate(90deg);
`;
export const CarretRightIcon = styled(CarretDownIcon)`
  transform: rotate(-90deg);
`;
export const CheckIcon = styled(CheckIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const EmailIcon = styled(EmailIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const QuestionMarkCircleIcon = styled(QuestionMarkCircleIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const InfoIcon = styled(InfoIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const MembersOutlineIcon = styled(MembersOutlineIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const PencilOutlineIcon = styled(PencilOutlineIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const BlankAvatarIcon = styled(BlankAvatarIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export const FileOutlineIcon = styled(FileOutlineIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
  width: 16px;
  height: 16px;
`;
export const SettingsOutlineIcon = styled(SettingsOutlineIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const KeyboardShortcutsIcon = styled(KeyboardShortcutsIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
  width: 24px;
  height: 24px;
`;
export const MagnifyingGlassIcon = styled(MagnifyingGlassIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const Model3dIcon = styled(Model3dIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export const AddToWorkbenchIcon = styled(AddToWorkbenchIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const SparklesIcon = styled(SparklesIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const CheckLineIcon = styled(CheckLine)`
  color: ${(p) => p.theme.icon.default};
`;
export const DuplicateToWorkbenchIcon = styled(
  DuplicateToWorkbenchIconNotStyled
)`
  color: ${(p) => p.theme.icon.default};
`;

export const MaterialTextureIcon = styled(MaterialTextureIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const MaterialMeshIcon = styled(MaterialMeshIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const Layer3dLightIcon = styled(Layer3dLightIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export const Layer3dDropShadowIcon = styled(Layer3dDropShadowIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export const CopyContentIcon = styled(CopyContentIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;
export const ArchiveIcon = styled(ArchiveIconNotStyled)`
  color: ${(p) => p.theme.icon.default};
`;

export { DropdownIconUrl };
export { ThumbnailIconRaw };
