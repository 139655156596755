import { useQuery } from 'urql';
import { graphql } from '../gql';
import { useHasAccessToken } from '../lib/auth';

const publishedChangelogsQuery = graphql(/* GraphQL */ `
  query publishedChangelogs($offset: Int, $count: Int) {
    changelogs(
      offset: $offset
      first: $count
      orderBy: PUBLISHED_AT_DESC
      filter: { publishedAt: { isNull: false } }
    ) {
      nodes {
        ...ChangelogData
      }
      totalCount
    }
  }
`);

const changelogsQuery = graphql(/* GraphQL */ `
  query changelogs($offset: Int, $count: Int) {
    changelogs(offset: $offset, first: $count, orderBy: PUBLISHED_AT_DESC) {
      nodes {
        ...ChangelogData
      }
      totalCount
    }
  }
`);

const unseenChangelogsQuery = graphql(/* GraphQL */ `
  query unseenChangelogs($count: Int) {
    unseenChangelogs(count: $count) {
      nodes {
        ...ChangelogData
      }
      totalCount
    }
  }
`);

export const usePublishedChangelogs = ({
  count,
  offset,
}: {
  count?: number;
  offset?: number;
}) => {
  const [res] = useQuery({
    query: publishedChangelogsQuery,
    variables: {
      count,
      offset,
    },
  });

  return {
    ...res,
    data: res.data?.changelogs?.nodes || [],
    totalCount: res.data?.changelogs?.totalCount || 0,
  };
};

export const useChangelogs = ({
  count,
  offset,
}: {
  count?: number;
  offset?: number;
}) => {
  const [res] = useQuery({
    query: changelogsQuery,
    variables: {
      count,
      offset,
    },
  });

  return {
    ...res,
    data: res.data?.changelogs?.nodes || [],
    totalCount: res.data?.changelogs?.totalCount || 0,
  };
};

export const useUnseenChangelogs = ({ count }: { count: number }) => {
  const hasAccessToken = useHasAccessToken();
  const [res] = useQuery({
    query: unseenChangelogsQuery,
    variables: { count },
    pause: !hasAccessToken,
  });

  return {
    ...res,
    data: res.data?.unseenChangelogs?.nodes || [],
    totalCount: res.data?.unseenChangelogs?.totalCount || 0,
  };
};
