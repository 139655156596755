import { graphql } from '../gql';
import { useMutation } from 'urql';

const CreateDrawingStyleReferenceMutation = graphql(/* GraphQL */ `
  mutation CreateStyleReference($input: CreateStyleReferenceInput!) {
    createStyleReference(input: $input) {
      workbench {
        ...WorkbenchStyleReferences
      }
      styleReference {
        id
      }
    }
  }
`);

export const useCreateDrawingStyleReference = () =>
  useMutation(CreateDrawingStyleReferenceMutation);
