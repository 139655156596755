import styled from 'styled-components';

export const Range = styled.input<{
  $variant: 'default' | 'secondary' | 'tertiary' | 'primary';
  $thick: boolean;
  $disabled?: boolean;
}>`
  display: block;
  width: 100%;
  height: 1.5rem;
  appearance: none;
  margin: 0;
  background: transparent;
  background-image: ${({ $variant, $disabled, theme }) => {
      let color;

      if ($disabled) {
        color = theme.surface.e2;
        return `linear-gradient(${color},${color})`;
      }

      switch ($variant) {
        default:
          color = theme.primary.default;
          break;
        case 'tertiary':
          color = theme.tertiary.default;
          break;
      }
      return `linear-gradient(${color},${color})`;
    }},
    ${({ $variant, theme }) => {
      let color;
      switch ($variant) {
        default:
          color = theme.surface.e1;
          break;
        case 'secondary':
          color = theme.surface.e2;
          break;
      }
      return `linear-gradient(${color},${color})`;
    }};
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 50%, 0% 50%;
  background-size: ${({ $thick }) =>
    $thick ? 'var(--track) 32px, 100% 32px;' : 'var(--track) 3px, 100% 3px'};
  border-radius: ${({ $thick }) => ($thick ? '16px' : '0')};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  // For touch devices
  @media (hover: none) {
    background-size: var(--track) 5px, 100% 5px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: ${({ $variant, $disabled, theme }) => {
      if ($disabled) {
        return theme.text.disabled;
      }

      switch ($variant) {
        default:
          return theme.primary.default;
        case 'tertiary':
          return theme.tertiary.default;
        case 'primary':
          return theme.white;
      }
    }};
    border: ${({ $variant, $thick, theme }) => {
      if ($thick) {
        return `none`;
      }
      switch ($variant) {
        default:
          return `1px solid ${theme.surface.e0}`;
        case 'secondary':
          return `1px solid ${theme.surface.e1}`;
      }
    }};
    box-shadow: ${({ $thick }) =>
      $thick ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25);' : 'none'};

    height: ${({ $thick }) => ($thick ? '24px' : '1rem')};
    width: ${({ $thick }) => ($thick ? '24px' : '1rem')};
    border-radius: ${({ $thick }) => ($thick ? '16px' : '8px')};

    position: relative;
    z-index: 2;

    // For touch devices
    @media (hover: none) {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 1rem;
    }
  }

  &::-webkit-slider-runnable-track {
    appearance: none;
    background: transparent;
    border: none;
    box-shadow: none;
  }
`;
