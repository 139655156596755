import { ResultOf } from '@graphql-typed-document-node/core';
import { graphql } from '../gql';

export const CompositeSceneDataFragment = graphql(/* GraphQL */ `
  fragment CompositeSceneData on CompositeScene {
    __typename
    id
    height
    width
    x
    y
    zIndex
    thumbnailPath
    sceneBackgroundColorHex
  }
`);

export type CompositeSceneData = ResultOf<typeof CompositeSceneDataFragment>;

export const CompositeSceneFullDataFragment = graphql(/* GraphQL */ `
  fragment CompositeSceneFullData on CompositeScene {
    ...CompositeSceneData
    workbenchId
    cameraPositionX
    cameraPositionY
    cameraPositionZ
    cameraTargetX
    cameraTargetY
    cameraTargetZ
    cameraFov
    sceneEnvironmentMapUrl
    sceneEnvironmentAngle
    sceneEnvironmentGroundPlane
    prompt
    influence
    renderStyle
    compositeSceneElements {
      nodes {
        id
        name
        basicShape
        modelPath
        meshes
      }
    }
  }
`);

export type CompositeSceneFullData = ResultOf<
  typeof CompositeSceneFullDataFragment
>;
