import styled from 'styled-components';
import { useStringHashNumber } from './hooks/useStringHashNumber';

interface TeamAvatarProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  name: string;
  size: 'small' | 'medium' | 'large';
  lettersToDisplay?: number;
  teamId?: string;
  color?: string;
}

export const teamAvatarColors = [
  '#FFFFFF',
  '#FFC46A',
  '#8282F4',
  '#C2F482',
  '#7CE7FE',
  '#FE7C9B',
];

const TeamAvatarContainer = styled.div<{
  $color: string;
  $size: 'small' | 'medium' | 'large';
}>`
  height: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  width: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.surface.e0};
  background-color: ${(p) => p.$color};
  text-transform: uppercase;
  border-radius: ${(p) =>
    p.$size === 'large' ? 5 : p.$size === 'medium' ? 6 : 2}px;
  font-size: ${(p) =>
    p.$size === 'large' ? 20 : p.$size === 'medium' ? 15 : 9}px;
  flex-shrink: 0;
`;

export const TeamAvatar = (props: TeamAvatarProps) => {
  const { teamId, size, name, lettersToDisplay = 1, color, ...rest } = props;
  const hash = useStringHashNumber(teamId || '');
  const defaultColor = color || '#FFFFFF';
  const computedColor =
    hash && !color
      ? teamAvatarColors[hash % teamAvatarColors.length]
      : defaultColor;

  return (
    <TeamAvatarContainer $color={computedColor} $size={size} {...rest}>
      {name.slice(0, lettersToDisplay)}
    </TeamAvatarContainer>
  );
};
