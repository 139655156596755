import { graphql } from '../gql';
import { useSubscription } from 'urql';

const workbenchUpdates = graphql(/* GraphQL */ `
  subscription workbenchUpdate($input: WorkbenchUpdatesInput!) {
    workbenchUpdates(input: $input) {
      type
      id
      table
      img2img {
        ...WorkbenchElementImg2ImgData
      }
      palette {
        ...WorkbenchElementPaletteData
      }
      placeholder {
        ...WorkbenchElementPlaceholderData
      }
      drawing {
        ...WorkbenchElementDrawingData
      }
      text {
        ...WorkbenchElementTextData
      }
      compositeScene {
        ...CompositeSceneData
      }
      mix {
        ...WorkbenchElementMixData
      }
      section {
        ...WorkbenchElementSectionData
      }
      paletteSourceImage {
        ...WorkbenchElementPaletteSourceImageData
      }
    }
  }
`);

export const useWorkbenchUpdates = (workbenchId: string) => {
  const [res] = useSubscription({
    query: workbenchUpdates,
    variables: {
      input: {
        workbenchId,
      },
    },
  });
  return res;
};
