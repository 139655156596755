import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const WorkbenchEventTypeZodEnum = z.enum([
  'CREATE_ELEMENTS',
  'CREATE_MULTI_VIEW',
  'DELETE_ELEMENTS',
  'DUPLICATE_ELEMENTS',
  'MIX_IMAGES',
  'UPDATE_ELEMENTS',
  'WORKBENCH_DELETED',
  'WORKBENCH_DUPLICATED',
  'WORKBENCH_MOVED',
  'ZIP_EXPORT',
]);

export const WorkbenchEventName = WorkbenchEventTypeZodEnum.Enum;

export const WorkbenchTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: WorkbenchEventTypeZodEnum,
  data: z.object({
    workbenchIds: z.array(z.string()),
    elementIds: z.array(z.string()).optional(),
  }),
});
