import { useMutation } from 'urql';
import { graphql } from '../../gql';

const RequestDeleteOrganizationConfirmationTokenMutation =
  graphql(/* GraphQL */ `
    mutation RequestDeleteOrganizationConfirmationToken(
      $input: RequestDeleteOrganizationConfirmationTokenInput!
    ) {
      requestDeleteOrganizationConfirmationToken(input: $input) {
        status
      }
    }
  `);

export const useRequestDeleteOrganizationConfirmationToken = () =>
  useMutation(RequestDeleteOrganizationConfirmationTokenMutation);

const DeleteOrganizationMutation = graphql(/* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      status
    }
  }
`);

export const useDeleteOrganization = () =>
  useMutation(DeleteOrganizationMutation);
