import { z } from 'zod';

export const compositeSceneClientAuth = z.object({
  type: z.literal('auth'),
  accessToken: z.string(),
  compositeSceneId: z.string(),
});

export const compositeScenePreviewRequest = z.object({
  type: z.literal('previewRequest'),
  inputImage: z.instanceof(Uint8Array),
  prompt: z.string(),
  weight: z.number(),
  // only for Vizcom admin, overwrite the workflow from ComfyUI, used to test different settings live
  workflow: z.nullable(z.record(z.string(), z.any())),
});

export const compositeSceneSegmentationRequest = z.object({
  type: z.literal('segmentationRequest'),
  inputImage: z.instanceof(Uint8Array),
});

export const compositeSceneClientMessage = z.discriminatedUnion('type', [
  compositeSceneClientAuth,
  compositeScenePreviewRequest,
  compositeSceneSegmentationRequest,
]);

export const compositeSceneServerAuthResponse = z.object({
  type: z.literal('authResponse'),
  id: z.string(), // id of current session
});

export const compositeScenePreviewResponse = z.object({
  type: z.literal('previewResponse'),
  image: z.instanceof(Uint8Array),
});

export const compositeSceneSegmentationResponse = z.object({
  type: z.literal('segmentationResponse'),
  image: z.instanceof(Uint8Array),
});

export const compositeSceneServerMessage = z.discriminatedUnion('type', [
  compositeSceneServerAuthResponse,
  compositeScenePreviewResponse,
  compositeSceneSegmentationResponse,
]);
