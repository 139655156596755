import { boundNumber } from '@vizcom/shared/js-utils';
import { Range } from './RangedInput.styled';
import { ChangeEvent, useMemo, MouseEventHandler, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type RangeInputPresenterProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onMouseUp?: MouseEventHandler<HTMLInputElement>;
  onMouseLeave?: MouseEventHandler<HTMLInputElement>;
  onMouseDown?: MouseEventHandler<HTMLInputElement>;
  onPointerMove?: MouseEventHandler<HTMLInputElement>;
  value: number;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  variant?: 'default' | 'secondary' | 'tertiary' | 'primary';
  thick?: boolean;
  snapCenter?: boolean;
};

const RangeInputPresenter = (props: RangeInputPresenterProps) => {
  const {
    onChange,
    onMouseUp,
    onMouseLeave,
    onMouseDown,
    onPointerMove,
    value,
    className,
    min = 0,
    max = 100,
    step = 1,
    disabled = false,
  } = props;
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);

  const style = useMemo(() => {
    let progress = (boundNumber(min, value, max) / (max - min)) * 100;

    // offset the progress bar to prevent it overtaking the thumb when
    // the range slider is thick
    if (props.thick) {
      progress =
        progress < max / 4
          ? progress + 2
          : progress > max * 0.75
          ? progress - 2
          : progress;
    }

    return {
      '--track': `${progress}%`,
    };
  }, [min, max, value]);
  const id = useMemo(() => uuidv4(), []);

  return (
    <div style={{ position: 'relative' }}>
      <Range
        ref={ref}
        $variant={props.variant || 'default'}
        $thick={!!props.thick}
        $disabled={disabled}
        type="range"
        list={id}
        className={className}
        value={value}
        min={min}
        max={max}
        step={step}
        onInput={onChange}
        onChange={onChange}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onPointerMove={onPointerMove}
        disabled={disabled}
        style={style as React.CSSProperties}
      />
      {props.snapCenter && !disabled && (
        <>
          <datalist id={id}>
            <option value={String(max / 2)} />
          </datalist>
          <Dot
            style={{
              backgroundColor:
                value >= max / 2
                  ? theme.text.primaryAccent
                  : theme.secondary.disabled,
            }}
          />
        </>
      )}
    </div>
  );
};

const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 6px;
  left: calc(50% - 6px);
  z-index: 1;
`;

export default RangeInputPresenter;
