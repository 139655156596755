import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

const sentryDsn = import.meta.env.NX_SENTRY_DSN;
const sentryTargetProduction = 'https://app.vizcom.ai/';
const sentryTargetStaging = 'https://beta.vizcom.ai/';

const ignorableErrors = [
  // Brush studio triggers this error randomly, this doesn't break anything but spam Sentry
  // We manually ignore it here for now. Sentry manually
  /update depth exceeded/i,
  /Minified React error #185/i,
  // This error is thrown by some old browsers, we already show an error message indicating to the user they should upgrade their browser
  /findLastIndex/i,
];

export const initSentry = () => {
  const enabled = !window.location.host.match(/localhost/);
  if (!enabled) {
    console.log(`Disabling Sentry because detected dev mode`);
  }
  if (!sentryDsn) {
    console.warn(`Sentry should be enabled but not Sentry DSN was provided`);
  }

  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        tracePropagationTargets: [
          'localhost',
          new RegExp(`^${sentryTargetProduction}`),
          new RegExp(`^${sentryTargetStaging}`),
        ],
      }),
      new Sentry.Replay(),
    ],
    beforeSend(event, hint) {
      const errorMsg = hint.originalException?.toString();
      if (errorMsg && ignorableErrors.some((regex) => regex.test(errorMsg))) {
        return null;
      }
      return event;
    },
    enabled,
    environment: window.location.hostname.split('.')[0],
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const useSyncUserInSentryContext = () => {
  const user = useCurrentUser();
  useEffect(() => {
    if (!user.data?.id) {
      return;
    }
    Sentry.setUser({
      id: user.data.id,
      email: user.data.email,
    });
  }, [user.data?.id, user.data?.email]);
};
