import styled from 'styled-components';

export const ProgressBarContainer = styled.progress`
  width: 100%;
  height: 1rem;
  background-color: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: ${(props) => props.color};
  }

  &::-moz-progress-bar {
    background-color: ${(props) => props.color};
  }

  &::-ms-fill {
    background-color: ${(props) => props.color};
  }
`;
