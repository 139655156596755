import { createGlobalStyle } from 'styled-components';
import { OperatingSystem, getOS } from './userAgent';

export const CSSReset = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    // This makes 1rem === 16px
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    font-smoothing: antialiased;
    overflow: hidden;
    tap-highlight-color: transparent;
    text-size-adjust: none;
    // Removes double taps on touch screens to make it snappier
    touch-action: pan-x pan-y;

    touch-callout: none;
    overscroll-behavior: none;
  }

  :root, body, #root {
    width: 100vw;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: inherit;
  }

  ol, ul {
    list-style: none;
  }

  a {
    color: inherit;
    outline: none;
    text-decoration: none;
  }

  button, input, textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    outline: none;
  }

  canvas {
    vertical-align: middle;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  ${
    getOS() === OperatingSystem.Windows
      ? `
  ::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar:horizontal {
    height: 0.5rem;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.5rem;
  }
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }`
      : ''
  }
`;
