import styled from 'styled-components';
import { forwardRef, useRef, useState } from 'react';
import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
  flip,
} from '@floating-ui/react';
import { Badge } from '../Badge';
import { TeamAvatar } from '../TeamAvatar';

/*
 * Option Component
 */
interface OptionComponentProps {
  active: boolean;
  label: string;
  value: string;
}

const OptionComponent = forwardRef<
  HTMLDivElement,
  OptionComponentProps & React.HTMLProps<HTMLDivElement>
>(({ active, label, value, ...rest }, ref) => {
  return (
    <div ref={ref} role="option" aria-selected={active} {...rest}>
      <SelectionLine $active={active}>
        <Badge avatar size="small" title={label} id={value} />
        {active && 'Select'}
      </SelectionLine>
    </div>
  );
});

type ComboBoxOptionData = {
  label: string;
  value: string;
};

type ComboBoxInputProps = {
  data: ComboBoxOptionData[];
  selection: string[];
  placeholder: string;
  description?: string;
  expandable?: boolean;
  widthExpanded?: number;
  compact?: boolean;
  onSelectElement: (value: string) => void;
  onRemoveElement?: (value: string) => void;
};

export const ComboBoxInput = ({
  data,
  selection,
  onSelectElement,
  onRemoveElement,
  placeholder,
  description,
  expandable = false,
  widthExpanded = 300,
  compact = false,
}: ComboBoxInputProps) => {
  const [open, setOpen] = useState(!expandable);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { refs, floatingStyles, context, placement } =
    useFloating<HTMLInputElement>({
      open,
      whileElementsMounted: autoUpdate,
      onOpenChange: setOpen,
      placement: 'bottom-start',
      middleware: [
        flip({ padding: 5 }),

        /* Ensure the floating element is always the same width as the reference element */
        size({
          apply({ rects, availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
              maxHeight: `${availableHeight}px`,
            });
          },
          padding: 5,
        }),
      ],
    });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNavigation]
  );

  const availableItemsForSearch = data.filter(
    (option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
      !selection.includes(option.value)
  );

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;

    setInputValue(inputValue);

    if (inputValue) {
      setActiveIndex(0);
    }
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Enter' &&
      activeIndex !== null &&
      availableItemsForSearch[activeIndex]
    ) {
      setInputValue('');
      onSelectElement(availableItemsForSearch[activeIndex].value);
      setActiveIndex(null);
    }
  };

  const onClickItem = (itemId: string) => {
    setActiveIndex(null);
    setInputValue('');
    onSelectElement(itemId);
  };

  const maxItemsToDisplay = 2;
  const selectedItems = data.filter((item) => selection.includes(item.value));
  const displaySelectedItems =
    !open && compact
      ? selectedItems.slice(0, maxItemsToDisplay)
      : selectedItems;

  return (
    <>
      <ComboBoxContainer
        ref={refs.setReference}
        $open={open}
        $expandable={expandable}
        $widthExpanded={widthExpanded}
        $flipped={placement === 'top-start'}
        onClick={() => {
          setOpen(true);
        }}
      >
        {displaySelectedItems.map((item) => (
          <Badge
            avatar={true}
            key={item.value}
            size="small"
            title={item.label}
            id={item.value}
            compact={compact && !open}
            onClose={
              open && onRemoveElement
                ? () => onRemoveElement(item.value)
                : undefined
            }
          />
        ))}

        {!open &&
          compact &&
          selectedItems.length > displaySelectedItems.length && (
            <TeamAvatar
              color="#FFFFFF"
              lettersToDisplay={4}
              name={`+${selectedItems.length - displaySelectedItems.length}`}
              size="small"
            />
          )}

        {!open && !selectedItems.length && (
          <span
            style={{
              fontSize: '10px',
            }}
          >
            {placeholder}
          </span>
        )}

        {open && (
          <StyledInput
            placeholder={placeholder}
            value={inputValue}
            aria-autocomplete="list"
            {...getReferenceProps({
              onChange: onInputChange,
              onKeyDown: onInputKeyDown,
            })}
          />
        )}
      </ComboBoxContainer>

      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <FloatingWindowContainer
              $flipped={placement === 'top-start'}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: floatingStyles,
              })}
            >
              {!availableItemsForSearch.length && (
                <SelectionLine $active={false}>No elements found</SelectionLine>
              )}

              {availableItemsForSearch.length > 0 && description && (
                <span
                  style={{
                    fontSize: '10px',
                    marginBottom: '6px',
                  }}
                >
                  {description}
                </span>
              )}

              {availableItemsForSearch.map((item, index) => (
                <OptionComponent
                  {...getItemProps({
                    key: item.value,
                    ref: (node) => {
                      listRef.current[index] = node;
                    },
                    onClick: () => onClickItem(item.value),
                  })}
                  active={activeIndex === index}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </FloatingWindowContainer>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

const FloatingWindowContainer = styled.div<{
  $flipped: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: ${(p) => (p.$flipped ? '8px 8px 0 0' : '0 0 8px 8px')};
  border-top: ${(p) => (p.$flipped ? '0px' : '1px')} solid;
  border-bottom: ${(p) => (p.$flipped ? '1px' : '0px')} solid;
  border-color: ${(p) => p.theme.surface['e2']};
  background-color: ${(p) => p.theme.surface['e1']};
  box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  color: ${(p) => p.theme.text.info};
`;

const ComboBoxContainer = styled.div<{
  $open: boolean;
  $widthExpanded: number;
  $expandable: boolean;
  $flipped: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  padding: 8px;
  width: ${(p) =>
    p.$open && p.$expandable ? `${p.$widthExpanded}px` : '100%'};
  border-radius: ${(p) => (p.$open && p.$flipped ? '0 0' : '8px 8px')}
    ${(p) => (p.$open && !p.$flipped ? '0 0' : '8px 8px')};
  background-color: ${(p) => p.theme.surface['e1']};
  z-index: ${(p) => (p.$open ? '100' : 'auto')};

  :hover {
    cursor: pointer;
    background-color: ${(p) => (p.$open ? '' : p.theme.surface['e2'])};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 4px;
  border-width: 0;
  background-color: transparent;
  color: ${(p) => p.theme.text.default};
  font-size: 12px;
  outline: none;

  ::placeholder {
    color: ${(p) => p.theme.text.info};
  }
`;

const SelectionLine = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 8px;
  cursor: pointer;
  font-size: 12px;
  background-color: ${({ $active, theme }) => $active && theme.surface['e2']};
  border-radius: ${({ $active }) => $active && '4px'};
`;
