import { graphql } from '../gql';
import { ResultOf } from '@graphql-typed-document-node/core';

export const TeamBasicDataFragment = graphql(/* GraphQL */ `
  fragment TeamBasicData on Team {
    id
    name
    description
    updatedAt
    createdAt
    currentUserRole
    archived
    rootFolder {
      id
    }
  }
`);

export type TeamBasic = ResultOf<typeof TeamBasicDataFragment>;

export const TeamDataFragment = graphql(/* GraphQL */ `
  fragment TeamData on Team {
    ...TeamBasicData
    publicInOrganization
    globalInOrganization
    creator {
      id
      name
      email
    }
    organization {
      ...OrganizationData
    }
    rootFolder {
      ...FolderData
    }
  }
`);

export type Team = ResultOf<typeof TeamDataFragment>;

export const TeamMembersFragment = graphql(/* GraphQL */ `
  fragment TeamMembers on Team {
    id
    name
    usersOnTeamsByTeamId {
      totalCount
      nodes {
        userRoleInOrganization
        user {
          id
          email
          name
        }
      }
    }
    invites {
      totalCount
      nodes {
        createdAt
        email
      }
    }
    members {
      totalCount
      nodes {
        email
        createdAt
      }
    }
  }
`);
export type TeamMembers = ResultOf<typeof TeamMembersFragment>;
