import { NavLink, To } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeContainerMixin = css<{ tertiary?: boolean }>`
  background-color: ${(p) => (p.tertiary ? 'transparent' : p.theme.surface.e1)};
  color: ${(p) => (p.tertiary ? p.theme.text.info : p.theme.text.default)};

  svg {
    opacity: 1;
  }
`;

const Container = styled(NavLink)<{
  tertiary?: boolean;
  $forceActive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  color: ${(p) => p.theme.text.info};
  padding: 13px 16px;
  cursor: pointer;
  margin-bottom: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;

  :hover {
    background-color: ${(p) =>
      p.tertiary ? 'transparent' : p.theme.surface.e1};
    color: ${(p) => p.theme.text.info};
  }

  svg {
    opacity: 0.5;
  }

  ${(p) => p.$forceActive && activeContainerMixin}

  :active,
  &[aria-current] {
    ${activeContainerMixin}
  }

  ${(props) =>
    props.tertiary &&
    css`
      background-color: transparent;
    `}
`;

interface SidebarItemProps {
  children: React.ReactNode;
  to?: To;
  onClick?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
  tertiary?: boolean;
  forceActive?: boolean;
  disabled?: boolean;
}

export const SidebarItem = (props: SidebarItemProps) => {
  if (props.to) {
    return (
      <Container
        $forceActive={props.forceActive}
        style={props.style}
        onClick={props.onClick}
        to={props.to}
        end
        tertiary={props.tertiary}
      >
        {props.children}
      </Container>
    );
  }

  return (
    <Container
      as="div"
      style={props.style}
      onClick={props.onClick}
      tertiary={props.tertiary}
    >
      {props.children}
    </Container>
  );
};
