import { useMutation } from 'urql';
import { graphql } from '../gql';

const UpdateFolderMutation = graphql(/* GraphQL */ `
  mutation UpdateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folder {
        ...FolderData
      }
      parent {
        ...FolderData
      }
      previousParent {
        ...FolderData
      }
    }
  }
`);

export const useUpdateFolder = () => useMutation(UpdateFolderMutation);
