import styled from 'styled-components';
import { createContext, Children, ReactNode, useContext } from 'react';
import { Modal } from '../Modal';

const MultiStepsModalContent = styled.div`
  color: ${(p) => p.theme.text.default};
`;

interface MultiStepsModalContextValue {
  currentStepIndex: number;
  changeStep: (index: number) => void;
}

const MultiStepsModalContext =
  createContext<MultiStepsModalContextValue | null>(null);

export const MultiStepsModal = (props: {
  isOpen: boolean;
  children: ReactNode;
  setIsOpen: (value: boolean) => void;
  currentStepIndex: number;
  setCurrentStepIndex: (index: number) => void;
}) => {
  const { currentStepIndex, setCurrentStepIndex } = props;

  const steps = Children.toArray(props.children);
  const countSteps = steps.length - 1;
  const currentStep = steps[currentStepIndex];

  const changeStep = (index: number) => {
    if (index >= 0 && index <= countSteps) {
      setCurrentStepIndex(index);
    }
  };

  return (
    <Modal
      style={{
        maxWidth: '800px',
        padding: '0',
        overflow: 'hidden',
        border: 'none',
      }}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    >
      <MultiStepsModalContext.Provider value={{ currentStepIndex, changeStep }}>
        <MultiStepsModalContent>{currentStep}</MultiStepsModalContent>
      </MultiStepsModalContext.Provider>
    </Modal>
  );
};

export const useMultiStepsModalContext = () => {
  const context = useContext(MultiStepsModalContext);

  if (!context) {
    throw new Error(
      'MultiStepsModal components must be wrapped in <MultiStepsModal />'
    );
  }

  return context;
};
