import IconGeneral from './icon-general.png';
import IconVolume from './icon-volume.png';
import IconPastel from './icon-pastel.png';
import IconCyberCel from './icon-cybercel.png';
import IconTechnicolor from './icon-technicolor.png';
import IconCarInterior from './icon-carInterior.png';
import IconCarExterior from './icon-carExterior.png';
import IconSurfaceSculpt from './icon-surfaceSculpt.png';
import PublicPaletteThumbnail from './palette.png';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';

export { PublicPaletteThumbnail };

export const PALETTE_DISPLAY_ORDER = [
  'Essentials',
  'Stylized',
  'Automotive',
  'Local palettes',
];

export enum PaletteCategory {
  Essentials = 'Essentials',
  Stylized = 'Stylized',
  Automotive = 'Automotive',
  Local = 'Local palettes',
}

export const PUBLIC_PALETTES_DETAILS = [
  {
    id: 'general',
    name: 'Vizcom General',
    desc: 'Stunning photorealism that puts the focus on your product.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.general,
    thumbnailPath: IconGeneral,
    versions: [{ value: PublicPalette.generalV2, version: 2 }],
  },
  {
    id: 'sculpt',
    name: 'Surface Sculpt',
    desc: 'Three-dimensional look with a emphasis on depth and form.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.surfaceSculpt,
    thumbnailPath: IconSurfaceSculpt,
  },
  {
    id: 'volume',
    name: 'Volume Render',
    desc: 'Three-dimensional look with a emphasis on depth and form.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.volume,
    thumbnailPath: IconVolume,
  },
  {
    id: 'interior',
    name: 'Interior',
    desc: 'Detailed renderings emphasizing texture, lighting, and space in automotive interiors',
    category: PaletteCategory.Automotive,
    value: PublicPalette.carInterior,
    thumbnailPath: IconCarInterior,
  },
  {
    id: 'exterior',
    name: 'Exterior',
    desc: 'Vivid car design renders with a emphasis on shadows, and reflections.',
    category: PaletteCategory.Automotive,
    value: PublicPalette.carExterior,
    thumbnailPath: IconCarExterior,
    versions: [{ value: PublicPalette.carExterior_v2, version: 2 }],
  },
  {
    id: 'cybercel',
    name: 'Cyber Cel',
    desc: 'Vibrant flat colors with strong outlines and high-contrast',
    category: PaletteCategory.Stylized,
    value: PublicPalette.cybercel,
    thumbnailPath: IconCyberCel,
  },
  {
    id: 'technicolor',
    name: 'Technicolor 6',
    desc: 'A futuristic style with warm inviting colors',
    category: PaletteCategory.Stylized,
    value: PublicPalette.technicolor,
    thumbnailPath: IconTechnicolor,
    versions: [{ value: PublicPalette.technicolor_v2, version: 2 }],
  },
  {
    id: 'pastel',
    name: 'Pastel Render',
    desc: 'Gentle and soothing color palette, inspired by the soft hues of pastel colors.',
    category: PaletteCategory.Stylized,
    value: PublicPalette.pastel,
    thumbnailPath: IconPastel,
  },
];
