import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const PaywallEventTypeZodEnum = z.enum(['PAYWALL_DISPLAYED']);

export const PaywallEventName = PaywallEventTypeZodEnum.Enum;

export const PaywallTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: PaywallEventTypeZodEnum,
  data: z.object({
    location: z.enum(['prompt']),
    action: z.enum(['prompt-count']),
  }),
});
