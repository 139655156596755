import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import {
  TRANSITION_DURATION_MS,
  fadeBackground,
  fadeBackgroundReverse,
} from '../ContextMenu/styles';
import { LoadingLogo } from '../Logo/LoadingLogo';
import { styledScrollbar } from '../Layout';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (val: boolean) => any;
  style?: CSSProperties;
  loading?: boolean;
}

const Backdrop = styled.div<{ $closing?: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  animation: ${TRANSITION_DURATION_MS}ms ease
    ${(p) => (p.$closing ? fadeBackgroundReverse : fadeBackground)};
  animation-fill-mode: both;
  ${(p) => p.$closing && 'pointer-events: none;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// This container is required to make sure modals are verticaly centered when smaller than the screen
// but can still be scrolled when bigger than the screen
const ModalContainer = styled.div`
  padding: 4em 1em;
  overflow-y: auto;
  ${styledScrollbar}
`;

const modalEnter = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95) translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`;
const modalExit = keyframes`
  from {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: scale(0.95) translate3d(0, -10px, 0);
  }
`;

const Container = styled.div<{ $closing?: boolean; $loading?: boolean }>`
  border-radius: 9px;
  background-color: ${(p) => p.theme.surface.e1};
  padding: 26px 24px;
  animation: ${TRANSITION_DURATION_MS}ms ease
    ${(p) => (p.$closing ? modalExit : modalEnter)};
  animation-fill-mode: both;
  max-width: 600px;
  min-width: 350px;
  ${(p) => (p.$loading ? 'filter: blur(2px);' : '')}
`;

export const Modal = (props: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const previousStateRef = useRef<boolean>(false);

  const isOpen = props.isOpen || isClosing;

  // detect when closing the modal and set isClosing to true during the animation
  // to keep it in the DOM and prevent instant closing
  useEffect(() => {
    if (props.isOpen !== previousStateRef.current) {
      previousStateRef.current = props.isOpen;
      if (!props.isOpen) {
        setIsClosing(true);
        setTimeout(() => {
          setIsClosing(false);
        }, TRANSITION_DURATION_MS);
      }
    }
  }, [props.isOpen]);

  // handle esc to close modal
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handler);
    }
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [isOpen]);

  const handleContainerClick: MouseEventHandler = (e) => {
    if (e.target instanceof Element && e.target.closest('[data-close-modal]')) {
      props.setIsOpen(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleBackdropClick: MouseEventHandler = (e) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as any)
    ) {
      props.setIsOpen(false);
    }
  };

  return createPortal(
    <Backdrop onClick={handleBackdropClick} $closing={isClosing}>
      {props.loading && <LoadingLogo />}
      <ModalContainer>
        <Container
          onClick={handleContainerClick}
          ref={containerRef as any}
          $closing={isClosing}
          $loading={props.loading}
          style={props.style}
        >
          {props.children}
        </Container>
      </ModalContainer>
    </Backdrop>,
    document.body
  );
};
