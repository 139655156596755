import styled from 'styled-components';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { ColorPickerIcon } from '../icons';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  onTriggerEyedropper?: () => void;
}

export const ColorPicker = ({
  color,
  onChange,
  onTriggerEyedropper,
}: ColorPickerProps) => {
  const handleHexColorChange = (newColor: string) => {
    onChange(newColor);
  };

  return (
    <Container>
      <StyledHexColorPicker
        color={color as string}
        onChange={handleHexColorChange}
      />
      <InputRow>
        <InputContainer>
          <ToggleButton>HEX</ToggleButton>
          <StyledHexColorInput
            color={color as string}
            onChange={handleHexColorChange}
          />
        </InputContainer>
        {onTriggerEyedropper && (
          <ColorPickerButton onClick={onTriggerEyedropper}>
            <StyledColorPickerIcon />
          </ColorPickerButton>
        )}
      </InputRow>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.surface.e0};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  height: max-content;
  box-shadow: 2px 2px 12px #67b7d178;
  padding: 0.75rem;
  justify-content: center;
  border-radius: 8px;
`;

const StyledHexColorPicker = styled(HexColorPicker)`
  width: 255px;
  height: 255px;
  box-shadow: 1px 1px solid black;
  border-radius: 2px;
  border: none;
  cursor: pointer;

  .react-colorful__saturation-pointer,
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    cursor: pointer;
  }

  .react-colorful__hue,
  .react-colorful__alpha {
    margin-top: 0.5rem;
    border-radius: 15px;
    height: 12px;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ToggleButton = styled.button`
  padding: 5px 10px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  font-size: 12px;
  border: none;
  border-right: 1px solid ${({ theme }) => theme.surface.e2};
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ColorPickerButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 12px;
`;

const StyledColorPickerIcon = styled(ColorPickerIcon)`
  background-color: ${({ theme }) => theme.surface.e1};
  border-radius: 4px;
  margin-left: 10px;
  padding: 5px;
  cursor: pointer;
`;

const StyledHexColorInput = styled(HexColorInput)`
  display: flex;
  width: 103px;
  height: 24px;
  padding-right: 0px;
  align-items: center;

  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.surface.e1};
  border-radius: 0px 4px 4px 0px;
  background-color: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  padding: 0 10px;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
