import { graphql } from '../../gql';

export const CreateWorkbenchMeshFromDrawingMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchMeshFromDrawing(
    $input: CreateWorkbenchMeshFromDrawingInput!
  ) {
    createWorkbenchMeshFromDrawing(input: $input) {
      placeholder {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);

export const ConvertPlaceholderToCompositeSceneMutation =
  graphql(/* GraphQL */ `
    mutation ConvertPlaceholderToCompositeScene(
      $input: ConvertPlaceholderToCompositeSceneInput!
    ) {
      convertPlaceholderToCompositeScene(input: $input) {
        compositeScene {
          ...CompositeSceneData
        }
      }
    }
  `);
