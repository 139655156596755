import { useMutation } from 'urql';
import { graphql } from '../../gql';

const RequestDeleteCurrentUserEmailMutation = graphql(/* GraphQL */ `
  mutation RequestDeleteCurrentUserEmail {
    requestDeleteCurrentUserEmail {
      status
    }
  }
`);

export const useRequestDeleteCurrentUserEmail = () =>
  useMutation(RequestDeleteCurrentUserEmailMutation);

const DeleteCurrentUserMutation = graphql(/* GraphQL */ `
  mutation DeleteCurrentUser($input: DeleteCurrentUserInput!) {
    deleteCurrentUser(input: $input) {
      status
    }
  }
`);

export const useDeleteCurrentUser = () =>
  useMutation(DeleteCurrentUserMutation);
