import styled from 'styled-components';
import MarkdownPreview from 'markdown-to-jsx';
import { Button } from '../Button';

type MarkdownProps = {
  children: string | null | undefined;
};

const MarkdownStyling = styled.div`
  display: inline-block;
  width: 100%;

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.1;
  }

  h3 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  span,
  p {
    margin: 0 0 16px 0;
  }

  h2 {
    padding-bottom: 8px;
    border-bottom: solid 1px ${(p) => p.theme.white}11;
  }

  ul {
    list-style-type: disc;
    padding: 0 16px;
  }

  code {
    display: inline-block;
    font-family: inherit;
    border: 0;
    border-radius: ${(p) => p.theme.borderRadius.subtle};
    background-color: ${(p) => p.theme.black}44;
    padding: 4px 6px;
    margin: 2px;
  }

  hr {
    border: solid 1px ${(p) => p.theme.white}11;
  }
`;

export const Markdown = ({ children }: MarkdownProps) => {
  return (
    <MarkdownStyling>
      <MarkdownPreview
        options={{
          overrides: {
            ButtonLink: {
              component: Button,
              props: {
                as: 'a',
                target: '_blank',
                style: {
                  display: 'inline-block',
                },
              },
            },
            video: {
              props: {
                autoplay: 'true',
                loop: 'true',
                muted: 'true',
                playsinline: 'true',
                style: {
                  width: '100%',
                },
              },
            },
            img: {
              props: {
                style: {
                  width: '100%',
                },
              },
            },
          },
        }}
      >
        {children ?? ''}
      </MarkdownPreview>
    </MarkdownStyling>
  );
};
