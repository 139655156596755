import { useMutation } from 'urql';
import { graphql } from '../gql';

export const CreateDrawingsMutation = graphql(/* GraphQL */ `
  mutation CreateDrawings($input: [CreateDrawingInput!]!) {
    createDrawings(input: $input) {
      drawings {
        ...DrawingData
      }
    }
  }
`);

export const useCreateDrawings = () => useMutation(CreateDrawingsMutation);
