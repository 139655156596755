import { Link, NavLink, useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { Button } from '../Button';
import { Text } from '../Text/Text';
import { AddIcon, SearchIcon } from '../icons';
import {
  useJoinTeam,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';
import styled from 'styled-components';
import { TeamAvatar } from '../TeamAvatar';
import { Chip } from '../Chip/chip';
import { addToast } from '../Toast';
import { formatErrorMessage } from '../helpers';

interface OrganizationTeamSwitcherProps {
  organizationId: string;
}

export const OrganizationTeamSwitcher = (
  props: OrganizationTeamSwitcherProps
) => {
  const { data: organization } = useOrganization(props.organizationId);
  const [joinTeamRes, joinTeam] = useJoinTeam();
  const navigate = useNavigate();

  const acceptTeamInvite = async (teamId: string) => {
    const res = await joinTeam({
      input: {
        teamId: teamId,
      },
    });
    if (res.error) {
      addToast('Error while joining team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });

      return;
    }
    addToast(`Successfully accepted invitation`);
    navigate(paths.files.team(teamId));
  };

  const availableTeams =
    organization?.joinedTeams.nodes.filter((team) => !team.archived) ?? [];

  return (
    <div>
      <TeamSelectorHeader>
        <Text type="button3" color="info">
          Teams
        </Text>
        <TeamSelectorActions>
          <Button
            as={Link}
            to={paths.settings.organization.teams(props.organizationId)}
            variant="transparent"
            size="icon"
          >
            <SearchIcon />
          </Button>

          {organization?.currentUserRole === 'ADMIN' && (
            <Button
              as={Link}
              to={paths.settings.organization.createTeam(props.organizationId)}
              variant="transparent"
              size="icon"
            >
              <AddIcon />
            </Button>
          )}
        </TeamSelectorActions>
      </TeamSelectorHeader>
      <TeamsList>
        {!organization?.invitedToTeams?.nodes?.length &&
          !availableTeams.length && (
            <Text block color="info" style={{ padding: '6px 0 6px 18px' }}>
              You're not a member of any team yet.
            </Text>
          )}
        {organization?.invitedToTeams.nodes.map((team) => (
          <TeamItem
            as={'div'}
            onClick={() => acceptTeamInvite(team.id)}
            key={team.id}
            style={{ cursor: 'pointer' }}
          >
            <TeamAvatar teamId={team.id} name={team.name} size="small" />
            <Text
              color="default"
              type="sh1"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                flex: 1,
              }}
            >
              {team.name}
            </Text>
            <Chip>Pending invite</Chip>
          </TeamItem>
        ))}

        {availableTeams.map((team) => (
          <TeamItem to={paths.files.team(team.id)} key={team.id}>
            <TeamAvatar teamId={team.id} name={team.name} size="small" />
            <Text
              color="default"
              type="sh1"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {team.name}
            </Text>
          </TeamItem>
        ))}
      </TeamsList>
    </div>
  );
};

const TeamSelectorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0 6px 18px;
  margin-bottom: 8px;
`;

const TeamItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 13px 16px;
  gap: 12px;
  border-radius: ${(p) => p.theme.borderRadius.default};
  transition: 0.2s background-color ease;
  &:hover {
    background-color: ${(p) => p.theme.surface.e1};
  }
  &[aria-current] {
    background-color: ${(p) => p.theme.surface.e1};
  }
`;

const TeamSelectorActions = styled.div`
  display: flex;
`;

const TeamsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
