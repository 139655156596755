import * as React from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow,
  safePolygon,
  useTransitionStyles,
  FloatingArrow,
  useClick,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import styled, {
  DefaultTheme,
  ThemedCssFunction,
  useTheme,
} from 'styled-components';

export const ARROW_SIZE = 10;

interface TooltipOptions {
  placement?: Placement;
  trigger?: 'hover' | 'click' | 'none';
  padding?: number;
  manualOpen?: boolean;
  delay?: number | { open: number; close: number };
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
}

function useTooltip({
  placement = 'top',
  trigger = 'hover',
  padding,
  manualOpen,
  delay = 0,
  isOpen,
  onOpenChange,
  disabled = false,
}: TooltipOptions = {}) {
  const [internalOpen, setInternalOpen] = React.useState(false);
  const open = isOpen ?? internalOpen;
  const setOpen = onOpenChange ?? setInternalOpen;

  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open: disabled ? false : manualOpen ?? open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_SIZE + (padding ?? 0)),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: ARROW_SIZE,
      }),
      shift({ padding: ARROW_SIZE }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    handleClose: safePolygon(),
    enabled: trigger === 'hover',
    delay,
  });

  const click = useClick(context, {
    enabled: trigger === 'click',
  });

  const focus = useFocus(context, {});
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([click, hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}

type ContextType =
  | (ReturnType<typeof useTooltip> & {
      parentsContext: ReturnType<typeof useTooltip>[];
    })
  | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export function RichTooltip({
  children,
  ...options
}: {
  children: React.ReactNode;
  noParentIntegration?: boolean;
} & TooltipOptions) {
  const parent = React.useContext(TooltipContext);
  const parentsContext =
    parent && !options.noParentIntegration
      ? parent.parentsContext.concat(parent)
      : [];

  // when nesting tooltips, if the parent is open (for example after clicking on the menu), disable the child tooltip
  const parentIsOpen = parentsContext.some((parent) => parent.open);
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({
    ...options,
    isOpen: parentIsOpen ? false : options.isOpen,
  });

  return (
    <TooltipContext.Provider
      value={{
        ...tooltip,
        parentsContext,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
}

export const RichTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(function TooltipTrigger({ children }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([
    ...context.parentsContext.map(
      (parentContext) => parentContext.refs.setReference
    ),
    context.refs.setReference,
    propRef,
    childrenRef,
  ]);

  if (!React.isValidElement(children)) {
    throw new Error(
      'RichTooltipTrigger children is not a valid element, it should only contain a single element'
    );
  }

  let childProps = context.getReferenceProps({
    ref,
    ...children.props,
    'data-state': context.open ? 'open' : 'closed',
  });
  for (const parentContext of context.parentsContext) {
    // allow nesting multiple tooltips on a single TooltipTrigger, for example to have a tooltip on hover and also a menu on click
    childProps = parentContext.getReferenceProps({
      ref,
      ...children.props,
      ...childProps,
      'data-state': context.open ? 'open' : 'closed',
    });
  }

  // `asChild` allows the user to pass any element as the anchor
  return React.cloneElement(children, childProps);
});

const RichTooltipContainer = styled.div<{
  $backgroundSurfaceColor: string;
}>`
  background-color: ${(p) => p.$backgroundSurfaceColor};
  padding: 14px;
  border-radius: ${(p) => p.theme.borderRadius.default};
`;

const RichTooltipArrow = (props: { backgroundSurfaceColor: string }) => {
  const context = useTooltipContext();

  // Auto position the arrow at the center of the tooltip, except when positioning it at the start or end of a side.
  const staticOffset =
    context.placement.includes('start') || context.placement.includes('end')
      ? 15
      : null;

  return (
    <FloatingArrow
      ref={context.arrowRef}
      context={context.context}
      width={ARROW_SIZE * 1.5}
      height={ARROW_SIZE}
      style={{
        fill: props.backgroundSurfaceColor,
      }}
      tipRadius={1}
      staticOffset={staticOffset}
    />
  );
};

export const RichTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    $backgroundSurface?: keyof DefaultTheme['surface'] | 'primary';
    hideArrow?: boolean;
    closeOnClick?: boolean;
    renderArrow?: () => React.ReactNode;
    renderContainer?: (
      props: React.HTMLProps<HTMLDivElement>
    ) => React.ReactNode;
  }
>(function TooltipContent(
  {
    $backgroundSurface,
    renderArrow,
    renderContainer,
    closeOnClick,
    hideArrow,
    ...rest
  },
  propRef
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const theme = useTheme();

  const arrowX = context.middlewareData.arrow?.x ?? 0;
  const arrowY = context.middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_SIZE / 2;
  const transformY = arrowY + ARROW_SIZE;

  const backgroundSurfaceColor =
    $backgroundSurface === 'primary'
      ? theme.primary.default
      : theme.surface[$backgroundSurface ?? 'e1'];

  const { isMounted, styles } = useTransitionStyles(context.context, {
    initial: {
      opacity: '0',
      transform: 'scale(0.9)',
    },
    duration: 200,
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_SIZE}px)`,
        bottom: `${transformX}px ${-ARROW_SIZE}px`,
        left: `calc(100% + ${ARROW_SIZE}px) ${transformY}px`,
        right: `${-ARROW_SIZE}px ${transformY}px`,
      }[side],
    }),
  });

  return (
    <FloatingPortal>
      {isMounted && (
        <div
          style={{ ...context.floatingStyles, zIndex: 10 }}
          {...context.getFloatingProps()}
          ref={ref}
          onClick={() => {
            if (closeOnClick) {
              context.setOpen(false);
            }
          }}
        >
          <div style={styles}>
            {!hideArrow &&
              (renderArrow?.() ?? (
                <RichTooltipArrow
                  backgroundSurfaceColor={backgroundSurfaceColor}
                />
              ))}
            {renderContainer?.(rest) ?? (
              <RichTooltipContainer
                {...(rest as any)}
                $backgroundSurfaceColor={backgroundSurfaceColor}
              />
            )}
          </div>
        </div>
      )}
    </FloatingPortal>
  );
});
