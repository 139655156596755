import { graphql } from '../gql';
import { ResultOf } from '@graphql-typed-document-node/core';

export const OrganizationDataFragment = graphql(/* GraphQL */ `
  fragment OrganizationData on Organization {
    id
    logoPath
    name
    subscriptionPlan
    currentUserRole
    globalTeam {
      id
    }
    poc
    pocEndDate
    publicSharing
  }
`);

export type OrganizationBasic = ResultOf<typeof OrganizationDataFragment>;

export const OrganizationFullDataFragment = graphql(/* GraphQL */ `
  fragment OrganizationFullData on Organization {
    ...OrganizationData
    paidSeatsCount
    manualSubscription
    promptDenylist
    joinedTeams {
      nodes {
        ...TeamBasicData
      }
    }
  }
`);

export type OrganizationData = ResultOf<typeof OrganizationFullDataFragment>;

export const OrganizationInvitesFragment = graphql(/* GraphQL */ `
  fragment OrganizationInvitesData on Organization {
    organizationInvites {
      nodes {
        email
        role
        createdAt
      }
    }
  }
`);

export type OrganizationInvitesData = ResultOf<
  typeof OrganizationInvitesFragment
>;

export const OrganizationActionLogsFragment = graphql(/* GraphQL */ `
  fragment UserActionLogsData on UserActionLog {
    actionType
    eventDate
    targetTable
    userEmail
    targetId
    metadata
  }
`);

export type OrganizationActionLogsData = ResultOf<
  typeof OrganizationActionLogsFragment
>;

export const OrganizationMembersFragment = graphql(/* GraphQL */ `
  fragment OrganizationMembersData on Organization {
    id
    members {
      edges {
        role
        node {
          id
          name
          email
          createdAt
          lastActive
        }
      }
    }
  }
`);

export type OrganizationMembersData = ResultOf<
  typeof OrganizationMembersFragment
>;

export const OrganizationAllTeamsFragment = graphql(/* GraphQL */ `
  fragment OrganizationAllTeamsData on Organization {
    id
    teams {
      nodes {
        ...TeamData
        usersOnTeamsByTeamId {
          totalCount
        }
        invites {
          totalCount
        }
      }
    }
  }
`);

export const OrganizationSubscriptionFragment = graphql(/* GraphQL */ `
  fragment OrganizationSubscriptionData on Organization {
    id
    subscriptionPlan
    manualSubscription
    subscription {
      status
      seats
      billingEmail
      currentPeriodEnd
      cancelAtPeriodEnd
      nextInvoiceAmount
      nextInvoiceBillingDate
      paymentMethodType
      paymentMethodCardLast4
      paymentMethodCardExpires
      paymentMethodCardBrand
      subscriptionBaseAmount
    }
  }
`);

export type OrganizationSubscriptionData = ResultOf<
  typeof OrganizationSubscriptionFragment
>;

const OrganizationRecentlyViewedWorkbenchesFragment = graphql(/* GraphQL */ `
  fragment OrganizationRecentlyViewedWorkbenchesData on Organization {
    id
    recentlyViewedWorkbenches(first: 20) {
      nodes {
        ...WorkbenchBasicData
        folder {
          id
          name: formattedName
        }
      }
    }
  }
`);
