import styled from 'styled-components';
import { MouseEventHandler } from 'react';
import { CheckIcon, CheckLineIcon } from '@vizcom/shared-ui-components';

const CheckboxContainer = styled.div<{ $checked: boolean; $disabled: boolean }>`
  background-color: ${({ $checked, theme }) =>
    $checked ? theme.surface.e2 : 'transparent'};

  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.surface.e3};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $disabled = false, theme }) =>
    $disabled ? theme.secondary.disabled : theme.text.default};
`;

type CheckboxProps = {
  icon?: 'check' | 'line';
  checked: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const Checkbox = ({
  checked,
  disabled = false,
  onClick,
  icon = 'check',
}: CheckboxProps) => {
  const Icon = icon === 'check' ? CheckIcon : CheckLineIcon;

  return (
    <CheckboxContainer
      $checked={checked}
      $disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {checked && <Icon style={{ color: 'white' }} />}
    </CheckboxContainer>
  );
};
