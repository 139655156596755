import { useMutation } from 'urql';
import { graphql } from '../gql';

const SetUserFeatureFlag = graphql(/* GraphQL */ `
  mutation setUserFeatureFlag($input: SetUserFeatureFlagInput!) {
    setUserFeatureFlag(input: $input) {
      user {
        id
        featureFlags
      }
    }
  }
`);

export const useSetUserFeatureFlag = () => useMutation(SetUserFeatureFlag);
