import { graphql } from '../../gql';

export const CreateWorkbenchElementsMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElements(
    $createDrawingsInput: [CreateDrawingInput!]!
    $createWorkbenchElementsPlaceholderInput: [WorkbenchElementPlaceholderInput!]!
    $createWorkbenchElementsImg2ImgInput: [WorkbenchElementImg2ImgInput!]!
    $createWorkbenchElementsTextInput: [WorkbenchElementTextInput!]!
    $createWorkbenchElementsMixInput: [WorkbenchElementMixInput!]!
    $createWorkbenchElementsSectionInput: [WorkbenchElementSectionInput!]!
    $createCompositeScenesInput: [CompositeSceneInput!]!
    $createWorkbenchElementsPaletteInput: [WorkbenchElementPaletteInput!]!
  ) {
    createDrawings(input: $createDrawingsInput) {
      drawings {
        ...DrawingData
      }
    }
    createWorkbenchElementsPlaceholder(
      input: $createWorkbenchElementsPlaceholderInput
    ) {
      placeholders {
        ...WorkbenchElementPlaceholderData
      }
    }
    createWorkbenchElementsImg2Img(
      input: $createWorkbenchElementsImg2ImgInput
    ) {
      img2Imgs {
        ...WorkbenchElementImg2ImgData
      }
    }
    createWorkbenchElementsText(input: $createWorkbenchElementsTextInput) {
      texts {
        ...WorkbenchElementTextData
      }
    }
    createWorkbenchElementsMix(input: $createWorkbenchElementsMixInput) {
      mixs {
        ...WorkbenchElementMixData
      }
    }
    createWorkbenchElementsSection(
      input: $createWorkbenchElementsSectionInput
    ) {
      sections {
        ...WorkbenchElementSectionData
      }
    }
    createCompositeScenes(input: $createCompositeScenesInput) {
      compositeScenes {
        ...CompositeSceneData
      }
    }
    createWorkbenchElementsPalette(
      input: $createWorkbenchElementsPaletteInput
    ) {
      palettes {
        ...WorkbenchElementPaletteData
      }
    }
  }
`);
