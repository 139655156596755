import { useQuery } from 'urql';
import { graphql } from '../gql';

const UsersByEmail = graphql(/* GraphQL */ `
  query UsersByEmail($email: String!) {
    users(condition: { email: $email }) {
      nodes {
        id
        email
        name
        description
        occupation
        createdAt
        updatedAt
        isAdmin
        featureFlags
      }
    }
  }
`);

export const useUsersByEmail = (email: string) => {
  const [res] = useQuery({
    query: UsersByEmail,
    variables: {
      email,
    },
    pause: !email,
  });

  return {
    ...res,
    data: res.data?.users?.nodes,
  };
};

const UsersById = graphql(/* GraphQL */ `
  query UsersById($ids: [String!]!) {
    users(filter: { id: { in: $ids } }) {
      nodes {
        ...UserBasicData
      }
    }
  }
`);

export const useUsersById = (ids: string[]) => {
  const [res] = useQuery({
    query: UsersById,
    variables: {
      ids,
    },
    pause: ids.length === 0,
  });

  return {
    ...res,
    data: res.data?.users?.nodes,
  };
};
