import { animated } from '@react-spring/web';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type SpinnerProps = {
  size?: {
    width: number;
    height: number;
  };
};

export const Spinner = styled(animated.div)<SpinnerProps>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid ${(p) => p.theme.text.info};
  border-right: 2px solid ${(p) => p.theme.text.info};
  border-bottom: 2px solid ${(p) => p.theme.text.info};
  border-left: 2px solid ${(p) => p.theme.primary.default};
  background: transparent;
  width: ${({ size }) => (size ? size.width : 24)}px;
  height: ${({ size }) => (size ? size.height : 24)}px;
  border-radius: 50%;
  z-index: 1;
`;
