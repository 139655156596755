import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const DrawingEventTypeZodEnum = z.enum([
  'DRAWING_CREATED',
  'DRAWING_DELETED',
  'DRAWING_DUPLICATED',
  'DRAWING_MOVED',
]);

export const DrawingEventName = DrawingEventTypeZodEnum.Enum;

export const DrawingTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: DrawingEventTypeZodEnum,
  data: z.object({
    drawingId: z.string(),
  }),
});
