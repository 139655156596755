import { useMutation } from 'urql';
import { graphql } from '../../gql';

const UpdateWorkbenchMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbench($id: UUID!, $patch: WorkbenchPatch!) {
    updateWorkbench(input: { id: $id, patch: $patch }) {
      workbench {
        ...WorkbenchBasicData
      }
    }
  }
`);

export const useUpdateWorkbench = () => {
  return useMutation(UpdateWorkbenchMutation);
};
