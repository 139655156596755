import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Sidebar } from '../Sidebar/Sidebar';

export const Wrapper = styled.div`
  flex-direction: column;
  position: relative;
  min-height: 100vh; // fallback for older browsers
  min-height: 100dvh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'sidebar header'
    'sidebar content';

  @media (max-width: 645px) {
    grid-template-columns: 0px 1fr;
    grid-template-areas:
      'header header'
      'sidebar content';
  }
`;

export const styledScrollbar = css`
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.text.info};
  }
`;

export const styledScrollbarDark = css`
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.secondary.hover};
  }
`;

export const ContentWrapper = styled.div`
  grid-area: content;
  position: relative;
  box-sizing: border-box;

  overflow: auto;

  width: 100%;

  background-color: ${(p) => p.theme.surface.e0};

  // Scrollbar in the content element
  height: 0;
  min-height: 100%;
  ${styledScrollbar}
`;

export const Layout = ({
  children,
  hideTeamInSidebar,
  sidebar,
}: {
  children?: ReactNode[] | ReactNode;
  hideTeamInSidebar?: boolean;
  sidebar?: ReactNode[] | ReactNode;
}) => {
  return (
    <Wrapper>
      {sidebar || <Sidebar hideTeam={hideTeamInSidebar} />}

      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
