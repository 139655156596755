import { graphql } from '../gql';
import { UseQueryArgs, useQuery } from 'urql';
import { OrganizationCondition } from '../gql/graphql';
import { ResultOf } from '@graphql-typed-document-node/core';

const organizations = graphql(/* GraphQL */ `
  query organizations($query: OrganizationCondition) {
    organizations(condition: $query) {
      nodes {
        ...OrganizationData
        azureTenantId
        restrictToInvitee
        manualSubscription
        members {
          totalCount
          edges {
            role
            node {
              id
              email
            }
          }
        }
        organizationInvites {
          totalCount
        }
        teams {
          totalCount
        }
      }
    }
  }
`);

export const useOrganizations = (query?: OrganizationCondition) => {
  const [res, execute] = useQuery({
    query: organizations,
    variables: {
      query,
    },
  });

  return { ...res, data: res.data?.organizations?.nodes || [], execute };
};

const organization = graphql(/* GraphQL */ `
  query organization($id: UUID!) {
    organization(id: $id) {
      ...OrganizationFullData
      invitedToTeams {
        nodes {
          id
          name
        }
      }
      members {
        totalCount
      }
    }
  }
`);

export const useOrganization = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: organization,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

const organizationTeams = graphql(/* GraphQL */ `
  query organizationTeams($id: UUID!) {
    organization(id: $id) {
      ...OrganizationAllTeamsData
      invitedToTeams {
        nodes {
          id
          name
        }
      }
    }
  }
`);

export const useOrganizationTeams = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationTeams,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

const organizationMembers = graphql(/* GraphQL */ `
  query organizationMembers($id: UUID!) {
    organization(id: $id) {
      id
      azureTenantId
      ...OrganizationMembersData
      ...OrganizationInvitesData
    }
  }
`);

export const useOrganizationMembers = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationMembers,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

const organizationAccessLogs = graphql(/* GraphQL */ `
  query organizationAccessLogs($id: UUID!, $offset: Int!, $email: String!) {
    organization(id: $id) {
      id
      userAccessLogs(
        first: 10
        offset: $offset
        condition: { userEmailContains: $email }
        orderBy: EVENT_DATE_DESC
      ) {
        nodes {
          userEmail
          ipAddress
          eventDate
          deviceInfo
        }
        totalCount
      }
    }
  }
`);

export const useOrganizationAccessLogs = (
  id: string | undefined,
  offset: number,
  email: string
) => {
  const [res] = useQuery({
    query: organizationAccessLogs,
    variables: {
      id: id!,
      offset,
      email,
    },
    pause: !id,
  });

  return {
    ...res,
    count: res.data?.organization?.userAccessLogs?.totalCount || 0,
    data: res.data?.organization?.userAccessLogs?.nodes || [],
  };
};

const organizationActivityLogs = graphql(/* GraphQL */ `
  query organizationActivityLogs($id: UUID!, $offset: Int!) {
    organization(id: $id) {
      id
      userActionLogs(first: 10, offset: $offset, orderBy: EVENT_DATE_DESC) {
        nodes {
          ...UserActionLogsData
        }
        totalCount
      }
    }
  }
`);

export const useOrganizationActivityLogs = (
  id: string | undefined,
  offset: number
) => {
  const [res] = useQuery({
    query: organizationActivityLogs,
    variables: {
      id: id!,
      offset,
    },
    pause: !id,
  });

  return {
    ...res,
    count: res.data?.organization?.userActionLogs?.totalCount || 0,
    data: res.data?.organization?.userActionLogs?.nodes || [],
  };
};

const pendingInvitedOrganizations = graphql(/* GraphQL */ `
  query pendingInvitedOrganizations {
    pendingInvitedOrganizations {
      nodes {
        id
        logoPath
        name
      }
    }
  }
`);

export const usePendingInvitedOrganizations = (
  args: Partial<UseQueryArgs<any>> = {}
) => {
  const [res] = useQuery({
    ...args,
    query: pendingInvitedOrganizations,
  });

  return {
    ...res,
    data: res.data?.pendingInvitedOrganizations?.nodes,
  };
};

const organizationSubscription = graphql(/* GraphQL */ `
  query organizationSubscription($id: UUID!) {
    organization(id: $id) {
      ...OrganizationSubscriptionData
    }
  }
`);

export const useOrganizationSubscription = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationSubscription,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

const organizationTeamsUsers = graphql(/* GraphQL */ `
  query organizationTeamsUsers($id: UUID!) {
    organization(id: $id) {
      id
      name
      teams {
        nodes {
          ...TeamBasicData
          globalInOrganization
          invites {
            nodes {
              email
            }
          }
          members {
            nodes {
              email
              id
            }
          }
        }
      }
    }
  }
`);

export type OrganizationTeamsUsers = ResultOf<
  typeof organizationTeamsUsers
>['organization'];

export const useOrganizationTeamsUsers = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationTeamsUsers,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};
