import { graphql } from '../../gql';

export const UpdateWorkbenchElementMixMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementMix($input: UpdateWorkbenchElementMixInput!) {
    updateWorkbenchElementMix(input: $input) {
      workbenchElementMix {
        ...WorkbenchElementMixData
      }
    }
  }
`);

export const DeleteWorkbenchElementMixMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementMix($input: DeleteWorkbenchElementMixInput!) {
    deleteWorkbenchElementMix(input: $input) {
      workbenchElementMix {
        ...WorkbenchElementMixData
      }
    }
  }
`);

export const TriggerWorkbenchElementMixMutation = graphql(/* GraphQL */ `
  mutation TriggerWorkbenchElementMix(
    $input: TriggerWorkbenchElementMixInput!
  ) {
    triggerWorkbenchElementMix(input: $input) {
      placeholder {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);
