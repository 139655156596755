import { graphql } from '../../gql';
import { urqlClient } from '../../lib/graphql';
import { OperationResult, OperationResultSource } from 'urql';
import { EphemeralJobMailboxSubscription } from '../../gql/graphql';
import { ephemeralJobMailboxId } from './ephemeralJobMailboxId';
import { assertUnreachable } from '../../../../../js-utils/src';

export const ephemeralJobMailboxSubscription = graphql(/* GraphQL */ `
  subscription ephemeralJobMailbox($input: EphemeralJobMailboxInput!) {
    ephemeralJobMailbox(input: $input) {
      __typename
      ... on EphemeralJobMailboxResult {
        jobId
        data
      }
      ... on EphemeralJobMailboxError {
        jobId
        error
      }
    }
  }
`);

let subscription: OperationResultSource<
  OperationResult<EphemeralJobMailboxSubscription>
> | null = null;

export const waitForEphemeralJobResult = (jobId: string, timeout?: number) => {
  return new Promise((resolve, reject) => {
    if (!subscription) {
      subscription = urqlClient.subscription(ephemeralJobMailboxSubscription, {
        input: {
          mailboxId: ephemeralJobMailboxId,
        },
      });
    }

    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (timeout) {
      timeoutId = setTimeout(() => {
        unsubscribe();
        reject(new Error('Timeout'));
      }, timeout);
    }

    const { unsubscribe } = subscription.subscribe((value) => {
      if (value.data?.ephemeralJobMailbox.jobId === jobId) {
        unsubscribe();
        clearTimeout(timeoutId);
        if (
          value.data?.ephemeralJobMailbox.__typename ===
          'EphemeralJobMailboxError'
        ) {
          reject(new Error(value.data?.ephemeralJobMailbox.error));
        } else if (
          value.data?.ephemeralJobMailbox.__typename ===
          'EphemeralJobMailboxResult'
        ) {
          resolve(value.data?.ephemeralJobMailbox.data);
        } else {
          assertUnreachable(value.data.ephemeralJobMailbox);
        }
      }
    });
  });
};
