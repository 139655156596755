import { CSSProperties, ComponentPropsWithoutRef, useState } from 'react';
import { useAutocomplete } from '@mui/base';
import styled from 'styled-components';
import { CheckmarkIcon, CloseIcon } from '../icons';
import { addToast } from '../Toast';

const PLACEHOLDER_TEXT = 'Email addresses separated by a comma';

const Option = styled.li`
  color: ${(p) => p.theme.text.info};
  padding: 9px;
  border-radius: 6px;
  margin: 5px;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${(p) => p.theme.surface.e1};
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SelectedIndicator = styled.div<{ $active: boolean }>`
  margin-right: 6px;
  opacity: ${(p) => (p.$active ? 1 : 0)};
`;

const OptionsList = styled.ul`
  position: absolute;
  max-width: 230px;
  left: 0;
  right: 0;
  padding: 4px;
  margin: 4px 0;
  border-radius: ${(p) => p.theme.borderRadius.default};
  background-color: ${(p) => p.theme.surface.e0};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  overflow-x: hidden;
  outline: 0px;
  max-height: 240px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

const EmailChip = styled.div<{ $baseSurfaceColorIndex?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.surface.e2};
  padding: 8px 6px;
  color: ${(p) => p.theme.text.default};
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const EmailChipCloseIcon = styled(CloseIcon)`
  margin-left: 8px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

const Chip = (
  props: {
    label: string;
    onDelete: (event: any) => void;
  } & ComponentPropsWithoutRef<typeof EmailChip>
) => {
  const { label, onDelete, ...rest } = props;

  return (
    <EmailChip {...rest}>
      <span>{props.label}</span>
      <EmailChipCloseIcon onClick={props.onDelete} />
    </EmailChip>
  );
};

const EmailInputContainer = styled.div<{ $baseSurfaceColorIndex?: number }>`
  min-height: 200px;
  color: ${(p) => p.theme.text.default};

  background-color: ${(p) =>
    p.$baseSurfaceColorIndex !== undefined
      ? (p.theme.surface as any)[`e${p.$baseSurfaceColorIndex}`]
      : 'transparent'};

  input {
    min-width: 230px;
    height: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }
`;

const EmailInputList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

// from https://stackoverflow.com/a/201378
const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

interface EmailMultiInputProps {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  style?: CSSProperties;
  baseSurfaceColorIndex?: number;
  autoCompleteOptions?: string[];
  autofocus?: boolean;
}

export const EmailMultiInput = ({
  emails,
  setEmails,
  style,
  baseSurfaceColorIndex,
  autoCompleteOptions = [],
  autofocus = true,
}: EmailMultiInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const autocomplete = useAutocomplete({
    value: emails,
    freeSolo: true,
    multiple: true,
    autoSelect: true,
    options: autoCompleteOptions,
    onChange: (e, newEmails, reason) => {
      if (
        reason !== 'createOption' &&
        reason !== 'selectOption' &&
        reason !== 'blur'
      ) {
        // only check when adding an option
        setEmails(newEmails);
        return;
      }

      const newEmail = newEmails.at(-1);
      if (newEmail?.match(/[, ]/)) {
        // if there's a space or comma
        const emails = newEmail.split(/[, ]/); // split by space or comma
        for (const email of emails) {
          if (email && !email.match(emailRegex)) {
            addToast(`${email} is not a valid email address`, {
              type: 'danger',
            });
            setInputValue(newEmail);
            return;
          }
        }
        setEmails([
          ...newEmails.slice(0, newEmails.length - 1),
          ...emails.filter(Boolean),
        ]);
        return;
      }

      if (!newEmail || newEmail.match(emailRegex)) {
        setEmails(newEmails);
      } else {
        setInputValue(newEmail);
        addToast(`${newEmail} is not a valid email address`, {
          type: 'danger',
        });
      }
    },
    inputValue,
    onInputChange: (e, newInputValue) => {
      setInputValue(newInputValue);
    },
  });

  return (
    <EmailInputContainer
      $baseSurfaceColorIndex={baseSurfaceColorIndex}
      style={style}
      {...autocomplete.getRootProps()}
    >
      <EmailInputList ref={() => autocomplete.setAnchorEl()}>
        {emails.map((email, index) => (
          <Chip label={email} {...autocomplete.getTagProps({ index })} />
        ))}

        <div style={{ position: 'relative', flex: 1 }}>
          <input
            type="text"
            autoFocus={autofocus}
            placeholder={!emails.length ? PLACEHOLDER_TEXT : ''}
            {...autocomplete.getInputProps()}
          />

          {autocomplete.groupedOptions.length > 0 && (
            <OptionsList {...autocomplete.getListboxProps()}>
              {(autocomplete.groupedOptions as string[]).map(
                (option, index) => (
                  <Option {...autocomplete.getOptionProps({ option, index })}>
                    <SelectedIndicator
                      $active={autocomplete.value.includes(option)}
                    >
                      <CheckmarkIcon />
                    </SelectedIndicator>
                    <p title={option}>{option}</p>
                  </Option>
                )
              )}
            </OptionsList>
          )}
        </div>
      </EmailInputList>
    </EmailInputContainer>
  );
};
