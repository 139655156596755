import styled from 'styled-components';
import { BaseIconProps } from '../../types';

import BaseIconPlus from './plus.svg?react';

export const IconPlus = styled(BaseIconPlus)<BaseIconProps>`
  width: ${({ width }) => width || '16px'};
  height: ${({ height }) => height || '16px'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  opacity: ${({ opacity }) => opacity || '1'};
  cursor: ${({ cursor }) => cursor || 'pointer'};
  fill: ${({ fill }) => fill || '#333'};
  stroke: ${({ stroke }) => stroke || '#333'};
`;
