import {
  AddIcon,
  Button,
  DropdownIcon,
  FolderIcon,
  TeamAvatar,
  addToast,
  styledScrollbar,
} from '@vizcom/shared-ui-components';
import {
  useCreateFolder,
  useFetchOrCreateUserOrganizationDraftFolder,
  useFolder,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import styled from 'styled-components';

const FolderTreeContainer = styled.div`
  height: 300px;
  overflow-y: auto;
  ${styledScrollbar}
  margin: 0 -24px;
  padding: 8px;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.surface.e0};
  margin: 16px 0;
  border-radius: 8px;
`;
const FolderLabel = styled.div<{ selected: boolean; editable: boolean }>`
  cursor: ${(p) => (p.editable ? 'pointer' : 'not-allowed')};
  background-color: ${(props) =>
    props.selected ? props.theme.surface.e2 : 'transparent'};
  color: ${(props) =>
    props.selected ? props.theme.text.default : props.theme.text.info};
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  padding-right: 12px;
`;
const FolderName = styled.div`
  flex: 1;
  padding: 10px 10px 10px 8px;
  display: flex;
`;
const ChildrenFolderList = styled.div`
  color: ${(p) => p.theme.text.info};
  margin-left: 10px;
`;
const DropdownIconContainer = styled.div`
  transition: 0.25s all ease;
  cursor: pointer;
  display: flex;
  padding: 5px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
`;

const FolderIconStyled = styled(FolderIcon)`
  width: 16px;
  height: 16px;
  color: ${(p) => p.theme.text.default};
`;

const FolderTree = (props: {
  folderId: string;
  defaultOpen?: boolean;
  name: ReactNode;
  icon?: ReactNode;
  onSelectFolder: (id: string) => any;
  selectedFolderId: string | null;
  loadData: boolean;
}) => {
  const { folderId, defaultOpen, name } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const [createFolderRes, createFolder] = useCreateFolder();

  const folderData = useFolder(props.loadData ? folderId : null);
  const isEditable = !!folderData.data?.isEditable;

  const handleFolderClick: MouseEventHandler = (e) => {
    if (isEditable) {
      props.onSelectFolder(folderId);
    } else {
      addToast(
        `You don't have the permission to move something to this folder`
      );
    }
  };

  const handleOpenFolder: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const isSelected = folderId === props.selectedFolderId;

  const handleAddButtonClick: MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
    const res = await createFolder({
      input: {
        folder: {
          name: 'New Folder',
          parentId: folderId,
        },
      },
    });
    if (res.data?.createFolder?.folder?.id) {
      props.onSelectFolder(res.data.createFolder.folder.id);
    }
  };

  return (
    <>
      <FolderLabel
        onClick={handleFolderClick}
        selected={isSelected}
        editable={isEditable}
      >
        <DropdownIconContainer
          onClick={handleOpenFolder}
          style={{
            transform: `rotate(${isOpen ? '0deg' : '-90deg'})`,
            opacity: folderData.data?.childFolders.nodes?.length === 0 ? 0 : 1,
          }}
        >
          <DropdownIcon />
        </DropdownIconContainer>
        <FolderName>
          {props.icon ?? <FolderIconStyled style={{ marginRight: 8 }} />} {name}
        </FolderName>
        {isSelected && (
          <Button
            variant="transparent"
            size="icon"
            onClick={handleAddButtonClick}
            disabled={createFolderRes.fetching}
          >
            <AddIcon />
          </Button>
        )}
      </FolderLabel>
      <ChildrenFolderList style={{ display: isOpen ? 'block' : 'none' }}>
        {folderData.fetching && !folderData.data && (
          <p style={{ paddingLeft: 16 }}>Loading...</p>
        )}
        {folderData.data?.childFolders.nodes.map((child) => (
          <FolderTree
            key={child.id}
            folderId={child.id}
            name={child.name}
            onSelectFolder={props.onSelectFolder}
            selectedFolderId={props.selectedFolderId}
            loadData={isOpen}
          />
        ))}
      </ChildrenFolderList>
    </>
  );
};

interface TargetFolderSelectorProps {
  organizationId: string;
  selectedFolderId: string | null;
  setSelectedFolderId: Dispatch<SetStateAction<string | null>>;
}

export const TargetFolderSelector = (props: TargetFolderSelectorProps) => {
  const { organizationId, selectedFolderId, setSelectedFolderId } = props;
  const selectedOrganization = useOrganization(organizationId);
  const { data: draftFolder } =
    useFetchOrCreateUserOrganizationDraftFolder(organizationId);

  return (
    <FolderTreeContainer>
      {draftFolder && (
        <FolderTree
          folderId={draftFolder.id}
          selectedFolderId={selectedFolderId}
          onSelectFolder={setSelectedFolderId}
          name="My files"
          loadData
        />
      )}
      {selectedOrganization.data?.joinedTeams?.nodes
        ?.filter((team) => !team.archived)
        ?.map((team) => (
          <FolderTree
            key={team.id}
            folderId={team.rootFolder!.id}
            selectedFolderId={selectedFolderId}
            onSelectFolder={setSelectedFolderId}
            icon={
              <TeamAvatar
                name={team.name}
                size="small"
                teamId={team.id}
                style={{ marginRight: 15 }}
              />
            }
            name={team.name}
            loadData
          />
        ))}
    </FolderTreeContainer>
  );
};
