import { useQuery } from 'urql';
import { graphql } from '../gql';

export const PalletesByWorkbenchId = graphql(/* GraphQL */ `
  query palletesByWorkbenchId($id: UUID!) {
    workbench(id: $id) {
      palettes {
        nodes {
          id
          name
          status
          tags
          thumbnailPath
        }
      }
    }
  }
`);

export const useWorkbenchPalettes = (id?: string) => {
  const [res] = useQuery({
    query: PalletesByWorkbenchId,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.workbench?.palettes };
};

export const WorkbenchPalleteByPaletteId = graphql(/* GraphQL */ `
  query paletteByPaletteId($id: UUID!) {
    workbenchElementPalette(id: $id) {
      ...WorkbenchElementPaletteData
    }
  }
`);

export const useWorkbenchPalette = (id?: string | null) => {
  const [res] = useQuery({
    query: WorkbenchPalleteByPaletteId,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.workbenchElementPalette };
};
