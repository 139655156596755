export const boundNumber = (
  minBound: number,
  value: number,
  maxBound: number
) => Math.min(maxBound, Math.max(minBound, value));

export const logStep = (
  value: number,
  step: number,
  minBound: number,
  maxBound: number
) => {
  minBound = Math.log(minBound);
  maxBound = Math.log(maxBound);
  const range = maxBound - minBound;
  value = boundNumber(0, (Math.log(value) - minBound) / range + step, 1);
  return Math.exp(minBound + value * range);
};

// formatBytes
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
const k = 1024;
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes < 1) return '0 Bytes';
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
