import { useQuery } from 'urql';
import { graphql } from '../gql';

const compositeScene = graphql(/* GraphQL */ `
  query compositeScene($id: UUID!) {
    compositeScene(id: $id) {
      ...CompositeSceneFullData
    }
  }
`);

export const useCompositeScene = (id: string | undefined) => {
  const [res] = useQuery({
    query: compositeScene,
    variables: {
      id: id!,
    },
    requestPolicy: 'cache-and-network',
    pause: !id,
  });

  return { ...res, data: res.data?.compositeScene };
};
