import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type {
  Active,
  CollisionDetection,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import styled from 'styled-components';
import { SortableOverlay } from './SortableOverlay';
import { DragHandle, SortableItem } from './SortableItem';
import { createPortal } from 'react-dom';

interface BaseItem {
  id: UniqueIdentifier;
  orderKey: string;
}

interface Props<T extends BaseItem> {
  activeLayerIds: UniqueIdentifier[];
  items: T[];
  onChange(
    newOrder: string[],
    nextItem: T | undefined,
    previousItem: T | undefined
  ): void;
  renderItem(item: T, index: number): ReactNode;
  setActiveLayer: (id: string) => void;
}

export function SortableList<T extends BaseItem>({
  activeLayerIds,
  items,
  onChange,
  renderItem,
  setActiveLayer,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const [selected, setSelected] = useState<UniqueIdentifier[]>([]);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 8,
      },
    })
  );

  const filterItems = (items: T[]) => {
    return items.filter((item) => {
      if (item.id === active?.id) {
        return true;
      }

      return !selected?.includes(item.id);
    });
  };

  const fixCursorSnapOffset: CollisionDetection = (args) => {
    // Bail out if keyboard activated
    if (!args.pointerCoordinates) {
      return closestCenter(args);
    }
    const { x, y } = args.pointerCoordinates;
    const { width, height } = args.collisionRect;
    const updated = {
      ...args,
      // The collision rectangle is broken when using snapCenterToCursor. Reset
      // the collision rectangle based on pointer location and overlay size.
      collisionRect: {
        width,
        height,
        bottom: y + height / 2,
        left: x - width / 2,
        right: x + width / 2,
        top: y - height / 2,
      },
    };
    return closestCenter(updated);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        if (!activeLayerIds.includes(active.id)) {
          setActiveLayer(active.id as string);
          setActive(active);
          setSelected([active.id]);
          return;
        }
        setActive(active);
        setSelected(activeLayerIds);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const filteredItems = items.filter(
            ({ id }) => id === active?.id || !selected?.includes(id)
          );
          const activeItem = filteredItems.find(({ id }) => id === active.id);
          const activeIndex = filteredItems.findIndex(
            ({ id }) => id === active.id
          );
          const overIndex = filteredItems.findIndex(({ id }) => id === over.id);
          const direction = overIndex > activeIndex ? 1 : -1;
          const nextItem =
            direction === 1
              ? filteredItems[overIndex]
              : filteredItems[overIndex - 1];
          const previousItem =
            direction === 1
              ? filteredItems[overIndex + 1]
              : filteredItems[overIndex];

          const newOrder = filteredItems
            .filter((item) => item.id !== active.id)
            .map((item) => item.id);
          newOrder.splice(overIndex, 0, ...selected);

          if (activeItem) {
            onChange(newOrder as string[], nextItem, previousItem);
          }
        }
        setActive(null);
        setSelected([]);
      }}
      onDragCancel={() => {
        setActive(null);
        setSelected([]);
      }}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={fixCursorSnapOffset}
    >
      <SortableContext
        items={filterItems(items)}
        strategy={verticalListSortingStrategy}
      >
        <List role="application">
          {filterItems(items).map((item, i) => (
            <React.Fragment key={item.id}>{renderItem(item, i)}</React.Fragment>
          ))}
        </List>
      </SortableContext>
      {createPortal(
        <SortableOverlay
          selectedCount={selected?.length}
          selectedOffset={(active && selected?.indexOf(active?.id)) ?? 0}
        >
          {activeItem ? renderItem(activeItem, 0) : null}
        </SortableOverlay>,
        document.getElementById('root')!
      )}
    </DndContext>
  );
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  height: 100%;
`;

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
