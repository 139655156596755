import { useMutation } from 'urql';
import { graphql } from '../../gql';

const UpdateManuallyManagedOrganization = graphql(/* GraphQL */ `
  mutation UpdateManuallyManagedOrganization(
    $input: UpdateManuallyManagedOrganizationInput!
  ) {
    updateManuallyManagedOrganization(input: $input) {
      organization {
        ...OrganizationData
      }
    }
  }
`);

export const useUpdateManuallyManagedOrganization = () => {
  return useMutation(UpdateManuallyManagedOrganization);
};
