import { graphql } from '../gql';
import { useQuery } from 'urql';

const teamById = graphql(/* GraphQL */ `
  query team($id: UUID!) {
    team(id: $id) {
      ...TeamData
    }
  }
`);

export const useTeam = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamById,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};

const teamByIdMembers = graphql(/* GraphQL */ `
  query teamMembers($id: UUID!) {
    team(id: $id) {
      ...TeamData
      ...TeamMembers
    }
  }
`);
export const useTeamMembers = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamByIdMembers,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};

const teamMemberCount = graphql(/* GraphQL */ `
  query teamMemberCount($id: UUID!) {
    team(id: $id) {
      id
      name
      globalInOrganization
      members {
        totalCount
      }
    }
  }
`);

export const useTeamMemberCount = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamMemberCount,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};

// const teamSubscription = graphql(/* GraphQL */ `
//   query teamSubscription($id: UUID!) {
//     team(id: $id) {
//       id
//       subscription {
//         type
//         seats
//         trialEnd
//         currentPeriodEnd
//         cancelAtPeriodEnd
//         nextInvoiceAmount
//         nextInvoiceBillingDate
//         hasPaymentMethod
//       }
//     }
//   }
// `);
// export const useTeamSubscription = (id: string | undefined | null) => {
//   const [res] = useQuery({
//     query: teamSubscription,
//     variables: { id: id! },
//     pause: !id,
//   });

//   return { ...res, data: res.data?.team?.subscription };
// };

// const teamsInOrg = graphql(/* GraphQL */ `
//   query teamsInOrg($orgId: UUID!) {
//     teams(condition: { organizationId: $orgId }) {
//       nodes {
//         ...TeamBasicData
//         creator {
//           id
//           email
//           name
//         }
//         externalMembers: members(
//           filter: { organizationId: { notEqualTo: $orgId } }
//         ) {
//           totalCount
//         }
//         explicitMembers: members(
//           filter: { organizationId: { equalTo: $orgId } }
//         ) {
//           totalCount
//         }
//         invitedMembers {
//           totalCount
//         }
//       }
//     }
//   }
// `);

// export const useTeamsInOrg = (orgId: string | null | undefined) => {
//   const [res] = useQuery({
//     query: teamsInOrg,
//     variables: { orgId: orgId! },
//     pause: !orgId,
//   });

//   return { ...res, data: res.data?.teams?.nodes };
// };
