import { TypedDocumentNode } from 'urql';

export const getOperationName = (document: TypedDocumentNode) => {
  const operationDefinition = document.definitions.find(
    (d) => d.kind === 'OperationDefinition'
  );

  if (
    operationDefinition &&
    'name' in operationDefinition &&
    operationDefinition.name?.value
  ) {
    return operationDefinition.name.value;
  }
  throw new Error(
    `No operation name node found in document: ${document.definitions}`
  );
};
