// This has been downloaded from the official repo https://github.com/rsms/inter
// and setup according to the official guide https://rsms.me/inter/

import { css } from 'styled-components';

import InterVariable from './InterVariable.woff2';
import InterVariableItalic from './InterVariable-Italic.woff2';
import InterThin from './Inter-Thin.woff2';
import InterThinItalic from './Inter-ThinItalic.woff2';
import InterExtraLight from './Inter-ExtraLight.woff2';
import InterExtraLightItalic from './Inter-ExtraLightItalic.woff2';
import InterLight from './Inter-Light.woff2';
import InterLightItalic from './Inter-LightItalic.woff2';
import InterRegular from './Inter-Regular.woff2';
import InterItalic from './Inter-Italic.woff2';
import InterMedium from './Inter-Medium.woff2';
import InterMediumItalic from './Inter-MediumItalic.woff2';
import InterSemiBold from './Inter-SemiBold.woff2';
import InterSemiBoldItalic from './Inter-SemiBoldItalic.woff2';
import InterBold from './Inter-Bold.woff2';
import InterBoldItalic from './Inter-BoldItalic.woff2';
import InterExtraBold from './Inter-ExtraBold.woff2';
import InterExtraBoldItalic from './Inter-ExtraBoldItalic.woff2';
import InterBlack from './Inter-Black.woff2';
import InterBlackItalic from './Inter-BlackItalic.woff2';

export const InterFontFace = css`
  @font-face {
    font-family: InterVariable;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('${InterVariable}') format('woff2');
  }
  @font-face {
    font-family: InterVariable;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url('${InterVariableItalic}') format('woff2');
  }

  /* static fonts */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('${InterThin}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('${InterThinItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('${InterExtraLight}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('${InterExtraLightItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('${InterLight}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('${InterLightItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('${InterRegular}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('${InterItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('${InterMedium}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('${InterMediumItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('${InterSemiBold}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('${InterSemiBoldItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('${InterBold}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('${InterBoldItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('${InterExtraBold}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('${InterExtraBoldItalic}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('${InterBlack}') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('${InterBlackItalic}') format('woff2');
  }
`;
