import styled, { keyframes } from 'styled-components';

export const TRANSITION_DURATION_MS = 150;

// Cannot just use css `animation-direction: reverse;` to reverse the animation because we need to reset it to 0s, and the only way I found
// was to change the animation
export const fadeBackground = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px);
  }
`;

export const fadeBackgroundReverse = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
`;

export const Backdrop = styled.div<{ $closing?: boolean; $visible: boolean }>`
  width: 100vw; // fallback for older browsers
  width: 100dvw;
  height: 100vh; // fallback for older browsers
  height: 100dvh;
  background-color: ${(p) =>
    p.$visible ? 'rgba(0, 0, 0, 0.2)' : 'transparent'};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000000000;
  animation: ${TRANSITION_DURATION_MS}ms ease
    ${(p) =>
      !p.$visible
        ? 'none'
        : p.$closing
        ? fadeBackgroundReverse
        : fadeBackground};
  animation-fill-mode: both;
  ${(p) => p.$closing && 'pointer-events: none;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const parentGrow = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

const parentGrowReverse = keyframes`
  from {
    transform: scale(1.03);
  }
  to {
    transform: scale(1);
    // setting opacity to 0 only when exiting to make sure we have a transition from the hover to non-hover state below it
    opacity: 0;
  }
`;

export const ParentContainer = styled.div<{ $closing?: boolean }>`
  animation: ${TRANSITION_DURATION_MS}ms ease
    ${(p) => (p.$closing ? parentGrowReverse : parentGrow)};
  animation-fill-mode: both;

  position: absolute;
  pointer-events: none;
`;

// using menu-left and menu-top to prevent context menu from clipping outside of screen
const contextMenuContainerEnter = keyframes`
  from {
    opacity: 0;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      calc(min(calc(100dvh - 100% - 10px), var(--menu-top)) - 10px),
      0
    );
  }
  to {
    opacity: 1;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      min(calc(100dvh - 100% - 10px), var(--menu-top)),
      0
    );
  }
`;

const contextMenuContainerExit = keyframes`
  from {
    opacity: 1;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      min(calc(100dvh - 100% - 10px), var(--menu-top)),
      0
      );
  }
  to {
    opacity: 0;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      calc(min(calc(100dvh - 100% - 10px), var(--menu-top)) - 10px),
      0
    );
  }
`;

export const Container = styled.div<{ $closing?: boolean }>`
  min-width: 200px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.surface.e1};
  border: 1px solid ${(p) => p.theme.surface.e2};
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  z-index: 3;
  overflow: hidden;
  animation: ${TRANSITION_DURATION_MS}ms ease
    ${(p) =>
      p.$closing ? contextMenuContainerExit : contextMenuContainerEnter};
  animation-fill-mode: both;

  transform-origin: 50% 50%;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

export const ContainerHidden = styled.div`
  display: none;
  pointer-events: none;
`;
