import styled from 'styled-components';
import { VizcomLogo } from './Logo';
import { CSSProperties, useEffect, useState } from 'react';

export const VizcomLogoAnimated = styled(VizcomLogo)<{ color?: string }>`
  & path {
    stroke-linecap: round;
    animation: dash 2s linear infinite;
    stroke-dasharray: 70 70;
    stroke: ${(p) => p.color || 'white'};
    @keyframes dash {
      0% {
        stroke-dashoffset: 70;
      }
      50% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -70;
      }
    }
  }
`;

const LoadingIndicator = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10;
  pointer-events: all;
  opacity: 1;
  transition: 0.2s opacity ease;
`;

export const LoadingLogo: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <LoadingIndicator>
      <VizcomLogoAnimated style={{ height: 64, width: 64 }} color={color} />
    </LoadingIndicator>
  );
};

const LoadingContainerInset = styled.div<{
  $active: boolean;
  $fade: boolean;
  $backdropColor: string | null;
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(p) => p.$backdropColor ?? p.theme.surface.e0};
  z-index: 10;
  transition: 1s opacity ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: progress;
  animation-name: ${(p) =>
    !p.$fade ? 'none' : p.$active ? 'fade-in' : 'fade-out'};
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-easing: ease;
  pointer-events: ${(p) => (p.$active ? 'all' : 'none')};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const LoadingLogoInset = (props: {
  active?: boolean;
  style?: CSSProperties;
  fade?: boolean;
  opaqueBackdrop?: boolean;
  backdropColor?: string;
  color?: string;
}) => {
  const active = props.active ?? true;
  const fade = props.fade ?? true;
  const color = props.color ?? 'white';
  const backdropColor = props.opaqueBackdrop
    ? null
    : props.backdropColor ?? 'rgba(0,0,0,.2)';

  const [loadingMounted, setLoadingMounted] = useState(active);

  // delay unmount of loading component by 1s to allow opacity transition to finish
  useEffect(() => {
    if (active) {
      setLoadingMounted(true);
      return;
    }
    const timeout = setTimeout(() => {
      setLoadingMounted(false);
    }, 1000); // delay is a little bit longer than the css animation duration
    return () => {
      clearTimeout(timeout);
    };
  }, [active]);

  if (!active && !loadingMounted) {
    // unmounting for performance reasons: disable svg css animation
    return null;
  }

  return (
    <LoadingContainerInset
      $active={active}
      $fade={fade}
      $backdropColor={backdropColor}
    >
      <VizcomLogoAnimated
        style={{ height: 64, width: 64, ...props.style }}
        color={color}
      />
    </LoadingContainerInset>
  );
};
