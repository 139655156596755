import { base62 } from 'mudder';

export const sortByOrderKey = <T extends { orderKey: string }>(arr: T[]) => {
  return [...arr].sort((a, b) => (a.orderKey > b.orderKey ? 1 : -1));
};

export const genTopOrderKey = <T extends { orderKey: string }>(arr: T[]) => {
  if (arr.length === 0) {
    return base62.mudder()[0];
  }
  const sorted = sortByOrderKey(arr);
  return base62.mudder(undefined, sorted[0].orderKey)[0];
};

export const genBottomOrderKey = <T extends { orderKey: string }>(arr: T[]) => {
  if (arr.length === 0) {
    return base62.mudder()[0];
  }
  const sorted = sortByOrderKey(arr);
  return base62.mudder(sorted[sorted.length - 1].orderKey)[0];
};

export const genOrderKeys = (length: number) => {
  return base62.mudder(length);
};

export const getLayerOrderKey = (
  layers: { id: string; orderKey: string }[],
  anchorId: string | null = null
) => {
  let orderKey;
  const sorted = sortByOrderKey(layers);

  if (sorted.length === 0) {
    return base62.mudder()[0];
  }

  let insertIndex;
  if (anchorId === null) {
    insertIndex = 0;
  } else {
    insertIndex = sorted.findIndex((layer) => layer.id === anchorId);
  }
  if (insertIndex < 1) {
    orderKey = genTopOrderKey(sorted);
  } else if (insertIndex >= sorted.length) {
    orderKey = genBottomOrderKey(sorted);
  } else {
    const previousOrder = sorted[insertIndex].orderKey;
    const nextOrder =
      insertIndex < sorted.length
        ? sorted[insertIndex - 1].orderKey
        : undefined;

    orderKey = base62.mudder(previousOrder, nextOrder)[0];
  }

  return orderKey;
};
