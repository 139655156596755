import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

interface ToastIndicatorProps {
  text: string | JSX.Element | null;
  type?: 'loading' | 'success' | 'error' | 'default';
}

const TOAST_FN: Record<
  NonNullable<ToastIndicatorProps['type']>,
  typeof toast['error']
> = {
  error: toast.error,
  loading: toast.loading,
  success: toast.success,
  default: toast,
};

export const ToastIndicator = ({
  text,
  type = 'default',
}: ToastIndicatorProps) => {
  const toastIdRef = useRef<string>();

  useEffect(() => {
    toastIdRef.current = TOAST_FN[type](text, {
      id: toastIdRef.current,
      duration: Infinity,
    });
  }, [text, type]);

  useEffect(() => () => toast.dismiss(toastIdRef.current), []);

  return null;
};
