export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown',
}

// Gets the user's operating system based on the user agent.
export const getOS = (): OperatingSystem => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('win')) {
    return OperatingSystem.Windows;
  } else if (userAgent.includes('mac')) {
    return OperatingSystem.MacOS;
  } else if (userAgent.includes('linux')) {
    return OperatingSystem.Linux;
  } else if (userAgent.includes('android')) {
    return OperatingSystem.Android;
  } else if (userAgent.includes('ios')) {
    return OperatingSystem.iOS;
  }
  return OperatingSystem.Unknown;
};

/*
Gets the appropriate shortcut key based on the user's operating system.
`${getShortcutKey()}Z`; Returns '⌘Z' on macOS, 'CTRLZ' on other platforms
`${getShortcutKey(true)}Z`; Returns '⌘ Z' on macOS, 'CTRL+Z' on other platforms
`${getShortcutKey('-')}Z`; Returns '⌘-Z' on macOS, 'CTRL-Z' on other platforms
*/
export const getShortcutKey = (
  includeKeyComboSeparator?: boolean | string
): string => {
  const isMacOS = getOS() === OperatingSystem.MacOS;

  if (typeof includeKeyComboSeparator === 'string') {
    return isMacOS
      ? `⌘${includeKeyComboSeparator}`
      : `CTRL${includeKeyComboSeparator}`;
  }

  return isMacOS
    ? includeKeyComboSeparator
      ? '⌘ '
      : '⌘'
    : includeKeyComboSeparator
    ? 'CTRL+'
    : 'CTRL';
};

export const getAltShortcutKey = (
  includeKeyComboSeparator?: boolean | string
): string => {
  const isMacOS = getOS() === OperatingSystem.MacOS;

  if (typeof includeKeyComboSeparator === 'string') {
    return isMacOS
      ? `⌥${includeKeyComboSeparator}`
      : `Alt${includeKeyComboSeparator}`;
  }

  return isMacOS
    ? includeKeyComboSeparator
      ? '⌥ '
      : '⌥'
    : includeKeyComboSeparator
    ? 'Alt+'
    : 'Alt';
};

export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edge',
  Opera = 'Opera',
  Unknown = 'Unknown',
}

export interface IBrowserResult {
  browser: Browser;
  version: string | undefined;
}

/*
Gets information about the user's browser and version.
const result = getBrowser();
const isFirefoxVersionCompatible = result.browser === Browser.Firefox && (result.version || "0") >= "119.0.1";
*/
export const getBrowser = (): IBrowserResult => {
  const userAgent = navigator.userAgent.toLowerCase();

  let browser: Browser = Browser.Unknown;
  let version: string | undefined;

  if (userAgent.includes('chrome')) {
    browser = Browser.Chrome;
    version = extractVersion(userAgent, 'chrome/');
  } else if (userAgent.includes('firefox')) {
    browser = Browser.Firefox;
    version = extractVersion(userAgent, 'firefox/');
  } else if (userAgent.includes('safari')) {
    browser = Browser.Safari;
    version = extractVersion(userAgent, 'version/');
  } else if (userAgent.includes('edg')) {
    browser = Browser.Edge;
    version = extractVersion(userAgent, 'edg/');
  } else if (userAgent.includes('opr') || userAgent.includes('opera')) {
    browser = Browser.Opera;
    version = extractVersion(userAgent, 'opr/');
  }

  return { browser, version };
};

const extractVersion = (
  userAgent: string,
  identifier: string
): string | undefined => {
  const startIndex = userAgent.indexOf(identifier);
  if (startIndex !== -1) {
    const endIndex = userAgent.indexOf(' ', startIndex);
    const versionString =
      endIndex !== -1
        ? userAgent.substring(startIndex + identifier.length, endIndex)
        : userAgent.substring(startIndex + identifier.length);
    return versionString;
  }
  return undefined;
};
