import { z } from 'zod';

export const image3DStudioClientAuth = z.object({
  type: z.literal('auth'),
  accessToken: z.string(),
  compositeSceneId: z.string(),
});

export const image3DStudioClientInference = z.object({
  type: z.literal('inference'),
  id: z.string(),
  inputImage: z.instanceof(Uint8Array),
  prompt: z.string(),
  influence: z.number(),
  segments: z.array(
    z.object({
      material: z.instanceof(Uint8Array),
      mask: z.instanceof(Uint8Array),
    })
  ),
});

export const image3DStudioClientMessage = z.discriminatedUnion('type', [
  image3DStudioClientAuth,
  image3DStudioClientInference,
]);

export const image3DStudioServerErrorResponse = z.object({
  type: z.literal('error'),
  error: z.instanceof(Error),
});

export const image3DStudioServerAuthResponse = z.object({
  type: z.literal('authResponse'),
  id: z.string(),
});

export const image3DStudioServerInferenceResult = z.object({
  type: z.literal('inference_result'),
  id: z.string(),
  image: z.instanceof(Uint8Array),
});

export const image3DStudioServerMessage = z.discriminatedUnion('type', [
  image3DStudioServerErrorResponse,
  image3DStudioServerAuthResponse,
  image3DStudioServerInferenceResult,
]);

export type Image3DStudioMessage = z.infer<
  | typeof image3DStudioServerErrorResponse
  | typeof image3DStudioServerAuthResponse
  | typeof image3DStudioServerInferenceResult
>;
