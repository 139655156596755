import { Cache } from '@urql/exchange-graphcache';
import {
  WorkbenchContentFragment,
  WorkbenchUpdateSubscription,
  WorkbenchUpdateSubscriptionVariables,
  WorkbenchUpdateType,
} from '../gql/graphql';
import { assertUnreachable } from '../../../../js-utils/src';
import { WorkbenchElementPaletteSourceImageData } from '../fragments/workbenchFragment';

export const handleWorkbenchSubscriptionMessage = (
  res: WorkbenchUpdateSubscription,
  args: WorkbenchUpdateSubscriptionVariables,
  cache: Cache
): void => {
  if (res.workbenchUpdates.type === WorkbenchUpdateType.Delete) {
    handleDeletedElements(
      cache,
      args.input.workbenchId,
      res.workbenchUpdates.id
    );
  } else if (res.workbenchUpdates.type === WorkbenchUpdateType.Update) {
    if (res.workbenchUpdates.paletteSourceImage) {
      handlePaletteSourceImageUpdate(
        cache,
        res.workbenchUpdates.paletteSourceImage
      );
    }
    handleWorkbenchElementUpdate(
      cache,
      args.input.workbenchId,
      res.workbenchUpdates
    );
  } else {
    assertUnreachable(res.workbenchUpdates.type);
  }
};

function handlePaletteSourceImageUpdate(
  cache: Cache,
  paletteSourceImage: WorkbenchElementPaletteSourceImageData
): void {
  const sourceImagesKey = `${cache.keyOfEntity({
    __typename: 'WorkbenchElementPalette',
    id: paletteSourceImage.workbenchElementPaletteId,
  })}.sourceImages`;

  const sourceImages = cache.resolve(sourceImagesKey, 'nodes') as string[];
  const updatedElementKey = cache.keyOfEntity(paletteSourceImage)!;

  if (!sourceImages.includes(updatedElementKey)) {
    cache.link(sourceImagesKey, 'nodes', [...sourceImages, updatedElementKey]);
  }
}

const elementTypenameToWorkbenchCollectionName: Record<
  Exclude<
    keyof WorkbenchUpdateSubscription['workbenchUpdates'],
    '__typename' | 'id' | 'table' | 'type' | 'paletteSourceImage'
  >,
  keyof WorkbenchContentFragment
> = {
  drawing: 'drawings',
  img2img: 'img2imgs',
  palette: 'palettes',
  placeholder: 'placeholders',
  text: 'textElements',
  mix: 'mixElements',
  section: 'sectionElements',
  compositeScene: 'compositeScenes',
};

function handleWorkbenchElementUpdate(
  cache: Cache,
  workbenchId: string,
  update: WorkbenchUpdateSubscription['workbenchUpdates']
): void {
  const workbenchKey = cache.keyOfEntity({
    __typename: 'Workbench',
    id: workbenchId,
  });
  for (const [key, element] of Object.entries(update)) {
    if (!element || typeof element !== 'object' || !('__typename' in element)) {
      continue;
    }
    const updatedElementKey = cache.keyOfEntity(element)!;
    const collectionName =
      elementTypenameToWorkbenchCollectionName[
        key as keyof typeof elementTypenameToWorkbenchCollectionName
      ];
    const collectionKey = `${workbenchKey}.${collectionName}`;

    const existingElementsInCollection =
      (cache.resolve(collectionKey, 'nodes') as string[]) ?? [];

    if (!existingElementsInCollection.includes(updatedElementKey)) {
      cache.link(collectionKey, 'nodes', [
        ...existingElementsInCollection,
        updatedElementKey,
      ]);
    }
  }
}

function handleDeletedElements(
  cache: Cache,
  workbenchId: string,
  deletedElementId: string
): void {
  handleDeletedSourceImage(cache, deletedElementId);
  handleDeletedWorkbenchElement(cache, workbenchId, deletedElementId);
}

function handleDeletedSourceImage(
  cache: Cache,
  deletedElementId: string
): void {
  const sourceImageKey = cache.keyOfEntity({
    __typename: 'WorkbenchElementPaletteSourceImage',
    id: deletedElementId,
  });

  const paletteId = cache.resolve(
    sourceImageKey,
    'workbenchElementPaletteId'
  ) as string;
  if (!paletteId) {
    return;
  }

  const sourceImagesKey = `${cache.keyOfEntity({
    __typename: 'WorkbenchElementPalette',
    id: paletteId,
  })}.sourceImages`;

  const sourceImages = cache.resolve(sourceImagesKey, 'nodes') as string[];
  const filteredSourceImages = sourceImages?.filter(
    (key) => key !== sourceImageKey
  );
  cache.link(sourceImagesKey, 'nodes', filteredSourceImages);
}

function handleDeletedWorkbenchElement(
  cache: Cache,
  workbenchId: string,
  deletedElementId: string
) {
  const workbenchKey = cache.keyOfEntity({
    __typename: 'Workbench',
    id: workbenchId,
  });

  for (const collectionName of Object.values(
    elementTypenameToWorkbenchCollectionName
  )) {
    const collection =
      (cache.resolve(
        `${workbenchKey}.${collectionName}`,
        'nodes'
      ) as string[]) ?? [];
    const filteredCollection = collection?.filter(
      // the keys in the collection array contains the __typename of the element + its id, in this case we only want to check with the id
      (key) => deletedElementId !== key.split(':')[1]
    );
    cache.link(
      `${workbenchKey}.${collectionName}`,
      'nodes',
      filteredCollection
    );
  }
}
