import { paths } from '@vizcom/shared-utils-paths';
import styled from 'styled-components';
import { useTriggerPaywallModalOpen } from '../PaywallModal/paywallModalState';
import { SIDEBAR_WIDTH } from '../constants';
import { useSidebarOpenStateSync } from './state';
import { UpgradeBanner } from './UpgradeBanner';
import { OrganizationSwitcher } from './OrganizationSwitcher';
import { SidebarItem } from './SidebarItem';
import {
  HomeIcon,
  FileOutlineIcon,
  QuestionMarkCircleIcon,
  SettingsOutlineIcon,
  SharedHelpCenter,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { AccountSettings } from './SidebarAccountSettings';
import { OrganizationTeamSwitcher } from './OrganizationTeamSwitcher';

export const SidebarWrapper = styled.div<{ isOpen: boolean }>`
  z-index: 2;
  background-color: ${({ theme }) => theme.surface.e0};

  grid-area: sidebar;
  width: ${SIDEBAR_WIDTH}px;

  @media (max-width: 645px) {
    transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '-100%')});
    transition: 0.2s transform ease;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 16px;
  height: 100vh; // fallback for older browsers
  // Using dvh instead of vh to prevent problem on ios where the sidebar is bigger than the screen if the address bar is visible
  height: 100dvh;

  box-sizing: border-box;
  overflow-x: hidden;
`;

const LowerContainer = styled.div`
  margin-top: auto;
`;

export const Sidebar = (props: { hideTeam?: boolean }) => {
  const { data: selectedOrganization } = useSelectedOrganization();

  const triggerPaywallModalOpen = useTriggerPaywallModalOpen();

  const [isSidebarOpen, setIsSidebarOpen] = useSidebarOpenStateSync();

  const handleLinkClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <SidebarWrapper isOpen={isSidebarOpen}>
      {!props.hideTeam && (
        <>
          {selectedOrganization && (
            <OrganizationSwitcher selectedOrganization={selectedOrganization} />
          )}

          {selectedOrganization && (
            <div
              style={{
                marginTop: '54px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SidebarItem
                to={paths.files.organizationRecent(selectedOrganization.id)}
                onClick={handleLinkClick}
              >
                <HomeIcon style={{ marginRight: 12, height: 16, width: 16 }} />
                <Text type="sh1">Home</Text>
              </SidebarItem>

              <SidebarItem
                to={paths.files.organizationDraftFolder(
                  selectedOrganization.id
                )}
                onClick={handleLinkClick}
              >
                <FileOutlineIcon style={{ marginRight: 12 }} />
                <Text type="sh1">My files</Text>
              </SidebarItem>

              <SidebarItem
                to={paths.settings.organization.general(
                  selectedOrganization?.id
                )}
                onClick={handleLinkClick}
              >
                <SettingsOutlineIcon style={{ marginRight: 12 }} />
                <Text type="sh1">Settings</Text>
              </SidebarItem>
            </div>
          )}
        </>
      )}

      {!props.hideTeam && selectedOrganization && (
        <OrganizationTeamSwitcher organizationId={selectedOrganization.id} />
      )}

      <LowerContainer>
        {!props.hideTeam && (
          <UpgradeBanner triggerPaywallModalOpen={triggerPaywallModalOpen} />
        )}

        <div style={{ marginTop: 'auto' }}>
          <SharedHelpCenter
            buttonProps={{
              variant: 'outline',
              style: {
                display: 'block',
                width: '100%',
                textAlign: 'left',
                marginBottom: '16px',
              },
            }}
          >
            <Text
              type="sh1"
              style={{ display: 'flex', gap: 12, alignItems: 'center' }}
            >
              <QuestionMarkCircleIcon />
              Help &amp; feedback
            </Text>
          </SharedHelpCenter>
        </div>
        <AccountSettings />
      </LowerContainer>
    </SidebarWrapper>
  );
};
