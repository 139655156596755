import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const InferenceEventTypeZodEnum = z.enum([
  'ADD_TO_LAYER',
  'CANCEL_INFERENCE',
  'EXPORT_HISTORY_IMAGE',
  'EXPORT_IMAGE',
  'GENERATE_IMAGE',
  'SANITIZED_PROMPT',
  'IMAGE_GENERATED',
]);

export const InferenceEventName = InferenceEventTypeZodEnum.Enum;

export const InferenceTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: InferenceEventTypeZodEnum,
  data: z.object({
    customModelId: z.string().optional(),
    imageInferenceType: z.string().optional(),
    imagePaths: z.array(z.string()).optional(),
    outputsCount: z.number().optional(),
    prompt: z.string().optional(),
    sanitizedPrompt: z.string().optional(),
    promptId: z.string().optional(),
    publicPaletteId: z.string().optional(),
    source: z.enum(['WORKBENCH', 'STUDIO']).optional(),
    sourceImageInfluence: z.number().optional(),
    styleReferenceEnabled: z.boolean().optional(),
    workbenchPaletteId: z.string().optional(),
    paletteInfluence: z.number().optional(),
    sourceImagePath: z.string().optional(),
    styleReferenceImagePath: z.string().optional(),
  }),
});
