import { useEffect, useState } from 'react';
import { paths } from '@vizcom/shared-utils-paths';

const ACCESS_TOKEN_KEY = 'access_token';

const accessTokenEventListeners = new Set<() => any>();
const triggerAccessTokenChangeEvent = () => {
  // Emiting the event out of the event loop to prevent race-conditions with the login mutation not having finished yet
  setTimeout(() => {
    accessTokenEventListeners.forEach((handler) => handler());
  }, 0);
};

export const setAccessToken = (accessToken: string) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  triggerAccessTokenChangeEvent();
};

export const removeAccessToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  // directly redirect to login page after login to not trigger the LoggedInAuthGuard which would save the current page URL in session storage
  // Reloading the page is also done to make sure we're not leaving anything in a cache somewhere in our code
  window.location.href = paths.auth.home();
};

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

// Used to rerender when the access token status changes
export const useHasAccessToken = () => {
  const [hasAccessToken, setHasAccessToken] = useState(!!getAccessToken());

  useEffect(() => {
    const handler = () => {
      setHasAccessToken(!!getAccessToken());
    };

    // synchronize this hook between all pages
    window.addEventListener('storage', handler);
    accessTokenEventListeners.add(handler);
    return () => {
      window.removeEventListener('storage', handler);
      accessTokenEventListeners.delete(handler);
    };
  }, []);

  return hasAccessToken;
};

export function getHashParam(key: string): string | null {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);

  return params.get(key);
}

export const getSharingSecret = () => getHashParam('sharing_secret');
