import styled from 'styled-components';
import { Button } from '../Button';
import { DotsPagination } from './DotsPagination';
import { useMultiStepsModalContext } from '@vizcom/shared-ui-components';
import { AutoplayVideo } from '../Video/AutoplayVideo';

export const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 400px;
  height: 450px;
`;

export const LeftPanelContainer = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
`;

export const LeftPanelBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 30px;
`;

export const RightPanelContainer = styled.div`
  grid-column: 2;
  overflow: hidden;
`;

export const PanelTitle = styled.h1`
  color: #f9f9fa;
  text-wrap: 'pretty';
  font-family: Inter;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const PanelDescription = styled.p`
  color: #98989b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  a {
    text-decoration: underline;
  }
`;

type SplitedModalViewPros = {
  title: string;
  description: string;
  position: number;
  image?: string;
  video?: string;
  children?: React.ReactNode;
};

export const SplitedModalView = (props: SplitedModalViewPros) => {
  const { changeStep } = useMultiStepsModalContext();
  const { title, description, image, video, position, children } = props;

  return (
    <SplitContainer>
      {/* LEFT */}
      <LeftPanelContainer>
        <LeftPanelBody>
          <PanelTitle>{title}</PanelTitle>
          <PanelDescription>{description}</PanelDescription>
          {changeStep && (
            <Button
              style={{ width: '100%' }}
              onClick={() => changeStep(position + 1)}
            >
              Continue
            </Button>
          )}
        </LeftPanelBody>

        {changeStep && (
          <DotsPagination
            activeIndex={position}
            changeStep={changeStep}
            stepsCount={4}
          />
        )}
      </LeftPanelContainer>

      {/* RIGHT */}
      <RightPanelContainer>
        {image && <FullImageBackgroundImage image={image} />}
        {video && <FullVideoBackground video={video} />}
        {children}
      </RightPanelContainer>
    </SplitContainer>
  );
};

export const FullImageBackgroundImage = ({ image }: { image: string }) => (
  <div
    style={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100%',
      width: '100%',
    }}
  ></div>
);

export const FullVideoBackground = ({ video }: { video: string }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <AutoplayVideo src={video} style={{ maxHeight: 450 }} />
  </div>
);
