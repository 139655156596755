import { graphql } from '../../gql';

export const CreateCompositeSceneMutation = graphql(/* GraphQL */ `
  mutation CreateCompositeScene($input: CreateCompositeSceneInput!) {
    createCompositeScene(input: $input) {
      compositeScene {
        ...CompositeSceneData
      }
    }
  }
`);

export const UpdateCompositeSceneMutation = graphql(/* GraphQL */ `
  mutation UpdateCompositeScene($input: UpdateCompositeSceneInput!) {
    updateCompositeScene(input: $input) {
      compositeScene {
        ...CompositeSceneData
        cameraPositionX
        cameraPositionY
        cameraPositionZ
        cameraTargetX
        cameraTargetY
        cameraTargetZ
        cameraFov
        sceneEnvironmentMapUrl
        sceneEnvironmentAngle
        sceneEnvironmentGroundPlane
        prompt
        influence
        renderStyle
      }
    }
  }
`);

export const DeleteCompositeSceneMutation = graphql(/* GraphQL */ `
  mutation DeleteCompositeScene($input: DeleteCompositeSceneInput!) {
    deleteCompositeScene(input: $input) {
      deletedCompositeSceneNodeId
    }
  }
`);
