import { useMutation } from 'urql';
import { graphql } from '../gql';

const NextLoginStepMutation = graphql(/* GraphQL */ `
  mutation nextLoginStep($input: NextLoginStepInput!) {
    nextLoginStep(input: $input) {
      email
      hasAccount
      redirectUrl
    }
  }
`);

export const useNextLoginStep = () => useMutation(NextLoginStepMutation);
