import { useMutation } from 'urql';
import { graphql } from '../gql';

const DeleteWorkbenchesAndFoldersMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchesAndFolders(
    $deleteWorkbenchesInput: DeleteWorkbenchesInput!
    $deleteFoldersInput: DeleteFoldersInput!
  ) {
    deleteWorkbenches(input: $deleteWorkbenchesInput) {
      status
    }
    deleteFolders(input: $deleteFoldersInput) {
      status
    }
  }
`);

export const useDeleteWorkbenchesAndFolders = () =>
  useMutation(DeleteWorkbenchesAndFoldersMutation);
