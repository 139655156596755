import React from 'react';
import styled, { CSSProperties, css } from 'styled-components';
import { fadeIn } from '../../animations';
import { ARROW_SIZE } from '../RichTooltip/RichTooltip';

export const TooltipTarget = styled.div`
  border: none;
  background: inherit;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
  cursor: inherit;
  display: flex;
`;

export const CenterContainer = styled.div<{
  position?: 'bottom' | 'left' | 'right' | 'top';
}>`
  position: absolute;
  width: 200px;
  margin-left: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;

  ${({ position }) => {
    switch (position) {
      case 'bottom':
        return css`
          bottom: unset !important;
          top: calc(100% + 5px);
        `;
      case 'left':
        return css`
          margin-right: 0;
          width: 100%;
          left: unset;
          top: 50%;
          right: calc(100% + 5px);
          width: max-content;
        `;
      case 'right':
        return css`
          margin-left: 0;
          width: 100%;
          top: 50%;
          left: calc(100% + 5px);
          width: max-content;
        `;
      case 'top':
        return css`
          top: unset !important;
          bottom: calc(100% + 5px);
        `;

      default:
        return css`
          bottom: calc(100% + 5px);
        `;
    }
  }}
`;

export const TooltipBox = styled.span<{
  position?: 'bottom' | 'left' | 'right' | 'top';
}>`
  position: relative;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px 8px;
  font-size: 0.75rem;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 0.5s opacity ease;

  ${({ position }) => {
    switch (position) {
      case 'right':
        return css`
          color: #fff;
        `;
      default:
        return css``;
    }
  }}
  /* animation: ${fadeIn} 1s linear; */
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 1px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 1);
    left: calc(50% - 4.5px);
    top: 100%;
    z-index: -10;
  }
  ${({ position }) => {
    switch (position) {
      case 'bottom':
        return css`
          top: ${ARROW_SIZE}px;

          &:after {
            border-color: transparent;
            top: unset;
            width: 1px;
            bottom: 100%;
            left: calc(50% - 5px);
          }
        `;
      case 'left':
        return css`
          right: ${ARROW_SIZE}px;

          &:after {
            border-color: transparent;
            left: 100%;
            top: calc(50% - 5px);
          }
        `;
      case 'right':
        return css`
          left: ${ARROW_SIZE}px;

          &:after {
            border-color: transparent;
            right: 100%;
            left: unset;
            top: calc(50% - 5px);
          }
        `;
      case 'top':
        return css`
          bottom: ${ARROW_SIZE}px;

          &:after {
            border-color: transparent;
            top: unset;
            bottom: 100%;
            left: calc(50% - 5px);
          }
        `;

      default:
        return css``;
    }
  }}
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;

  ${TooltipBox} {
    opacity: 0;
  }
  &:hover > ${CenterContainer} > ${TooltipBox} {
    opacity: 1;
  }
`;

export interface TooltipProps {
  children: React.ReactNode | React.ReactNode[];
  tip: React.ReactNode | React.ReactNode[];
  position?: 'bottom' | 'left' | 'right' | 'top';
  style?: CSSProperties;
}

export const Tooltip = (props: TooltipProps) => {
  const { position = 'bottom', children, tip, style } = props;

  return (
    <TooltipWrapper style={style}>
      <TooltipTarget>{children}</TooltipTarget>
      <CenterContainer position={position}>
        <TooltipBox position={position}>{tip}</TooltipBox>
      </CenterContainer>
    </TooltipWrapper>
  );
};
