import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const Inference3dEventTypeZodEnum = z.enum(['GENERATE_MESH_FROM_DRAWING']);

export const Inference3dEventName = Inference3dEventTypeZodEnum.Enum;

export const Inference3dTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: Inference3dEventTypeZodEnum,
  data: z.object({
    source: z.enum(['STUDIO', 'WORKBENCH']),
    qualityType: z.enum(['BASIC', 'HIGH']),
    drawingId: z.string(),
  }),
});
