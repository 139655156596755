import { useMutation } from 'urql';
import { graphql } from '../gql';

const CreateCustomModelMutation = graphql(/* GraphQL */ `
  mutation CreateCustomModel($input: CreateCustomModelInput!) {
    createCustomModel(input: $input) {
      query {
        customModels {
          nodes {
            ...CustomModelFullData
          }
        }
      }
    }
  }
`);

export const useCreateCustomModel = () =>
  useMutation(CreateCustomModelMutation);

const DeleteCustomModelMutation = graphql(/* GraphQL */ `
  mutation DeleteCustomModel($input: DeleteCustomModelInput!) {
    deleteCustomModel(input: $input) {
      query {
        customModels {
          nodes {
            ...CustomModelFullData
          }
        }
      }
    }
  }
`);

export const useDeleteCustomModel = () =>
  useMutation(DeleteCustomModelMutation);
