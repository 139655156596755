import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const UserAuthEventTypeZodEnum = z.enum([
  'RESET_PASSWORD',
  'USER_LOGGED_IN',
  'USER_REGISTERED',
]);

export const UserAuthEventName = UserAuthEventTypeZodEnum.Enum;

export const UserTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: UserAuthEventTypeZodEnum,
  data: z.object({
    method: z.enum(['google', 'email']),
    email: z.string().optional(),
  }),
});
