import { Crisp } from 'crisp-sdk-web';
import { useMemo } from 'react';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';
import { useEffect } from 'react';
import { ChatboxPosition } from 'crisp-sdk-web';

let isConfigured = false;

export const useCrisp = () => {
  if (!isConfigured) {
    if (import.meta.env['NX_CRISP_API_KEY']) {
      Crisp.configure(import.meta.env['NX_CRISP_API_KEY'], {
        autoload: false,
      });
      Crisp.chat.onChatClosed(() => {
        Crisp.chat.hide();
      });
    }
    isConfigured = true;
  }

  const { data: user } = useCurrentUser();
  useEffect(() => {
    if (user?.email) {
      if (user.crispIdentityToken) {
        Crisp.setTokenId(user.crispIdentityToken);
        Crisp.session.reset();
      }
      Crisp.user.setEmail(user.email, user.crispIdentityToken || undefined);
      Crisp.session.setData({
        id: user.id,
      });
    } else {
      Crisp.session.reset();
    }
  }, [user?.id, user?.email, user?.crispIdentityToken]);

  return useMemo(
    () => ({
      openChat: () => {
        if (!isConfigured) {
          console.error(
            'Crips is not configured, add NX_CRISP_API_KEY env variable to enable it'
          );
          return;
        }
        Crisp.setPosition(ChatboxPosition.Left);
        Crisp.chat.open();
        Crisp.chat.show();
      },
    }),
    []
  );
};
