import styled from 'styled-components';
import { Modal } from '../Modal';
import {
  ModalHeader,
  ModalDivider,
  ModalCloseButton,
  ModalContent,
} from '../Modal/ModalItems';
import { Text } from '../Text/Text';
import { LoadingLogoInset } from '../Logo';
import { Markdown } from '../Markdown/Markdown';
import { ChangelogData } from '@vizcom/shared/data-access/graphql';
import {
  FormattedDate,
  VizcomLogoFilled,
  VizcomText,
} from '@vizcom/shared-ui-components';

export const ChangelogModal = (props: {
  changelogs: ChangelogData[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { changelogs } = props;

  if (!props.isOpen) {
    return null;
  }

  if (!props.changelogs) {
    return (
      <Modal
        isOpen={props.isOpen}
        setIsOpen={() => {}}
        style={{
          width: 600,
        }}
      >
        <ModalContent>
          <LoadingLogoInset />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={props.onClose}
      style={{
        width: 380,
        maxHeight: 536,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <ModalHeader>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 24,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <VizcomLogoFilled height={12} width={12} />
              <VizcomText
                style={{ padding: '0px 0px 0px 6px' }}
                height={9}
                width={'100%'}
              />
            </div>
            <ModalCloseButton />
          </div>
          <Text type="h1">🚀 Changelog</Text>
          <Text block color="info" type="b1" style={{ marginTop: 16 }}>
            Everything you've missed since your last visit.
          </Text>
        </div>
      </ModalHeader>
      <ModalDivider />
      <ModalContent>
        {changelogs.map((changelog, index) => (
          <div key={changelog.id}>
            <div style={{ marginBottom: 16 }}>
              <Text type="h2" block style={{ marginBottom: 8 }}>
                {changelog.title}
              </Text>
              <Text type="sh2">
                <FormattedDate date={changelog.publishedAt!} />
              </Text>
              {index == 0 && (
                <NewLabel>
                  <Text color="primary" type="button3">
                    New
                  </Text>
                </NewLabel>
              )}
            </div>
            <Markdown>{changelog.content}</Markdown>

            {index !== changelogs.length - 1 && (
              <ModalDivider style={{ margin: '20px 0px' }} />
            )}
          </div>
        ))}
      </ModalContent>
    </Modal>
  );
};

const NewLabel = styled.div`
  margin-top: 8px;
  border-radius: 4px;
`;
