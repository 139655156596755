import { useCallback } from 'react';
import { useLastValue } from './useLastValue';

// Return a function that always calls the last seen value of the callback
// this is useful as a replacement to useCallback
export function useStableCallback<TParams extends any[], TResult>(
  callback: (...args: TParams) => TResult
): (...args: TParams) => TResult {
  const lastValue = useLastValue(callback);

  return useCallback(
    (...args: TParams) => {
      return lastValue.current(...args);
    },
    [lastValue]
  );
}
