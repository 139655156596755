import { graphql } from '../gql';
import { ResultOf } from '@graphql-typed-document-node/core';

export const DrawingBasicDataFragment = graphql(/* GraphQL */ `
  fragment DrawingBasicData on Drawing {
    id
    height
    layersOrder
    createdAt
    thumbnailPath
    updatedAt
    width
    creatorId
    workbenchId
    isEditable
    initiated
    name
    workbench {
      id
      name
      folderId
      team {
        id
        organization {
          id
          name
        }
      }
    }
  }
`);

export type DrawingBasicData = ResultOf<typeof DrawingBasicDataFragment>;

export const LayerDataFragment = graphql(/* GraphQL */ `
  fragment LayerData on Layer {
    blendMode
    fill
    id
    name
    opacity
    visible
    updatedAt
    createdAt
    metadata3D
    imagePath
    meshPath
    orderKey
    drawingId
  }
`);

export const DrawingDataFragment = graphql(/* GraphQL */ `
  fragment DrawingData on Drawing {
    ...DrawingBasicData
    colors
    layers {
      nodes {
        ...LayerData
      }
    }
    # only get first prompt in the drawing data fragment to populate the "create" tab with the latest used prompt settings
    prompts(orderBy: CREATED_AT_DESC, first: 1) {
      nodes {
        ...PromptData
      }
    }
    workbench {
      id
      ...WorkbenchStyleReferences
      folder {
        id
        organization {
          id
          subscriptionPlan
        }
        rootFolder {
          id
        }
      }
    }
    workbenchSizeRatio
    x
    y
  }
`);

export type Drawing = ResultOf<typeof DrawingDataFragment>;

export const WorkbenchStyleReferencesFragment = graphql(/* GraphQL */ `
  fragment WorkbenchStyleReferences on Workbench {
    id
    styleReferences(orderBy: CREATED_AT_DESC) {
      nodes {
        ...StyleReferenceData
      }
    }
  }
`);

export const DrawingPromptsFragment = graphql(/* GraphQL */ `
  fragment DrawingPrompts on Drawing {
    id
    prompts(orderBy: CREATED_AT_DESC) {
      nodes {
        ...PromptData
      }
    }
  }
`);

export const PromptOutputData = graphql(/* GraphQL */ `
  fragment PromptOutputData on PromptOutput {
    id
    imagePath
    upscaledImagePath
    failureReason
    createdAt
    promptId
  }
`);

export type PromptOutput = ResultOf<typeof PromptOutputData>;

export const PromptDataFragment = graphql(/* GraphQL */ `
  fragment PromptData on Prompt {
    id
    createdAt
    drawingId
    imageInferenceType
    legacyType

    publicPaletteId
    customModelId
    workbenchPaletteId

    text
    sourceImageInfluence
    paletteInfluence

    maskImagePath
    sourceImagePath
    styleReferenceImagePath

    outputs {
      nodes {
        ...PromptOutputData
      }
    }
  }
`);

export type PromptData = ResultOf<typeof PromptDataFragment>;

export const StyleReferenceData = graphql(/* GraphQL */ `
  fragment StyleReferenceData on StyleReference {
    id
    workbenchId
    imagePath
    imageName
  }
`);

export const UpscaleData = graphql(/* GraphQL */ `
  fragment UpscaleData on Upscale {
    id
    drawingId
    sourceImagePath
    upscaledImagePath
    failureReason
  }
`);
