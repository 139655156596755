import LogoImageBase from './vizcom_logo.svg?react';
import LogoFilledImageBase from './vizcom_logo_filled.svg?react';
import TextImageBase from './vizcom_text.svg?react';
import VizcomLogoAsText from './vizcom_logo.svg?raw';

export const VizcomText = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <TextImageBase
      {...props}
      style={{
        maxWidth: '100%',
        height: 'auto',
        padding: 10,
        boxSizing: 'border-box',
        ...props.style,
      }}
    />
  );
};

export const VizcomLogo = LogoImageBase;
export const VizcomLogoFilled = LogoFilledImageBase;
export const VizcomLogoSvgAsText = VizcomLogoAsText;
