import styled from 'styled-components';
import { useStringHashNumber } from '../hooks/useStringHashNumber';

interface UserAvatarProps {
  userId: string;
  name: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
}

const avatarColors = [
  '#B85EFF', // light purple
  '#FF3E8D', // ping
  '#FFA113', // orange
  '#FF724A', // sunset
  '#81BB37', // lime green
  '#37B4EA', // light blue
  '#6F33AB', // dark purple
  '#00A3AE', // teal
  '#937047', // brown
  '#D54214', // red
];

export function getColorFromHash(hash: number = 0) {
  return avatarColors[hash % avatarColors.length];
}

const UserAvatarContainer = styled.div<{
  color: string;
  $userHash: number;
  $size: UserAvatarProps['size'];
}>`
  border-radius: 100%;
  height: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  width: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.surface.e0};
  background-color: ${(p) => p.color || getColorFromHash(p.$userHash)};
  text-transform: uppercase;
`;

export const UserAvatar = (props: UserAvatarProps) => {
  const hash = useStringHashNumber(props.userId);

  return (
    <UserAvatarContainer
      color={props.color!}
      $size={props.size ?? 'medium'}
      $userHash={hash || 0}
    >
      {props.name[0]}
    </UserAvatarContainer>
  );
};
