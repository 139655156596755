import { useMutation } from 'urql';
import { graphql } from '../../gql';

const GenerateSignupLink = graphql(/* GraphQL */ `
  mutation AdminGenerateSignupLink($input: AdminGenerateSignupLinkInput!) {
    adminGenerateSignupLink(input: $input) {
      link
    }
  }
`);

export const useGenerateSignupLink = () => {
  return useMutation(GenerateSignupLink);
};
