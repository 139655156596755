import styled from 'styled-components';
import { DefaultTheme } from 'styled-components';

const TextFontType: Record<
  string,
  {
    fontWeight: number;
    fontSize: number;
    lineHeight: number;
  }
> = {
  t1: {
    fontWeight: 600,
    fontSize: 32,
    lineHeight: 39,
  },
  h1: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 17,
  },
  h2: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 16,
  },
  sh1: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 14,
  },
  sh2: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 12,
  },
  b1: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 15,
  },
  b2: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 12,
  },
  b3: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 15,
  },
  button1: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 14,
  },
  button2: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 12,
  },
  button3: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: 10,
  },
} as const;

interface TextProps {
  children: React.ReactNode;
  type?: keyof typeof TextFontType;
  color?: keyof DefaultTheme['text'] | 'inherit';
  block?: boolean;
  ellipsis?: boolean;
}

export const Text = styled.span<TextProps>`
  ${(p) => p.block && 'display: block;'}
  font-weight: ${({ type }) => TextFontType[type || 'b1'].fontWeight};
  font-size: ${({ type }) => TextFontType[type || 'b1'].fontSize}px;
  line-height: ${({ type }) => TextFontType[type || 'b1'].lineHeight}px;
  color: ${({ theme, color }) =>
    color === 'inherit' ? 'inherit' : theme.text[color || 'default']};
  ${({ ellipsis }) =>
    ellipsis ? 'overflow: hidden; text-overflow: ellipsis;' : ''}
`;
