import { ComponentProps, PropsWithChildren } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { LoadingLogoInset } from './Logo/LoadingLogo';

const SurfaceContainer = styled.div<{ color?: keyof DefaultTheme['surface'] }>`
  background-color: ${(p) => p.theme.surface[p.color || 'e1']};
  padding: 16px;
  border-radius: ${(p) => p.theme.borderRadius.default};
  position: relative;
`;

export const Surface = (
  props: PropsWithChildren<
    ComponentProps<typeof SurfaceContainer> & { loading?: boolean }
  >
) => {
  const { children, loading, ...surfaceProps } = props;

  return (
    <SurfaceContainer {...surfaceProps}>
      <LoadingLogoInset active={loading ?? false} />
      {children}
    </SurfaceContainer>
  );
};
