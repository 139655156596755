import styled from 'styled-components';

import { Text } from '../Text/Text';

const Thumbnail = styled.img`
  max-width: 56px;
  max-height: 56px;
  margin-right: 12px;
  border-radius: 5px;
`;

const InferenceStyleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const InferenceStyleOption = (props: {
  option: {
    name: string;
    value: string;
    desc?: string | null;
    thumbnailPath?: string | null;
  };
}) => {
  const { name, desc, thumbnailPath } = props.option;

  return (
    <InferenceStyleContainer>
      {thumbnailPath && <Thumbnail alt={name} src={thumbnailPath} />}
      <TextContainer>
        <Text block>{name}</Text>
        {desc && (
          <Text block style={{ marginTop: 8, fontSize: 10 }} color="secondary">
            {desc}
          </Text>
        )}
      </TextContainer>
    </InferenceStyleContainer>
  );
};
