import { Text } from '../Text/Text';
import { Button } from '../Button';
import { Surface } from '../Surface';
import { SparklesIcon } from '../icons';
import { FC } from 'react';
import { useSelectedOrganization } from '../hooks/useSelectedOrganization';
import {
  OrganizationSubscriptionPlan,
  TeamRole,
} from '@vizcom/shared/data-access/graphql';

interface UpgradeBannerProps {
  triggerPaywallModalOpen: () => void;
}

export const UpgradeBanner: FC<UpgradeBannerProps> = ({
  triggerPaywallModalOpen,
}) => {
  const { data: selectedOrganization } = useSelectedOrganization();

  if (
    !selectedOrganization ||
    selectedOrganization.subscriptionPlan !==
      OrganizationSubscriptionPlan.Free ||
    selectedOrganization.currentUserRole !== TeamRole.Admin
  ) {
    return null;
  }

  return (
    <Surface
      style={{
        marginTop: 'auto',
        marginBottom: 24,
        background: 'rgba(76, 76, 239, 0.10)',
        border: '1px solid rgba(86, 86, 253, 0.41)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SparklesIcon style={{ marginBottom: 4 }} />
      <Text type="button3" style={{ marginBottom: 16 }}>
        Unlock advanced features
      </Text>

      <Button
        style={{ width: '100%', padding: '12px 0' }}
        onClick={triggerPaywallModalOpen}
      >
        <Text
          color="default"
          type="button3"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 8px',
          }}
        >
          Subscribe
        </Text>
      </Button>
    </Surface>
  );
};
