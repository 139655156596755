import { useMutation } from 'urql';
import { graphql } from '../gql';

export const DeleteDrawingMutation = graphql(/* GraphQL */ `
  mutation DeleteDrawing($id: UUID!) {
    deleteDrawing(input: { id: $id }) {
      deletedDrawingNodeId
    }
  }
`);

export const useDeleteDrawing = () => useMutation(DeleteDrawingMutation);
