import styled from 'styled-components';
import {
  LockIcon,
  TickIcon,
  LoaderIcon as LoaderIconBase,
  styledScrollbarDark,
} from '@vizcom/shared-ui-components';

export const ChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem 0;
`;

export const Choice = styled.button<{ $selected: boolean }>`
  position: relative;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.primary.default : theme.secondary.default};
  border-radius: 0.375rem;
  color: ${({ $selected, theme }) =>
    $selected ? theme.text.default : theme.text.info};
  cursor: ${({ $selected }) => ($selected ? 'default' : 'pointer')};
`;

export const Locked = styled(LockIcon)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: ${({ theme }) => theme.secondary.disabled};
  color: ${({ theme }) => theme.text.info};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.375rem;
`;

export const Selected = styled(TickIcon)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: ${({ theme }) => theme.primary.default};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.375rem;
`;

export const Divider = styled.div`
  width: 100%;
  min-height: 1px;
  height: 1px;
  background: ${({ theme }) => theme.surface.e2};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px); // Remove the height of the header
  width: 100%;
`;

export const ScrollableContent = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 65px; // The size of the footer + some padding
  ${styledScrollbarDark}
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.surface.e0};
  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 12px;
  color: #f9f9fa;

  > div {
    margin-left: auto;
    font-weight: 400;
  }
`;

export const HeaderSecondary = styled.div`
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.text.info};
`;

export const HoverableHeaderSecondary = styled(HeaderSecondary)`
  &:hover {
    color: ${({ theme }) => theme.text.default};
  }
`;

export const TextArea = styled.textarea<{
  $variant?: 'primary' | 'secondary' | 'tertiary';
}>`
  resize: none;
  margin: 0;
  width: 100%;
  height: 90px;
  padding: 1rem 1rem 0.5rem;
  background: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  line-height: 1rem;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  &:focus {
    border-color: ${({ theme, $variant }) =>
      theme[$variant || 'primary'].default};
  }
  &::placeholder {
    color: ${({ theme }) => theme.text.info};
  }
`;

export const SidebarHeader = styled.div`
  padding: 17px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text.default};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.surface.e2};
`;

export const SidebarHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Generate = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 101;

  padding: 10px 14px 16px;
  &,
  > button {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
`;

export const Outputs = styled.div<{ $hasOutputs: boolean }>`
  display: inline-flex;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.default};
  gap: 0.5rem;
  border-radius: 0.75rem;
  max-width: ${({ $hasOutputs }) => ($hasOutputs ? '305px' : '0px')};
  position: ${({ $hasOutputs }) => ($hasOutputs ? 'relative' : 'absolute')};
  transition: max-width 0.5s ease;
`;

export const Output = styled.div<{
  $aspect: number;
  $current: boolean;
  $blur?: boolean;
}>`
  background: ${({ theme }) => theme.surface.e1};
  cursor: pointer;
  position: relative;
  height: 38px;
  min-width: 38px;
  max-width: 64px;
  padding: 2px;
  align-items: center;
  display: flex;
  border-radius: 2.781px;
  overflow: hidden;
  box-shadow: 0 0 0 2px
    ${({ $current, theme }) =>
      $current ? `${theme.primary.default}` : 'transparent'};
  transition: box-shadow 0.2s ease;
  user-select: none;

  &,
  > img {
    aspect-ratio: ${({ $aspect }) => $aspect};
    filter: ${({ $blur }) => ($blur ? 'blur(6px)' : 'none')};
  }
  > img {
    border-radius: 2.781px;
    pointer-events: none;
    object-fit: contain;
    width: 100%;
    max-width: 60px;
    max-height: 34px;
  }
`;

export const Actions = styled.div`
  display: flex;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.default};
  padding: 8px;
  border-radius: 0.625rem;
  height: 48px;
  align-items: center;
  gap: 8px;
`;

export const Group = styled.div`
  display: flex;
  padding-right: 0.25rem;
  border-right: 1px solid ${({ theme }) => theme.surface.e2};
`;

export const Button = styled.button<{ $primary?: boolean; $tiny?: boolean }>`
  border: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  background: ${({ $primary, theme }) =>
    $primary ? theme.primary.default : theme.surface.e0};
  color: ${({ theme }) => theme.text.default};
  border-radius: 0.375rem;
  width: ${({ $tiny }) => ($tiny ? '2rem' : '3.6875rem')};
  height: 1.875rem;
  font-size: 0.625rem;
  font-weight: 600;
  &:disabled {
    background: ${({ $primary, theme }) =>
      $primary ? theme.primary.disabled : theme.surface.e0};
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
    transform: none;
  }
`;

export const EnhanceDetailSliderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.white};
  > * {
    width: calc(100% - 50px);
  }
`;

export const HorizontalDivider = styled.div`
  width: 1px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100000000;
`;

export const LoaderIcon = styled(LoaderIconBase)`
  color: ${({ theme }) => theme.text.default};
  width: 5rem;
  height: 5rem;
`;

export const Hotkey = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.text.info};
`;

export const PaletteSelection = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 6px;
  border-radius: 8px;
  background: ${({ theme }) => theme.surface.e1};
  cursor: pointer;
  overflow: hidden;
  height: 32px;
  width: 155px;

  &:hover {
    background: ${({ theme }) => theme.surface.e2};
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  > span {
    font-size: 12px;
    color: ${({ theme }) => theme.text.default};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
  }
`;

export const AutoPrompt = styled.div`
  cursor: pointer;
  color: #7c7cf3;

  &:hover {
    color: #dadafc;
  }
`;
