import { useMutation } from 'urql';
import { graphql } from '../gql';

const UpdateUser = graphql(/* GraphQL */ `
  mutation UpdateUser($id: String!, $patch: UserPatch!) {
    updateUser(input: { id: $id, patch: $patch }) {
      user {
        id
        name
        occupation
        description
        lastChangelogSeenAt
      }
    }
  }
`);

export const useUpdateUser = () => {
  return useMutation(UpdateUser);
};
