import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const StudioEventTypeZodEnum = z.enum(['EXPORT', 'LAYOUT_CHANGED']);

export const StudioEventName = StudioEventTypeZodEnum.Enum;
export const StudioTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: StudioEventTypeZodEnum,
  data: z.object({
    exportType: z.enum(['3D', 'PNG', 'VIDEO', 'PSD']).optional(),
    layout: z.string().optional(),
  }),
});
