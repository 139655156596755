import { keyframes } from 'styled-components';

export const slideInFromTop = keyframes`
from {
  transform: translateY(calc(-100% - 1rem));
}
to {
  transform: translateY(0);
}
`;

export const slideInFromLeft = keyframes`
  from {
    transform: translateX(calc(-100% - 1rem));
  }
  to {
    transform: translateX(0);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
