import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1em;
  text-align: center;
`;

export const CenterContent = (props: { children: ReactNode[] | ReactNode }) => {
  return <Container>{props.children}</Container>;
};
