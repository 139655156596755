import styled from 'styled-components';
import { Modal } from '../Modal';
import { ModalCloseButton } from '../Modal/ModalItems';
import {
  OrganizationSubscriptionPlan,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  FullPageDarkLoader,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { OrganizationPlansTable } from '../OrganizationPlansTable';
import { Text } from '../Text/Text';
import { capitalize } from '@vizcom/shared/js-utils';

const Link = styled.a`
  color: #7070f2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const UnderlinedLink = styled.a`
  color: ${({ theme }) => theme.text.info};
  text-decoration: underline;
`;

type PaywallModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PaywallModal = (
  props: PaywallModalProps & {
    orgId?: string;
  }
) => {
  const { data: organization, fetching: loading } = useOrganization(
    props.orgId
  );

  if (loading) {
    return <FullPageDarkLoader />;
  }

  return <ModalImpl {...props} organization={organization} />;
};

export const RecoilPaywallModal = (props: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: organization, loading } = useSelectedOrganization();

  if (loading) {
    return <FullPageDarkLoader />;
  }

  return <ModalImpl {...props} organization={organization} />;
};

const ModalImpl = ({
  organization,
  isOpen,
  onClose,
}: PaywallModalProps & {
  organization: ReturnType<typeof useSelectedOrganization>['data'];
}) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => onClose()}
      style={{
        padding: '32px 28px',
        borderRadius: 16,
        overflow: 'hidden',
        maxWidth: 880,
        width: '90%',
      }}
    >
      <ModalCloseButton style={{ position: 'absolute', right: 28, top: 28 }} />
      {!organization ? (
        <Text block style={{ textAlign: 'center', padding: '60px 20px' }}>
          There was an error. Please refresh the page and retry.
        </Text>
      ) : (
        <>
          <ModalHeader>
            <Text type="t1" block style={{ marginBottom: '8px' }}>
              Plans
            </Text>
            <Text type="sh1" color="info">
              You are currently on the{' '}
              <strong>
                {organization.subscriptionPlan ===
                OrganizationSubscriptionPlan.Free
                  ? 'Starter'
                  : capitalize(organization.subscriptionPlan)}
              </strong>{' '}
              plan. To access advanced features, upgrade now.
            </Text>
          </ModalHeader>
          <PlansTableContainer>
            <OrganizationPlansTable
              currentPlan={organization.subscriptionPlan}
              organizationId={organization.id}
              organizationPoc={organization.poc}
              onClose={onClose}
            />
          </PlansTableContainer>
          <BottomSection>
            <Text>
              Need to speak to our sales team?{' '}
              <StyledLink target="_blank" href="https://www.vizcom.ai/contact">
                Please reach out.
              </StyledLink>
            </Text>
            <UnderlinedLink
              target="_blank"
              href="https://www.vizcom.ai/pricing"
            >
              View all features
            </UnderlinedLink>
          </BottomSection>
        </>
      )}
    </Modal>
  );
};

const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const PlansTableContainer = styled.div`
  margin-bottom: 24px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.primary.default};
  &:hover {
    text-decoration: underline;
  }
`;
