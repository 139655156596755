import styled, { DefaultTheme } from 'styled-components';

export const ContextMenuItem = styled.button<{
  color?: keyof DefaultTheme['text'];
}>`
  color: ${(p) => p.theme.text[p.color ?? 'default']};
  background-color: transparent;
  outline: none;
  display: flex;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: 0.1s all ease;
  :hover {
    background-color: ${(p) => p.theme.surface.e2};
  }
  :focus {
    background-color: ${(p) => p.theme.surface.e2};
  }
  :disabled {
    color: ${(p) => p.theme.text.secondary};
    :hover {
      background-color: transparent;
    }
  }
  padding: 10px 16px;
  border-radius: ${(p) => p.theme.borderRadius.default};
  align-items: center;
  margin: 0 4px;
`;

export const ContextMenuSuffix = styled.span`
  color: ${({ theme }) => theme.text.secondary};
`;

export const ContextMenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(p) => p.theme.surface.e2};
  margin: 4px 0;
`;
