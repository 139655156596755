import {
  createClient,
  mapExchange,
  subscriptionExchange,
  fetchExchange,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { persistedExchange } from '@urql/exchange-persisted';
// import { persistedMultipleFetchExchange } from './multipart-persisted-exchange';
import { gqlCache } from './cache';
import { getAccessToken, getSharingSecret, removeAccessToken } from './auth';
import { hash } from './multipart-persisted-exchange';
import { wsClient } from './graphqlWs';
import { getOperationName } from './utils';
import { parse } from 'graphql';
import { requestPolicyExchange } from '@urql/exchange-request-policy';

const DEV = import.meta.env.DEV;

export const urqlClient = createClient({
  url: (import.meta.env.NX_VIZCOM_API_BASE_URL || '/api/v1') + '/graphql',
  exchanges: [
    requestPolicyExchange({
      shouldUpgrade: (operation) => {
        if (
          ['workbenchContent', 'drawingById'].includes(
            getOperationName(operation.query)
          )
        ) {
          // force referch of workbench and drawing content 10s after last call
          return true;
        }
        return false;
      },
      ttl: 10_000,
    }),
    ...(DEV ? [devtoolsExchange] : []),
    gqlCache,
    mapExchange({
      onError: async (error) => {
        if (
          error?.graphQLErrors.some((err) =>
            err.message.includes('Invalid Authorization header value')
          ) &&
          getAccessToken()
        ) {
          removeAccessToken();
          window.location.href = '/';
        }
        if (
          error?.graphQLErrors?.[0]?.message === 'Must provide a query string.'
        ) {
          // Forcing reload of app if the client is out-of-sync with the backend
          // There's no gracefully way of migrating to a new version of the client as it's not possible to send mutation anymore
          // best thing is to reload the page as soon as possible to reduce the amount of work lost
          window.location.reload();
        }
      },
    }),
    persistedExchange({
      enableForMutation: true,
      enforcePersistedQueries: true,
      async generateHash(_, document) {
        const operationName = getOperationName(document);
        return hash(operationName);
      },
    }) as any, // fix because mismatched version between @urql/exchange-request-policy and urql

    subscriptionExchange({
      forwardSubscription(request) {
        const operationName = getOperationName(parse(request.query ?? ''));
        if (!operationName) {
          throw new Error(
            `Cannot get operationName from subscription query: ${request.query}`
          );
        }

        const input = {
          variables: request.variables,
          extensions: {
            persistedQuery: {
              sha256Hash: hash(operationName),
            },
          },
          query: '',
        };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return {
              unsubscribe: () => {
                unsubscribe();
              },
            };
          },
        };
      },
    }),
    fetchExchange,
  ],
  fetchOptions: () => {
    const accessToken = getAccessToken();
    const sharingSecret = getSharingSecret();

    return {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'x-sharing-secret': sharingSecret || '',
      },
    };
  },
});
