import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface ChipProps {
  children: ReactNode | ReactNode[];
  variant?: 'primary';
}

const ChipContainer = styled.div<{ $variant?: string }>`
  border-radius: 10000px;
  background-color: ${(p) => p.theme.surface.e1};
  border: 1px solid ${(p) => p.theme.surface.e2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.text.info};
  padding: 4px 8px;
  white-space: nowrap;

  ${(props) =>
    props.$variant === 'primary' &&
    css`
      background-color: ${(p) => p.theme.chip.primary.background};
      border: 1px solid ${(p) => p.theme.chip.primary.text};
      color: ${(p) => p.theme.chip.primary.text};
    `}
`;

export const Chip = (props: ChipProps) => {
  return (
    <ChipContainer $variant={props.variant}>{props.children}</ChipContainer>
  );
};
