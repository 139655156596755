import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from '../Button';
import { MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import {
  Backdrop,
  Container,
  ParentContainer,
  ContainerHidden,
  TRANSITION_DURATION_MS,
} from './styles';

interface ContextMenuProps {
  items: React.ReactNode;
  autoOpen?:
    | boolean
    | {
        x: number;
        y: number;
      };
  withButton?: boolean;
  buttonProps?: React.ComponentProps<typeof Button>;
  parentRef?: MutableRefObject<HTMLElement | undefined>;
  referenceElementRef?: MutableRefObject<HTMLDivElement | null>;
  style?: React.CSSProperties;
  matchButtonWidth?: boolean;
  backdrop?: boolean;
  onOpenStateChange?: (open: boolean) => void;
}

const cloneNodeWithBoundingBoc = (el: HTMLElement | undefined) => {
  if (!el) {
    return;
  }
  const parentClone = el.cloneNode(true) as HTMLDivElement;
  const originalBoundingBox = el.getBoundingClientRect();
  return {
    el: parentClone,
    position: {
      top: originalBoundingBox.top,
      left: originalBoundingBox.left,
      width: originalBoundingBox.width,
      height: originalBoundingBox.height,
    },
  };
};

export const ContextMenu = (props: PropsWithChildren<ContextMenuProps>) => {
  const [state, setState] = useState<null | {
    top: number;
    left: number;
    buttonWidth: number;
    isClosing?: boolean;
    parentEl?: {
      el: Node;
      position: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
    };
  }>(null);
  const buttonRef = useRef<HTMLButtonElement>();
  const contextMenuRef = useRef<HTMLDivElement>();

  const openMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    let top, left;

    // If a reference element is provided, use its position
    if (props.referenceElementRef && props.referenceElementRef.current) {
      const rect = props.referenceElementRef.current.getBoundingClientRect();
      top = rect.bottom;
      left = rect.left;
    } else {
      // Else, calculate the position from the event
      top = e.clientY + 10;
      left = e.clientX;
      if (buttonRef.current) {
        const bb = buttonRef.current.getBoundingClientRect();
        top = bb.bottom + 10;
        left = bb.left;
      }
    }

    setState({
      top,
      left,
      parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
      buttonWidth: buttonRef.current?.clientWidth ?? 200,
    });
    props.onOpenStateChange?.(true);
  };

  const closeMenu = () => {
    setState(
      (s) =>
        s && {
          ...s,
          isClosing: true,
        }
    );
    setTimeout(() => {
      setState(null);
      props.onOpenStateChange?.(false);
    }, TRANSITION_DURATION_MS);
  };

  const onBackdropClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      contextMenuRef.current &&
      e.target &&
      !contextMenuRef.current.contains(e.target as any)
    ) {
      closeMenu();
    }
  };

  const handleMenuItemClick: MouseEventHandler = (e) => {
    closeMenu();
  };

  // listen to right-click on parent element
  useEffect(() => {
    if (props.autoOpen) {
      return;
    }

    const handler = (e: MouseEvent) => {
      if (
        props.parentRef?.current &&
        props.parentRef.current.contains(e.target as any)
      ) {
        e.preventDefault();
        setState({
          top: e.clientY,
          left: e.clientX,
          buttonWidth: buttonRef.current?.clientWidth ?? 200,
          parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
        });
        props.onOpenStateChange?.(true);
      }
    };

    document.addEventListener('contextmenu', handler);

    return () => {
      document.removeEventListener('contextmenu', handler);
    };
  }, [props.parentRef, props.onOpenStateChange, props.autoOpen]);

  useEffect(() => {
    if (!props.autoOpen) {
      return;
    }

    const position = {
      top: typeof props.autoOpen === 'boolean' ? 0.0 : props.autoOpen.y,
      left: typeof props.autoOpen === 'boolean' ? 0.0 : props.autoOpen.x,
    };

    setState({
      ...position,
      buttonWidth: buttonRef.current?.clientWidth ?? 200,
      parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
    });
    props.onOpenStateChange?.(true);
  }, [props.autoOpen]);

  const mountParent = (el: HTMLDivElement | null) => {
    if (!el || !state?.parentEl) {
      return;
    }

    el.appendChild(state.parentEl.el);
  };

  return (
    <>
      {props.withButton !== false && (
        <Button
          ref={buttonRef as any}
          type="button"
          onClick={openMenu}
          {...props.buttonProps}
        >
          {props.children}
        </Button>
      )}
      {state
        ? createPortal(
            <Backdrop
              onClick={onBackdropClick}
              onContextMenu={onBackdropClick}
              $closing={state.isClosing}
              $visible={props.backdrop ?? false}
            >
              {state.parentEl && (
                <ParentContainer
                  $closing={state.isClosing}
                  className="hover"
                  style={state.parentEl.position}
                  ref={mountParent}
                />
              )}
              <Container
                $closing={state.isClosing}
                id="context-menu"
                ref={contextMenuRef as any}
                onClick={handleMenuItemClick}
                style={{
                  ['--menu-left' as any]: `${state.left}px`,
                  ['--menu-top' as any]: `${state.top}px`,
                  ...(props.matchButtonWidth
                    ? {
                        width: state.buttonWidth,
                      }
                    : {}),
                  // transform: `translate3d(min(calc(100dvw - 100%), ${state.left}px), min(calc(100dvh - 100%), ${state.top}px))`,
                  // left: `min(calc(100dvw - 100%), ${state.left}px)`,
                  // top: state.top,
                  ...props.style,
                }}
              >
                {props.items}
              </Container>
            </Backdrop>,
            document.body
          )
        : createPortal(
            /* NOTE Instantiate context menu items in the background to let passive listeners / keyboard shortcuts to stay active */
            <ContainerHidden>{props.items}</ContainerHidden>,
            document.body
          )}
    </>
  );
};
