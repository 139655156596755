import { graphql } from '../../gql';

export const CreateWorkbenchElementSectionMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementSection(
    $input: CreateWorkbenchElementSectionInput!
  ) {
    createWorkbenchElementSection(input: $input) {
      workbenchElementSection {
        ...WorkbenchElementSectionData
      }
    }
  }
`);

export const UpdateWorkbenchElementSectionMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementSection(
    $input: UpdateWorkbenchElementSectionInput!
  ) {
    updateWorkbenchElementSection(input: $input) {
      workbenchElementSection {
        ...WorkbenchElementSectionData
      }
    }
  }
`);

export const DeleteWorkbenchElementSectionMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementSection(
    $input: DeleteWorkbenchElementSectionInput!
  ) {
    deleteWorkbenchElementSection(input: $input) {
      workbenchElementSection {
        ...WorkbenchElementSectionData
      }
    }
  }
`);
