import { z } from 'zod';

const workbenchMultiplayerClientAuthWithAccessToken = z.object({
  type: z.literal('auth-accessToken'),
  accessToken: z.string(),
  workbenchId: z.string(),
  sharingSecret: z.string().nullable(),
});

const workbenchMultiplayerClientAuthWithSharingSecret = z.object({
  type: z.literal('auth-sharingSecret'),
  sharingSecret: z.string(),
  workbenchId: z.string(),
});

export const workbenchMultiplayerClientCursor = z.object({
  type: z.literal('cursor'),
  x: z.number(),
  y: z.number(),
});

export const workbenchMultiplayerClientCamera = z.object({
  type: z.literal('camera'),
  x: z.number(),
  y: z.number(),
  zoom: z.number(),
});

export const workbenchMultiplayerClientFocus = z.object({
  type: z.literal('focusedElement'),
  focusedElementId: z.string().nullable(),
});

export const workbenchMultiplayerClientEditing = z.object({
  type: z.literal('editingElement'),
  editingElementId: z.string().nullable(),
});

export const workbenchMultiplayerClientMessage = z.discriminatedUnion('type', [
  workbenchMultiplayerClientAuthWithAccessToken,
  workbenchMultiplayerClientAuthWithSharingSecret,
  workbenchMultiplayerClientCursor,
  workbenchMultiplayerClientCamera,
  workbenchMultiplayerClientFocus,
  workbenchMultiplayerClientEditing,
]);

export const workbenchMultiplayerServerAuthResponse = z.object({
  type: z.literal('authResponse'),
  id: z.string(), // id of current multiplayer session
  userId: z.string().nullable(),
});

export const workbenchMultiplayerServerCursorUpdate = z.object({
  type: z.literal('update'),
  id: z.string(),
  x: z.number(),
  y: z.number(),
  userId: z.string().nullable(),
});

export const workbenchMultiplayerServerCameraUpdate = z.object({
  type: z.literal('updateCamera'),
  id: z.string(),
  x: z.number(),
  y: z.number(),
  zoom: z.number(),
  userId: z.string().nullable(),
});

export const workbenchMultiplayerServerCursorLeft = z.object({
  type: z.literal('left'),
  id: z.string(),
});

export const workbenchMultiplayerServerFocusUpdate = z.object({
  type: z.literal('updateFocusedElement'),
  id: z.string(),
  userId: z.string().nullable(),
  focusedElementId: z.string().nullable(),
});

export const workbenchMultiplayerServerEditUpdate = z.object({
  type: z.literal('updateEditingElement'),
  id: z.string(),
  editingElementId: z.string().nullable(),
  userId: z.string().nullable(),
});

export const workbenchMultiplayerServerMessage = z.discriminatedUnion('type', [
  workbenchMultiplayerServerAuthResponse,
  workbenchMultiplayerServerCursorUpdate,
  workbenchMultiplayerServerCameraUpdate,
  workbenchMultiplayerServerCursorLeft,
  workbenchMultiplayerServerFocusUpdate,
  workbenchMultiplayerServerEditUpdate,
]);
