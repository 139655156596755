import React, { FC } from 'react';

interface AutoplayVideoProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
}

export const AutoplayVideo: FC<AutoplayVideoProps> = ({ src, ...props }) => {
  return <video src={src} autoPlay loop muted {...props} />;
};
