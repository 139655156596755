import { useMutation } from 'urql';
import { graphql } from '../../gql';

const CreateAuthTokenForUser = graphql(/* GraphQL */ `
  mutation CreateAuthTokenForUser($userId: String!) {
    createAuthTokenForUser(input: { userId: $userId }) {
      token
    }
  }
`);

export const useCreateAuthTokenForUser = () => {
  return useMutation(CreateAuthTokenForUser);
};
