import { useMutation } from 'urql';
import { graphql } from '../gql';

const UpdateWorkbenchesAndFoldersMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchesAndFolders(
    $updateWorkbenchesInput: UpdateWorkbenchesInput!
    $updateFoldersInput: UpdateFoldersInput!
  ) {
    updateWorkbenches(input: $updateWorkbenchesInput) {
      updatedWorkbenches {
        ...WorkbenchBasicData
      }
      previousFolders {
        ...FolderData
      }
    }
    updateFolders(input: $updateFoldersInput) {
      updatedFolders {
        ...FolderData
      }
    }
  }
`);

export const useUpdateWorkbenchesAndFolders = () =>
  useMutation(UpdateWorkbenchesAndFoldersMutation);
