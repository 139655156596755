import { z } from 'zod';
import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const FolderEventTypeZodEnum = z.enum([
  'FOLDER_CREATED',
  'FOLDER_DELETED',
  'FOLDER_MOVED',
]);

export const FolderEventName = FolderEventTypeZodEnum.Enum;

export const FolderTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: FolderEventTypeZodEnum,
  data: z.object({
    folderIds: z.array(z.string()),
  }),
});
