import { useMutation } from 'urql';
import { graphql } from '../gql';
import { v4 as uuidv4 } from 'uuid';
import { drawingUpdatesSubscription } from '../subscriptions';
import { urqlClient } from '../lib/graphql';
import {
  DrawingUpdatesSubscription,
  DrawingUpdatesSubscriptionVariables,
} from '../gql/graphql';

const UpscaleImageMutation = graphql(/* GraphQL */ `
  mutation UpscaleImage($input: UpscaleImageInput!) {
    upscaleImage(input: $input) {
      upscale {
        ...UpscaleData
      }
    }
  }
`);

// An upscale operation returns immediately, once the job has been queued
// in here, we want to want until the job has finished
// to do so, we start a subscription to the drawing events and resolve the promise once we received the correct
// event indicating that the upscale operation has finished
export const useUpscaleImage = () => {
  const [, upscaleImage] = useMutation(UpscaleImageMutation);

  return (drawingId: string, image: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const upscaleId = uuidv4();

      const { unsubscribe } = urqlClient
        .subscription<
          DrawingUpdatesSubscription,
          DrawingUpdatesSubscriptionVariables
        >(drawingUpdatesSubscription, {
          input: {
            drawingId,
          },
        })
        .subscribe((event) => {
          const upscaleData = event.data?.drawingUpdates.upscale;
          if (upscaleData?.id === upscaleId) {
            if (upscaleData.upscaledImagePath) {
              resolve(upscaleData.upscaledImagePath);
              unsubscribe();
            } else if (upscaleData.failureReason) {
              reject(new Error(upscaleData.failureReason));
              unsubscribe();
            }
          }
        });

      upscaleImage({
        input: {
          id: upscaleId,
          drawingId,
          image,
        },
      }).catch((e) => reject(e));
    });
  };
};
