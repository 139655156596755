import { useParams } from 'react-router-dom';
import {
  useCurrentUser,
  useDrawing,
  useFolder,
  useOrganization,
  useTeam,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { globalOrganizationIdAtom } from './globalOrganizationAtom';

export const lastOrganizationIdStorageKeyForUserId = (userId: string) =>
  `vizcom:last_organization_id:${userId}`;

const useOrganizationIdFromPath = () => {
  const params = useParams<{
    team_id: string;
    drawingId: string;
    workbenchId: string;
    folderId: string;
    organizationId: string;
  }>();

  const currentFolder = useFolder(params.folderId);
  const currentDrawing = useDrawing(params.drawingId);
  const currentWorkbench = useWorkbench(params.workbenchId);
  const currentTeam = useTeam(params.team_id);

  if (params.organizationId) {
    return { loading: false, value: params.organizationId };
  }

  if (params.folderId) {
    // use team from folder if its id is provided in the URL
    if (currentFolder.fetching || currentFolder.stale) {
      return { loading: true, value: undefined };
    }

    return {
      loading: false,
      value: currentFolder.data?.organization?.id,
    };
  }

  if (params.workbenchId) {
    if (currentWorkbench.fetching || currentWorkbench.stale) {
      return { loading: true, value: undefined };
    }

    return {
      loading: false,
      value: currentWorkbench.data?.folder?.organization?.id,
    };
  }

  if (params.drawingId) {
    if (currentDrawing.fetching || currentDrawing.stale) {
      return { loading: true, value: undefined };
    }

    return {
      loading: false,
      value: currentDrawing.data?.workbench?.folder?.organization?.id,
    };
  }

  if (params.team_id) {
    if (currentTeam.fetching || currentTeam.stale) {
      return { loading: true, value: undefined };
    }
    return { loading: false, value: currentTeam.data?.organization?.id };
  }

  // no organization has been selected
  return { loading: false, value: undefined };
};

export const useSelectedOrganization = () => {
  const currentUser = useCurrentUser();
  const organizationIdFromRoute = useOrganizationIdFromPath();
  const [globalOrganizationId, setGlobalOrganizationId] = useRecoilState(
    globalOrganizationIdAtom
  );

  const currentOrganizationId =
    organizationIdFromRoute.value || globalOrganizationId;

  useEffect(() => {
    if (
      organizationIdFromRoute.value &&
      organizationIdFromRoute.value !== globalOrganizationId
    ) {
      setGlobalOrganizationId(organizationIdFromRoute.value);
    }
  }, [
    organizationIdFromRoute.value,
    globalOrganizationId,
    setGlobalOrganizationId,
    currentUser.data?.id,
  ]);

  useEffect(() => {
    if (globalOrganizationId && currentUser.data?.id) {
      localStorage.setItem(
        lastOrganizationIdStorageKeyForUserId(currentUser.data.id),
        globalOrganizationId
      );
    }
  }, [globalOrganizationId, currentUser.data?.id]);

  const organization = useOrganization(currentOrganizationId || undefined);

  return {
    loading:
      organizationIdFromRoute.loading ||
      organization.fetching ||
      organization.stale ||
      currentUser.fetching ||
      currentUser.stale,
    data: organization.data,
    error: organization.error,
  };
};
