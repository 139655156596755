import { v4 as uuid } from 'uuid';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { CloseIcon, Loader, WarningIcon } from '@vizcom/shared-ui-components';

const COLORS = {
  default: {
    mainBackground: '#303030',
    ctaBackground: '#39393A',
    fontColor: '#FFF',
  },
  primary: {
    mainBackground: '#3535A7',
    ctaBackground: '#4C4CEF',
    fontColor: '#FFF',
  },
  warning: {
    mainBackground: '#FDBA0F',
    ctaBackground: '#FDC83F',
    fontColor: '#FFF',
  },
  danger: {
    mainBackground: '#F24A58',
    ctaBackground: '#F46E79',
    fontColor: '#FFF',
  },
};

const CtaButton = styled.button<{ $bgColor: string }>`
  border-radius: 8px;
  background-color: ${({ $bgColor }) => $bgColor};
  font-weight: 400;
  font-size: 12px;
  border: none;
  cursor: pointer;
  min-width: 70px;
  height: 32px;
  padding: 0 10px;

  :hover {
    background-color: ${({ $bgColor }) => $bgColor + 70};
  }
`;

const CustomToastContainer = styled.div`
  padding: 0px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Separator = styled.div<{ $bgColor: string }>`
  width: 0px;
  height: 20px;
  background-color: ${({ $bgColor }) => $bgColor};
  padding: 1px;
  border-radius: 10px;
`;

const TextContainer = styled.div`
  font-size: 12px;
  max-width: 350px;
`;

const MainText = styled.span`
  font-weight: 600;
`;

const SecondaryText = styled.span`
  font-weight: 400;
`;

type CustomToastType = keyof typeof COLORS;

interface CustomToastOptions {
  id?: string;
  type?: CustomToastType;
  ctaText?: string;
  ctaAction?: () => void;
  duration?: number;
  dismissToastId?: string;
  secondaryText?: string;
  icon?: JSX.Element;
  loading?: boolean;
}

export const addToast = (message: string, options: CustomToastOptions = {}) => {
  const {
    ctaAction,
    ctaText,
    type = 'default',
    duration = 3000,
    secondaryText,
  } = options;

  if (options.dismissToastId) {
    toast.dismiss(options.dismissToastId);
  }

  const ctaBackground = COLORS[type].ctaBackground;

  const warningIcon = (
    <WarningIcon color="white" style={{ minWidth: 20, minHeight: 20 }} />
  );
  const LoaderIcon = <Loader size={{ height: 15, width: 15 }} />;

  const icon = options.loading
    ? LoaderIcon
    : options.type === 'danger'
    ? warningIcon
    : options.icon;

  return toast(
    (t) => (
      <CustomToastContainer>
        <TextContainer>
          <MainText>{message}</MainText>
          {secondaryText && '  '}
          {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        </TextContainer>

        {ctaText && ctaAction && (
          <>
            <Separator $bgColor={ctaBackground} />
            <CtaButton
              $bgColor={ctaBackground}
              onClick={() => {
                toast.dismiss(t.id);
                ctaAction();
              }}
            >
              {ctaText}
            </CtaButton>
          </>
        )}

        <CloseIcon
          onClick={() => toast.dismiss(t.id)}
          style={{
            cursor: 'pointer',
            color: COLORS[type].fontColor,
            minWidth: 20,
            minHeight: 20,
          }}
        />
      </CustomToastContainer>
    ),
    {
      id: options.id || uuid(),
      duration,
      icon,
      style: {
        backgroundColor: COLORS[type].mainBackground,
        color: COLORS[type].fontColor,
        borderRadius: '16px',
        maxWidth: '600px',
      },
    }
  );
};
