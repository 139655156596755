import React, { PropsWithChildren, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

type ErrorBoundaryState = {
  error: any;
};

type ErrorBoundaryProps = {
  fallback?: ReactNode;
  fallbackRender?: (props: { error: any }) => ReactNode;
  logToSentry?: boolean;
};

export class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  override state: ErrorBoundaryState = { error: false };

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  override componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (this.props.logToSentry !== false) {
      // from https://github.com/getsentry/sentry-javascript/blob/develop/packages/react/src/errorboundary.tsx
      Sentry.captureException(error, {
        contexts: {
          react: {
            componentStack: info.componentStack,
          },
        },
      });
    }
  }

  override render() {
    if (this.state.error) {
      if (this.props.fallbackRender) {
        return this.props.fallbackRender({ error: this.state.error });
      }
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}
