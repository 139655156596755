import { graphql } from '../gql';

export const CreateMultiViewsFromDrawingMutation = graphql(/* GraphQL */ `
  mutation CreateMultiViewsFromDrawing(
    $input: CreateMultiViewsFromDrawingInput!
  ) {
    createMultiViewsFromDrawing(input: $input) {
      placeholders {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);
