import { useMutation } from 'urql';
import { graphql } from '../gql';

const ChangePassword = graphql(/* GraphQL */ `
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ok
    }
  }
`);

export const useChangePassword = () => {
  return useMutation(ChangePassword);
};
