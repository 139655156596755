import { graphql } from '../gql';
import { UseQueryArgs, useQuery } from 'urql';
import { RecentWorkbenchOrdering } from '../gql/graphql';

const workbenchContent = graphql(/* GraphQL */ `
  query workbenchContent($id: UUID!) {
    workbench(id: $id) {
      ...WorkbenchContent
    }
  }
`);

export const useWorkbenchContent = (
  id?: string,
  options: Omit<UseQueryArgs, 'query' | 'variables'> = {}
) => {
  const [res] = useQuery({
    query: workbenchContent,
    variables: {
      id: id!,
    },
    pause: !id,
    ...options,
  });

  return { ...res, data: res.data?.workbench };
};

const workbenchQuery = graphql(/* GraphQL */ `
  query workbench($id: UUID!) {
    workbench(id: $id) {
      ...WorkbenchBasicData
    }
  }
`);

export const useWorkbench = (
  id?: string,
  options: Omit<UseQueryArgs, 'query' | 'variables'> = {}
) => {
  const [res] = useQuery({
    query: workbenchQuery,
    variables: {
      id: id!,
    },
    pause: !id,
    ...options,
  });

  return { ...res, data: res.data?.workbench };
};

const recentWorkbenches = graphql(/* GraphQL */ `
  query recentWorkbenches(
    $organizationId: UUID!
    $recentBy: RecentWorkbenchOrdering!
    $filterTeamIds: [UUID!]!
  ) {
    organization(id: $organizationId) {
      id
      recentWorkbenches(recentBy: $recentBy, filterTeamIds: $filterTeamIds) {
        nodes {
          ...WorkbenchBasicData
          folder {
            id
            name: formattedName
          }
        }
      }
    }
  }
`);

export const useRecentWorkbenchesOfOrganization = (
  organizationId: string | undefined | null,
  recentBy: RecentWorkbenchOrdering,
  filterTeamIds: string[] | null | undefined
) => {
  const [res] = useQuery({
    query: recentWorkbenches,
    variables: {
      organizationId: organizationId!,
      recentBy,
      filterTeamIds: filterTeamIds ?? [],
    },
    pause: !organizationId,
  });

  return { ...res, data: res.data?.organization?.recentWorkbenches?.nodes };
};

export const recentlyViewedWorkbenches = graphql(/* GraphQL */ `
  query recentlyViewedWorkbenches($organizationId: UUID!) {
    organization(id: $organizationId) {
      ...OrganizationRecentlyViewedWorkbenchesData
    }
  }
`);

export const useRecentlyViewedWorkbenchesOfOrganization = (
  organizationId: string | undefined | null
) => {
  const [res] = useQuery({
    query: recentlyViewedWorkbenches,
    variables: {
      organizationId: organizationId!,
    },
    pause: !organizationId,
  });

  return {
    ...res,
    data: res.data?.organization?.recentlyViewedWorkbenches?.nodes,
  };
};

const workbenchStyleReferencesQuery = graphql(/* GraphQL */ `
  query workbenchStyleReferences($id: UUID!) {
    workbench(id: $id) {
      id
      ...WorkbenchStyleReferences
    }
  }
`);

export const useWorkbenchStyleReferences = (workbenchId: string) => {
  const [res] = useQuery({
    query: workbenchStyleReferencesQuery,
    variables: {
      id: workbenchId,
    },
    pause: !workbenchId,
  });

  return { ...res, data: res.data?.workbench?.styleReferences };
};
