import { graphql } from '../../gql';

export const CreateCompositeSceneElementMutation = graphql(/* GraphQL */ `
  mutation CreateCompositeSceneElement(
    $input: CreateCompositeSceneElementInput!
  ) {
    createCompositeSceneElement(input: $input) {
      compositeScene {
        ...CompositeSceneFullData
      }
    }
  }
`);

export const UpdateCompositeSceneElementMutation = graphql(/* GraphQL */ `
  mutation UpdateCompositeSceneElement(
    $input: UpdateCompositeSceneElementInput!
  ) {
    updateCompositeSceneElement(input: $input) {
      compositeScene {
        ...CompositeSceneFullData
      }
    }
  }
`);

export const DeleteCompositeSceneElementMutation = graphql(/* GraphQL */ `
  mutation DeleteCompositeSceneElement(
    $input: DeleteCompositeSceneElementInput!
  ) {
    deleteCompositeSceneElement(input: $input) {
      compositeScene {
        ...CompositeSceneFullData
      }
    }
  }
`);
