import styled from 'styled-components';

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: auto;
`;

const Dot = styled.button`
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;

  &.active {
    background: white;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: white;
  }
`;

type DotsPaginationProps = {
  changeStep: (index: number) => void;
  activeIndex: number;
  stepsCount: number;
};

export const DotsPagination = ({
  changeStep,
  activeIndex,
  stepsCount,
}: DotsPaginationProps) => {
  const positions = Array.from({ length: stepsCount }, (_, i) => i);

  return (
    <DotsContainer>
      {positions.map((position) => (
        <Dot
          key={position}
          onClick={() => changeStep(position)}
          className={activeIndex === position ? 'active' : ''}
        />
      ))}
    </DotsContainer>
  );
};
