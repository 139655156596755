import { useRerender } from './useRerender';

export const computeHashNumber = async (val: string) => {
  const msgUint8 = new TextEncoder().encode(val); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

  return hashArray.reduce((p, v) => p + v, 0);
};

// Caching the hash number to prevent a FUC when calling this hook multiple times
const stringHashNumberCache = new Map<string, Promise<number> | number>();

export const useStringHashNumber = (val: string) => {
  const rerender = useRerender();

  if (stringHashNumberCache.get(val) === undefined) {
    // we start the hash compute only once and register the promise to allow any other component
    // to register to the completion event and rerender themself
    stringHashNumberCache.set(
      val,
      computeHashNumber(val).then((hash) => {
        stringHashNumberCache.set(val, hash);
        return hash;
      })
    );
  }

  const cachedHash = stringHashNumberCache.get(val);
  if (typeof cachedHash === 'object') {
    cachedHash.then(rerender);
  }

  if (typeof cachedHash === 'number') {
    return cachedHash;
  }
  return undefined;
};
