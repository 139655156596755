import hashes from '../client_queries.json';

export const hash = (operationName: string) => {
  const queryHash = (hashes as Record<string, string>)[operationName];
  if (!queryHash) {
    throw new Error(
      `No query hash was generated for this operation, make sure to run "nx run shared-data-access-graphql:codegen": ${operationName}`
    );
  }
  return queryHash;
};
