import { IconCircleQuestionmark } from '@vizcom/shared-utils-assets';
import { ContextMenu, ContextMenuDivider, ContextMenuItem } from '../..';
import { useCrisp } from './crisp';
import { useWebConfig } from '@vizcom/shared/data-access/graphql';

interface SharedHelpCenterProps {
  children?: React.ReactNode;
  buttonProps?: React.ComponentProps<typeof ContextMenu>['buttonProps'];
  additionalItems?: React.ReactNode;
}

const LinkItem = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <ContextMenuItem>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        style={{ display: 'block', width: '100%', height: '100%' }}
      >
        {children}
      </a>
    </ContextMenuItem>
  );
};

export const SharedHelpCenter = ({
  children,
  buttonProps,
  additionalItems,
}: SharedHelpCenterProps) => {
  const crisp = useCrisp();
  const { data } = useWebConfig();

  return (
    <ContextMenu
      items={
        <>
          <LinkItem href="https://www.youtube.com/channel/UCU-XOa74z1aGdZRyvRBQ6UQ/videos">
            Tutorials
          </LinkItem>
          <LinkItem href="https://docs.vizcom.ai/">Documentation</LinkItem>
          {!data?.whiteLabelIconUrl && (
            <>
              <LinkItem href="https://discord.gg/aJZZaJpPSZ">
                Join our Discord
              </LinkItem>
              <ContextMenuDivider />
              <LinkItem href="https://www.vizcom.ai/terms-of-service">
                Terms of service
              </LinkItem>
              <LinkItem href="https://www.vizcom.ai/privacy-policy">
                Privacy policy
              </LinkItem>
            </>
          )}

          <ContextMenuDivider />
          <ContextMenuItem onClick={() => crisp.openChat()}>
            Contact support
          </ContextMenuItem>
          {additionalItems}
        </>
      }
      buttonProps={{
        variant: 'outline',
        ...buttonProps,
      }}
    >
      {children || (
        <IconCircleQuestionmark
          width="2.2rem"
          height="2.2rem"
          fill="#333"
          stroke="#fff"
        />
      )}
    </ContextMenu>
  );
};
