import { graphql } from '../../gql';

export const UpdateMultiWorkbenchElementsMutation = graphql(/* GraphQL */ `
  mutation UpdateMultiWorkbenchElements(
    $input: UpdateMultiWorkbenchElementsInput!
  ) {
    updateMultiWorkbenchElements(input: $input) {
      updatedDrawings {
        id
        x
        y
        zIndex
        workbenchSizeRatio
      }
      updatedPlaceholders {
        id
        x
        y
        zIndex
        width
        height
      }
      updatedImg2Imgs {
        id
        x
        y
        zIndex
        width
        height
      }
      updatedMixs {
        id
        x
        y
        width
        height
        zIndex
      }
      updatedSections {
        id
        x
        y
        width
        height
        zIndex
      }
      updatedTexts {
        id
        x
        y
        zIndex
        width
        height
      }
      updatedCompositeScenes {
        id
        x
        y
        zIndex
        width
        height
      }
      updatedPalettes {
        id
        x
        y
        zIndex
      }
    }
  }
`);
