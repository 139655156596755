import { graphql } from '../gql';
import { useQuery } from 'urql';

export const folderById = graphql(/* GraphQL */ `
  query folder($id: UUID!) {
    folder(id: $id) {
      ...FolderData
    }
  }
`);

export const useFolder = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: folderById,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.folder };
};

export const workbenchesByFolderId = graphql(/* GraphQL */ `
  query workbenchesByFolderId($id: UUID!, $cursor: Cursor, $count: Int) {
    workbenches(
      condition: { folderId: $id }
      orderBy: UPDATED_AT_DESC
      first: $count
      after: $cursor
    ) {
      nodes {
        ...WorkbenchBasicData
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const useWorkbenchesByFolderId = (
  id: string | undefined | null,
  cursor: string | null,
  count?: number
) => {
  const [res] = useQuery({
    query: workbenchesByFolderId,
    variables: { id: id!, cursor, count },
    pause: !id,
  });

  return {
    ...res,
    data: res.data?.workbenches?.nodes,
    pageInfo: res.data?.workbenches?.pageInfo,
  };
};
