import styled, { DefaultTheme } from 'styled-components';

interface InputProps {
  $background?: keyof DefaultTheme['surface'];
}

export const TextArea = styled.textarea<InputProps>`
  background-color: ${(p) => p.theme.surface[p.$background || 'e0']};
  color: ${(p) => p.theme.text.default};

  height: 100%;
  width: 100%;
  padding: 16px;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  outline: none;
  border-radius: 8px;
  border-width: 0;

  ::placeholder {
    color: ${(p) => p.theme.text.default};
  }

  transition: 0.25s all ease;
  box-shadow: 0 0 0 0 ${(p) => p.theme.primary.default};
  :focus {
    box-shadow: 0 0 0 2px ${(p) => p.theme.primary.default};
  }
`;
