import { graphql } from '../../gql';

export const CreateWorkbenchElementTextMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementText(
    $input: CreateWorkbenchElementTextInput!
  ) {
    createWorkbenchElementText(input: $input) {
      workbenchElementText {
        ...WorkbenchElementTextData
      }
    }
  }
`);

export const UpdateWorkbenchElementTextMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementText(
    $input: UpdateWorkbenchElementTextInput!
  ) {
    updateWorkbenchElementText(input: $input) {
      workbenchElementText {
        ...WorkbenchElementTextData
      }
    }
  }
`);

export const DeleteWorkbenchElementTextMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementText(
    $input: DeleteWorkbenchElementTextInput!
  ) {
    deleteWorkbenchElementText(input: $input) {
      deletedWorkbenchElementTextNodeId
    }
  }
`);
