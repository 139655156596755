import { useMutation } from 'urql';
import { graphql } from '../../gql';
import { useEffect } from 'react';

let lastViewedWorkbenchId = null as null | string;

const registerViewedWorkbenchMutation = graphql(/* GraphQL */ `
  mutation registerViewedWorkbench($input: RegisterViewedWorkbenchInput!) {
    registerViewedWorkbench(input: $input) {
      clientMutationId
      organization {
        ...OrganizationRecentlyViewedWorkbenchesData
      }
    }
  }
`);

export const useRegisterViewedWorkbench = (workbenchId: string | undefined) => {
  const [, registerViewedWorkbench] = useMutation(
    registerViewedWorkbenchMutation
  );

  useEffect(() => {
    if (workbenchId && lastViewedWorkbenchId !== workbenchId) {
      lastViewedWorkbenchId = workbenchId;
      registerViewedWorkbench({
        input: {
          workbenchId,
        },
      });
    }
  }, [workbenchId]);
};
