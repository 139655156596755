import { graphql } from '../gql';
import { useQuery } from 'urql';

export const promptById = graphql(/* GraphQL */ `
  query prompt($id: UUID!) {
    prompt(id: $id) {
      ...PromptData
    }
  }
`);

export const usePrompt = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: promptById,
    variables: {
      id: id!,
    },
    pause: !id || id === 'loading',
  });

  return { ...res, data: res.data?.prompt };
};
