import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import originStyled, {
  ThemeProvider,
  DefaultTheme,
  ThemedStyledInterface,
} from 'styled-components';
import { deviceThemeState, selectedThemeState } from './atoms';

const lightTheme: DefaultTheme = {
  white: '#ffffff',
  black: '#000000',
  primary: {
    default: '#4C4CEF',
    hover: '#3535A7',
    disabled: '#23234D',
  },
  secondary: {
    default: '#242425',
    hover: '#39393A',
    disabled: '#39393A',
  },
  tertiary: {
    default: '#A8E14A',
    hover: '#A8E14A',
    disabled: '#4F7413',
  },
  text: {
    default: '#FFFFFF',
    info: '#808085',
    disabled: '#39393A',
    primary: '#4C4CEF',
    secondary: '#828283',
    tertiary: '#A8E14A',
    danger: '#F03D3D',
    warning: '#FDBA0F',
    success: '#0BAA60',
    black: '#000000',
    primaryAccent: '#7070F2',
    button: '#F9F9FA',
    body: '#FAFAFA',
  },
  background: {
    base: '#f8f8f8',
    pattern: '#e6e5e5',
  },
  surface: {
    e0: '#151517',
    e1: '#242425',
    e2: '#303030',
    e3: '#3C3C3E',
  },
  icon: {
    default: '#FAFAFA',
  },
  functional: {
    danger: {
      default: '#F03D3D',
      hover: '#C03131',
    },
    warning: {
      default: '#FDBA0F',
      hover: '#CA950C',
    },
    success: {
      default: '#0BAA60',
      hover: '#088048',
    },
  },
  borderRadius: {
    default: '8px',
    file: '19px',
    subtle: '4px',
  },
  blocks: {
    image: '#1E1E60',
  },
  chip: {
    primary: {
      background: '#1E1E60',
      text: '#7070F2',
    },
  },
  spacing: {
    s: '8px',
    m: '16px',
    l: '24px',
  },
};

const darkTheme: DefaultTheme = {
  ...lightTheme,
  // background: {
  //   base: '#0C0C0D',
  //   pattern: '#1E1E20',
  // },
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const VizcomThemeProvider = ({ children }: any) => {
  const [deviceTheme, setDeviceTheme] = useRecoilState(deviceThemeState);
  const selectedTheme = useRecoilValue(selectedThemeState);

  const theme = useMemo(() => {
    if (selectedTheme === 'device') {
      return themes[deviceTheme];
    }
    return themes[selectedTheme];
  }, [deviceTheme, selectedTheme]);

  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const change = ({ matches }: MediaQueryListEvent) =>
      setDeviceTheme(matches ? 'dark' : 'light');
    prefersDark.addEventListener('change', change);
    return () => prefersDark.removeEventListener('change', change);
  }, []);

  return <ThemeProvider children={children} theme={theme} />;
};

export const styled = originStyled as ThemedStyledInterface<DefaultTheme>;
