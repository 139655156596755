import { graphql } from '../gql';
import { useQuery } from 'urql';
import { useHasAccessToken } from '../lib/auth';
import { UserClientStateKeys } from '../gql/graphql';

export const currentUserQuery = graphql(/* GraphQL */ `
  query currentUser {
    currentUser {
      ...CurrentUserData
    }
  }
`);

export const useCurrentUser = () => {
  const hasAccessToken = useHasAccessToken();
  const [res] = useQuery({ query: currentUserQuery, pause: !hasAccessToken });

  return {
    ...res,
    fetching: hasAccessToken ? res.fetching : false,
    data: hasAccessToken ? res.data?.currentUser : null,
  };
};

export const useCurrentUserClientStateByKey = (key: UserClientStateKeys) => {
  const { data: currentUser } = useCurrentUser();

  return currentUser?.clientStates.nodes.find((cs) => cs.key === key)?.value;
};
